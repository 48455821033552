export const RequestLogSearchLimit = 10000;
export const cloudStatuses = [
  {
    label: 'Success',
    id: 'success'
  },
  {
    label: 'User errors',
    id: 'user_error'
  },
  {
    label: 'Limit errors',
    id: 'limit_exceeded'
  },
  {
    label: 'Server errors',
    id: 'server_error'
  }
];
