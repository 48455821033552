import React from 'react';
import ReactModal from 'react-modal';
import { ThemeProvider } from 'styled-components';
import { themeConfig } from '@make-software/cspr-ui';
import { RouterProvider } from 'react-router-dom';
import router from './app/route';

function App() {
  ReactModal.setAppElement('body');
  return (
    <ThemeProvider theme={themeConfig.light}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
