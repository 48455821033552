import { BodyText } from '@make-software/cspr-ui';
import styled from 'styled-components';

interface HiddenTextProps {
  text: string;
  hide: boolean;
  dotsNumber: number;
  color?: 'gray' | 'black' | 'green' | 'red';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const StyledDots = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    wordBreak: ['unset', 'unset', 'break-word']
  })
);

export const HiddenText = ({ text, hide, dotsNumber, color, size }: HiddenTextProps) => {
  if (!hide)
    return (
      <BodyText size={1} monotype variation={color} scale={size}>
        {text}
      </BodyText>
    );

  const dots = dotsNumber ? dotsNumber : text.length;
  let newArray = Array.from({ length: dots }, (value, index) => index.toString());

  const hashStart = text.slice(0, 4);
  const hashEnd = text.slice(text.length - 4);
  return (
    <StyledDots size={1} scale={size ? size : 'sm'} variation={color} monotype>
      {hashStart}
      {newArray.fill('.').join('')}
      {hashEnd}
    </StyledDots>
  );
};
