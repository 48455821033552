import { useCallback, useEffect, useState } from 'react';
import { buildApi, DefaultResponse, GetResponseType } from 'app/build-api/types';
import { CloudEndpointItem } from 'app/build-api/statistics/cloud-logs/endpoints-list';
import { debounce } from 'app/components/utils/utils';
import { getCurrentAndThirtyDaysAgoDate } from 'app/components/utils/date-time';

type ManageEndpointsListReturnValue = {
  endpoints: CloudEndpointItem[] | undefined | null;
  popularEndpointsLoading: boolean;
  autocompleteEndpointsLoading: boolean;
  handleChangeSearchValue: (value: string) => void;
};

const useManageEndpointsList = (): ManageEndpointsListReturnValue => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [autocompleteEndpoints, setAutocompleteEndpoints] =
    useState<GetResponseType<CloudEndpointItem[]>>(DefaultResponse);
  const [popularEndpoints, setPopularEndpoints] =
    useState<GetResponseType<CloudEndpointItem[]>>(DefaultResponse);

  const { now, thirtyDaysAgo } = getCurrentAndThirtyDaysAgoDate();

  useEffect(() => {
    (async () => {
      setPopularEndpoints(await buildApi.getCloudPopularEndpointsList(thirtyDaysAgo, now));
    })();
  }, []);

  const throttledSearchAutocompleteEndpoints = useCallback(
    debounce(async (searchValue: string) => {
      if (searchValue?.length >= 3) {
        setAutocompleteEndpoints(
          await buildApi.getCloudAutocompleteEndpointsList(thirtyDaysAgo, now, searchValue)
        );
      }
    }, 400),
    []
  );

  useEffect(() => {
    (async () => {
      throttledSearchAutocompleteEndpoints(searchValue);
    })();
  }, [searchValue, throttledSearchAutocompleteEndpoints]);

  const handleChangeSearchValue = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const endpoints = searchValue ? autocompleteEndpoints?.data : popularEndpoints?.data;

  return {
    endpoints,
    popularEndpointsLoading: popularEndpoints?.loading,
    autocompleteEndpointsLoading: autocompleteEndpoints?.loading,
    handleChangeSearchValue
  };
};

export default useManageEndpointsList;
