import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { AUTH_TOKEN, generalError, jsonErrorCodes, statusCode } from 'app/constants';
import KeyIcon from 'assets/logos/keys.svg';
import SuccessEntityModal from 'app/components/common/success-entity-modal/success-entity-modal';
import { FlexRow, SvgIcon } from '@make-software/cspr-ui';
import CreatedSuccessIcon from 'assets/logos/success-entity.svg';
import {
  AccessItem,
  ClickKeyRequestType,
  ClickKeyResponseType,
  ProductType,
  UpdateClickKeyRequestType,
  WithSubscriptionProps
} from 'app/build-api';
import { StyledLink } from 'app/components/home/components/create-modal-styles';
import { useGetClickKeys } from 'app/build-api/hooks/useGetClickKeys';
import { SIGN_IN_PATH, SUBSCRIPTIONS_PATH } from 'app/route/paths';
import { useNavigate } from 'react-router-dom';
import { ClickKeyList } from './show/click-key-list';
import CreateClickKeyWindow, { DropdownItem } from './create/create-click-key-window';
import { PreloadTableWindow } from 'app/components/common/preload-table-window/preload-table-window';
import EditClickKeyWindow from './edit/edit-click-key-window';
import { useGetCloudKeys } from 'app/build-api/hooks/useGetCloudKeys';
import { checkGrantedAccess } from 'app/components/utils/utils';
import { appActions, communicator } from 'app/communicator';

export interface ExpectedErrors {
  tierNetworkLimitError: string | React.ReactElement;
  duplicateDomainError: string;
}

const Container = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginTop: '48px'
  })
);

export const ClickKeyContainer = ({ tiersDetails }: WithSubscriptionProps) => {
  const {
    data: clickKeys,
    error: fetchingClickKeysError,
    refetch: clickKeyRefetch,
    httpCode: clickKeyHttpCode
  } = useGetClickKeys(1, 5);

  const {
    data: cloudKeys,
    error: fetchingCloudKeysError,
    httpCode: cloudKeyHttpCode,
    refetch: cloudKeyRefetch
  } = useGetCloudKeys(1, 100);

  const [formattedCloudKeysList, setFormattedCloudKeysList] = useState<DropdownItem[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [createdKey, setCreatedKey] = useState<string | null>(null);
  const [keyToEdit, setKeyToEdit] = useState<ClickKeyResponseType>();
  const [expectedError, setExpectedError] = useState<ExpectedErrors>({} as ExpectedErrors);
  const [unexpectedError, setUnexpectedError] = useState<ErrorResult | null>(null);
  const [keys, setKeys] = useState<ClickKeyResponseType[] | null>();
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const { clickTier } = tiersDetails;
  const noClickKeys = !keys?.length && !loading && !fetchingClickKeysError && !unexpectedError;
  const isTierLimitExceeded =
    clickTier.info?.tier.key_limit !== null
      ? Boolean(clickKeys && clickKeys.item_count >= clickTier.info?.tier.key_limit)
      : false;

  const getTierErrorMessage = () => {
    let message: React.JSX.Element | string = '';

    if (checkGrantedAccess(AccessItem.TierManagement)) {
      // TODO: We don't have data about relation between key and network.
      //  We use {click_key_mainnet_limit} but it can be a testnet network as well
      message = (
        <div>
          Can’t create more than {clickTier.info.tier.click_key_mainnet_limit} keys for same Network
          for “{clickTier.info.tier.name}” tier.{' '}
          <StyledLink to={SUBSCRIPTIONS_PATH} state={{ activeTab: ProductType.Click }}>
            Upgrade your plan.{' '}
          </StyledLink>
        </div>
      );
    } else if (!checkGrantedAccess(AccessItem.TierManagement)) {
      message = `Can’t create more than ${clickTier.info.tier.click_key_mainnet_limit} keys for same Network for “${clickTier.info.tier.name}” tier. Please contact your organization owner to upgrade the plan.`;
    }

    return message;
  };

  useEffect(() => {
    communicator.register(appActions.refetchCloudKeys, () => cloudKeyRefetch(1, 100));
  }, [communicator]);

  useEffect(() => {
    setKeys(clickKeys?.data);
    if (clickKeys?.data) {
      setLoading(false);
    }
  }, [clickKeys]);

  useEffect(() => {
    if (cloudKeys?.data) {
      const formattedKeys = cloudKeys?.data.map((k) => {
        const hashStart = k.hash.slice(0, 4);
        const hashEnd = k.hash.slice(k.hash.length - 4);
        return {
          value: k.id,
          label: `${k.name}, ${hashStart}...${hashEnd}`
        };
      });

      setFormattedCloudKeysList(formattedKeys);
    }
  }, [cloudKeys]);

  if ((fetchingClickKeysError || fetchingCloudKeysError) && !unexpectedError) {
    if (
      cloudKeyHttpCode === statusCode.authentication_error ||
      clickKeyHttpCode === statusCode.authentication_error
    ) {
      localStorage.removeItem(AUTH_TOKEN);
      navigate(SIGN_IN_PATH);
    } else {
      setLoading(false);
      setUnexpectedError(generalError);
    }
  }

  const handleOpenCreateKeyWindow = () => {
    setOpenModal(true);
    setUnexpectedError(null);
    setExpectedError({} as ExpectedErrors);
  };

  const handleCloseCreateKeyWindow = () => {
    setOpenModal(false);
    setExpectedError({} as ExpectedErrors);
  };

  const handleCloseSuccessModal = () => {
    setCreatedKey(null);
  };

  const handleOpenEditKeyWindow = (key: ClickKeyResponseType) => {
    setOpenEditModal(true);
    setKeyToEdit(key);
  };

  const handleCloseEditKeyWindow = () => {
    setOpenEditModal(false);
    setExpectedError({} as ExpectedErrors);
  };

  const updateKeyList = (key: string) => {
    setCreatedKey(key);
    clickKeyRefetch && clickKeyRefetch();
  };

  const handleUpdateKey = async (id: string, key: UpdateClickKeyRequestType) => {
    const response = await buildApi.updateClickKey(id, key);

    if (response.data?.id) {
      handleCloseEditKeyWindow();
      setKeys((prevState: any) => [
        ...prevState.map((k: ClickKeyResponseType) => {
          if (k.id === response.data?.id) {
            return {
              ...k,
              name: response.data.name,
              status: response.data.status,
              domains: response.data.domains,
              linked_cloud_key: response.data.linked_cloud_key,
              updated_at: response.data.updated_at
            };
          }
          return k;
        })
      ]);
    }

    if (response.error) {
      switch (response.httpCode) {
        case statusCode.invalid_input_error:
        case statusCode.not_found_error:
        case statusCode.validation_error:
        case statusCode.access_denied_error:
          setUnexpectedError(response.error);
          Sentry.captureException(response.error);
          break;
        case statusCode.conflict_error:
          setExpectedError({
            ...expectedError,
            duplicateDomainError: 'Some of domains already exist'
          });
          break;
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        case statusCode.unexpected_error:
          setUnexpectedError(generalError);
          break;
        default:
          setUnexpectedError(generalError);
          break;
      }
    }
  };

  const handleCreateKey = async (data: ClickKeyRequestType) => {
    const response = await buildApi.createClickKey(data);

    if (response.data?.id) {
      handleCloseCreateKeyWindow();
      setCreatedKey(response.data.api_key);

      setKeys((prevState: any) => [...prevState, response.data]);
      await clickKeyRefetch();

      if (noClickKeys) {
        updateKeyList(response.data.api_key);
      }
    }

    if (response.error) {
      switch (response.httpCode) {
        case statusCode.invalid_input_error:
        case statusCode.access_denied_error:
        case statusCode.validation_error:
          setUnexpectedError(response.error);
          Sentry.captureException(response.error);
          break;
        case statusCode.conflict_error:
          setExpectedError({
            ...expectedError,
            duplicateDomainError: 'Some of domains already exist'
          });
          break;
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        case statusCode.payment_required_error:
          if (response.error.code === jsonErrorCodes.networkValidationError) {
            setExpectedError({
              ...expectedError,
              tierNetworkLimitError: getTierErrorMessage()
                ? getTierErrorMessage()
                : response.error.message
            });
          } else {
            Sentry.captureException(response.error);
            setUnexpectedError(generalError);
          }
          break;
        case statusCode.unexpected_error:
          setUnexpectedError(generalError);
          break;
        default:
          setUnexpectedError(generalError);
          break;
      }
    }
  };

  return (
    <>
      {createdKey && (
        <SuccessEntityModal
          isOpen={Boolean(createdKey)}
          icon={<SvgIcon src={CreatedSuccessIcon} height={120} width={212} />}
          title="New CSPR.click key is ready!"
          information="It was added to the list of your keys."
          onDismiss={handleCloseSuccessModal}
          previewData={createdKey}
        />
      )}

      {noClickKeys ? (
        <PreloadTableWindow
          icon={<SvgIcon src={KeyIcon} height={120} width={224} />}
          title="You have no CSPR.click keys yet"
          windowTitle="CSPR.click keys"
          subtitle="All your CSPR.click keys will be here once you create them."
          onSubmitTitle="Create key"
          onSubmit={handleOpenCreateKeyWindow}
        />
      ) : (
        <Container justify={'center'} align={'center'}>
          <ClickKeyList
            loading={loading}
            currentTire={clickTier.currentType}
            clickKeys={keys || null}
            pageCount={clickKeys?.page_count || 1}
            error={unexpectedError}
            refetch={clickKeyRefetch}
            onOpenCreateKeyWindow={handleOpenCreateKeyWindow}
            onOpenEditKeyWindow={handleOpenEditKeyWindow}
          />
        </Container>
      )}
      {openModal && (
        <CreateClickKeyWindow
          isOpen={openModal}
          confirmLabel="Create"
          onConfirm={handleCreateKey}
          title="Create CSPR.click key"
          dismissLabel="Cancel"
          expectedError={expectedError}
          unexpectedError={unexpectedError}
          currentTire={clickTier.currentType}
          onDismiss={handleCloseCreateKeyWindow}
          isTierLimitExceeded={isTierLimitExceeded}
          cloudKeyList={formattedCloudKeysList}
        />
      )}

      {keyToEdit && openEditModal && (
        <EditClickKeyWindow
          isOpen={openEditModal}
          keyToEdit={keyToEdit}
          title={'Edit CSPR.click key'}
          confirmLabel={'Save'}
          onUpdate={handleUpdateKey}
          onDismiss={handleCloseEditKeyWindow}
          expectedError={expectedError}
          unexpectedError={unexpectedError}
          dismissLabel={'Cancel'}
        />
      )}
    </>
  );
};
