import React, { useState } from 'react';
import styled from 'styled-components';
import {
  BodyText,
  CopyHash,
  FlexRow,
  SvgIcon,
  TableData,
  TableRow,
  Tooltip
} from '@make-software/cspr-ui';
import { ClickKeyResponseType, ClickKeyStatus } from 'app/build-api';
import { HiddenText } from 'app/components/common/hidden-text/hidden-text';
import ShowIcon from 'assets/icons/ic-show.svg';
import HideIcon from 'assets/icons/ic-hide.svg';
import EditIcon from 'assets/icons/ic-edit.svg';
import { CASPER, CASPER_TEST, MAINNET, TESTNET } from 'app/constants';

interface ClickKeyListItemProps {
  clickKey: ClickKeyResponseType;
  onOpenEditWindow: (key: ClickKeyResponseType) => void;
}

const StyledEditSvg = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    cursor: 'pointer'
  })
);
const StatusWrapper = styled(BodyText)<{ status?: string }>(({ theme, status }) =>
  theme.withMedia({
    textTransform: 'uppercase',
    color:
      status === ClickKeyStatus.ACTIVE
        ? theme.styleguideColors.contentGreen
        : theme.styleguideColors.contentSecondary
  })
);

const StyledHash = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    marginRight: '15px'
  })
);

const StyledSvgIcon = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    marginRight: '15px',
    cursor: 'pointer'
  })
);

const StyledDomain = styled.div(({ theme }) =>
  theme.withMedia({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: ['150px', '190px', '250px']
  })
);

const StyledBadge = styled.span(({ theme }) =>
  theme.withMedia({
    marginLeft: '9px',
    cursor: 'pointer',
    background: theme.styleguideColors.fillSecondaryBlueHover,
    borderRadius: '7px',
    padding: '0 4px',
    color: theme.styleguideColors.contentLightBlue,
    '&:hover': {
      background: theme.styleguideColors.fillSecondaryBlueClick
    }
  })
);

export const ClickKeyListItem = ({ clickKey, onOpenEditWindow }: ClickKeyListItemProps) => {
  const { id, name, api_key, status, domains = [], app_id, network, linked_cloud_key } = clickKey;

  const [showKey, setShowKey] = useState<boolean>(false);
  const handleShowKey = () => setShowKey((prevState) => !prevState);
  const maxNameLength = 30;

  const mapNetwork = (network: string) => {
    switch (network) {
      case MAINNET:
        return CASPER;
      case TESTNET:
        return CASPER_TEST;
    }
  };

  const openEditWindow = () => {
    onOpenEditWindow(clickKey);
  };
  return (
    <TableRow key={id}>
      <TableData>
        <BodyText size={3} monotype>
          {name && name.length > maxNameLength ? `${name.slice(0, maxNameLength - 3)}...` : name}
        </BodyText>
      </TableData>
      <TableData>
        <BodyText size={3} monotype>
          {app_id}
        </BodyText>
      </TableData>
      <TableData>
        <FlexRow justify={'flex-start'} align={'center'}>
          <StyledHash size={3} scale={'xs'} monotype>
            <HiddenText text={api_key} hide={!showKey} dotsNumber={api_key.length - 8} />
          </StyledHash>
          <StyledSvgIcon src={showKey ? ShowIcon : HideIcon} onClick={handleShowKey} />
          <CopyHash value={api_key} label="" copiedLabel="" variation="gray" />
        </FlexRow>
      </TableData>
      <TableData>
        <BodyText size={3} monotype>
          <div>{linked_cloud_key.name}</div>
          <HiddenText
            text={linked_cloud_key.hash}
            hide={true}
            dotsNumber={4}
            color={'gray'}
            size={'xs'}
          />
        </BodyText>
      </TableData>
      <TableData>
        <BodyText size={3} monotype>
          <FlexRow>
            <StyledDomain>{domains.length && domains[0]}</StyledDomain>
            <>
              {domains.length > 1 && (
                <Tooltip
                  title={
                    <>
                      {domains.map((d) => (
                        <div>{d}</div>
                      ))}
                    </>
                  }
                  paddingScale={1}
                >
                  <StyledBadge>
                    <BodyText size={3} scale={'xs'}>
                      more
                    </BodyText>
                  </StyledBadge>
                </Tooltip>
              )}
            </>
          </FlexRow>
        </BodyText>
      </TableData>
      <TableData>
        <BodyText size={3} monotype>
          {mapNetwork(network)}
        </BodyText>
      </TableData>
      <TableData>
        <StatusWrapper status={status} size={3} monotype>
          {status}
        </StatusWrapper>
      </TableData>
      <TableData align="right">
        <BodyText size={3} monotype>
          <StyledEditSvg src={EditIcon} onClick={openEditWindow} />
        </BodyText>
      </TableData>
    </TableRow>
  );
};
