import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SvgIcon } from '@make-software/cspr-ui';
import NoAccessIcon from 'assets/logos/not_found.svg';
import { BLANK_LAYOUT } from 'app/constants';
import UnauthorizedLayout from 'app/layouts/unauthorized-layout';
import { InformationWindow } from 'app/components/common/information-window/information-window';
import { HOME_PATH } from 'app/route/paths';

interface NotAccessProps {
  title?: string;
  information?: string;
}

const StyledNoAccess = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    marginBottom: '25px'
  })
);

export const NoAccess = ({ title, information }: NotAccessProps) => {
  const navigate = useNavigate();

  const handleHomePageRedirect = () => {
    navigate(HOME_PATH);
  };

  return (
    <UnauthorizedLayout title="No access" type={BLANK_LAYOUT}>
      <InformationWindow
        bodyImg={<StyledNoAccess src={NoAccessIcon} width={195} height={119} />}
        title={title ? title : "You don't have an access"}
        information={information ? information : "You don't have an access to this feature."}
        onConfirm={handleHomePageRedirect}
        confirmLabel="Return to Homepage"
      />
    </UnauthorizedLayout>
  );
};
