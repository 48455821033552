import React from 'react';
import { FlexRow } from '@make-software/cspr-ui';
import { PaginationInfoText } from './pagination-info-text';
import { PaginationDropdown } from './pagination-dropdown';

export const RowsPerPage = ({
  value,
  items,
  onChange
}: {
  value: number;
  items: number[];
  onChange: (perPage: any) => void;
}) => {
  return (
    <FlexRow itemsSpacing={4} align={'center'}>
      <PaginationInfoText>Show rows</PaginationInfoText>
      <PaginationDropdown value={value} items={items} onChange={onChange} />
    </FlexRow>
  );
};
