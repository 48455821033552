import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  BodyText,
  Checkbox,
  CheckboxFontSize,
  FlexColumn,
  formatDateShort,
  formatNumber,
  PageTile
} from '@make-software/cspr-ui';
import {
  GraphComponent,
  GraphComponentDataFormatType,
  YAxisRangeStartType
} from './graph-component';

export const customColorsByIndex: Record<number, string> = {
  0: '#31DE91',
  1: '#7490FF',
  2: '#F1BF0B',
  3: '#FF9C65',
  4: '#FF7A9A',
  5: '#BAE780',
  6: '#7FD8C8',
  7: '#F362E0',
  8: '#0021A5'
};

const ChartBlock = styled.div(({ theme }) =>
  theme.withMedia({
    display: 'flex',
    width: ['100%', '100%', '100%'],
    flexDirection: 'column',
    flexShrink: 0,
    alignItems: ['center', 'center', 'flex-start'],
    backgroundColor: theme.styleguideColors.backgroundPrimary,
    padding: ['20px 16px 32px', '20px 20px 36px']
  })
);

const TitleContainer = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    alignItems: 'flex-start',
    width: '100%'
  })
);

const CheckboxListContainer = styled.div<{ hasSingleItem?: boolean }>(({ theme, hasSingleItem }) =>
  theme.withMedia({
    marginLeft: [0, 120, 60, 63],
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    ...(!hasSingleItem && {
      '& > * + *': {
        marginLeft: [24, 32, 32]
      }
    }),
    ...(!hasSingleItem && {
      '& >:last-child': {
        marginTop: [28, 0, 0],
        marginLeft: [0, 32, 32]
      }
    })
  })
);

export interface EntityCountChartProps {
  title: string;
  yAxisLabelText?: string;
  data: GraphComponentDataFormatType[];
  chartHeader: React.ReactNode;
  loading: boolean;
  error?: React.ReactNode | string;
  yAxisRangeStart?: YAxisRangeStartType;
}
export const EntityCountChart = (props: EntityCountChartProps) => {
  const { data, title, chartHeader } = props;
  const theme = useTheme();

  const [selectedCheckboxKeys, setSelectedCheckboxKeys] = useState<string[]>([]);

  const selectedDataKeys: string[] = data
    .filter((item) => (item.checkboxLabel ? item.initiallySelected : true))
    .map((item) => item.graphKey);

  useEffect(() => {
    setSelectedCheckboxKeys(selectedDataKeys);
  }, [data]);

  const handleCheckboxChange = (key: string) => {
    if (selectedCheckboxKeys.length === 1 && selectedCheckboxKeys.includes(key)) return;

    if (selectedCheckboxKeys.includes(key)) {
      setSelectedCheckboxKeys(selectedCheckboxKeys.filter((selectedKey) => selectedKey !== key));
    } else {
      setSelectedCheckboxKeys([...selectedCheckboxKeys, key]);
    }
  };

  const dataWithMatchingColors = data.map((item: GraphComponentDataFormatType, idx: number) => ({
    ...item,
    themeColor: customColorsByIndex[idx]
  }));

  const filteredData = dataWithMatchingColors.filter((item) =>
    selectedCheckboxKeys.includes(item.graphKey)
  );

  const multipleGraphChart = dataWithMatchingColors.length >= 1;

  return (
    <PageTile>
      <ChartBlock>
        <TitleContainer itemsSpacing={8}>
          <BodyText scale={'xl'} size={1} variation="black" noWrap>
            {title}
          </BodyText>
          {chartHeader}
        </TitleContainer>

        <GraphComponent
          graphData={filteredData}
          {...props}
          multipleGraphChart={multipleGraphChart}
          xAxisTickFormatter={formatDateShort}
          yAxisValueKey="date"
          tooltipFormatter={formatNumber}
          yAxisTickFormatter={formatNumber}
        />

        {multipleGraphChart && (
          <CheckboxListContainer hasSingleItem={dataWithMatchingColors.length === 1}>
            {dataWithMatchingColors.map((item, idx) => (
              <Checkbox
                key={idx}
                label={item.checkboxLabel}
                checked={selectedCheckboxKeys.includes(item.graphKey)}
                customCheckedColor={item.themeColor}
                customUncheckedColor={theme.styleguideColors.contentTertiary}
                onChange={() => handleCheckboxChange(item.graphKey)}
                checkboxFontSize={CheckboxFontSize.small}
              />
            ))}
          </CheckboxListContainer>
        )}
      </ChartBlock>
    </PageTile>
  );
};
