import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import UnauthorizedLayout from 'app/layouts/unauthorized-layout';
import EmailConfirmationIcon from 'assets/logos/email-confirmation.svg';
import { BodyText, SvgIcon } from '@make-software/cspr-ui';
import { SIGN_UP_PATH } from 'app/route/paths';
import { InformationWindow } from 'app/components/common/information-window/information-window';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { useOnMountUnsafe } from 'hooks/useOnMountUnsafe';
import {
  AUTH_TOKEN,
  GENERAL_ERROR_DESCRIPTION,
  GENERAL_ERROR_MESSAGE,
  statusCode
} from 'app/constants';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import NotFoundIcon from 'assets/logos/no-found.svg';

const EMAIL = 'email';
const TOKEN = 'token';

export const SendConfirmationEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [title, setTitle] = useState<string>('');
  const [info, setInfo] = useState<React.ReactElement | string>('');
  const [error, setError] = useState<ErrorResult | null>(null);
  const [counter, setCounter] = useState<number>(60);

  const email = searchParams.get(EMAIL)?.replaceAll(' ', '+');
  const token = searchParams.get(TOKEN) || '';

  useEffect(() => {
    if (title) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, title]);

  const sendingConfirmationEmail = async () => {
    const response = await buildApi.sendConfirmEmail(token);

    if (response.error) {
      const err = {
        code: GENERAL_ERROR_MESSAGE,
        message: GENERAL_ERROR_DESCRIPTION
      };
      switch (response.httpCode) {
        case statusCode.authentication_error:
        case statusCode.access_denied_error:
          setError(response.error);
          localStorage.removeItem(AUTH_TOKEN);
          Sentry.captureException(response.error);
          break;
        case statusCode.unexpected_error:
          setError(err);
          break;
        default:
          setError(err);
          break;
      }
    }

    const delayBeforeRedirect = (delay: number) =>
      new Promise(() =>
        setTimeout(() => {
          if (response.httpCode === statusCode.no_content) {
            setTitle(`Email has been sent to ${email}!`);
            setInfo(
              <>
                Please, check your email. <br /> Don't see an email? Check your spam mailbox or
                click ‘Resend email’.
              </>
            );
          }
        }, delay)
      );

    await delayBeforeRedirect(3000);
  };

  useOnMountUnsafe(sendingConfirmationEmail);

  const resendEmail = async () => {
    setCounter(60);
    await sendingConfirmationEmail();
  };
  const backToRegistration = () => {
    navigate(SIGN_UP_PATH);
  };

  if (error) {
    return (
      <UnauthorizedLayout title="Email confirmation">
        <ErrorWindow
          title={error.code}
          information={error.message}
          icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
        />
      </UnauthorizedLayout>
    );
  }

  return (
    <UnauthorizedLayout title="Send confirmation email">
      <InformationWindow
        title={title ? title : 'Confirm your email address'}
        confirmLabel={`Resend email ${counter !== 0 && title ? `(${counter})` : ''}`}
        onConfirm={resendEmail}
        dismissLabel="Back to registration"
        onDismiss={backToRegistration}
        information={
          info ? (
            info
          ) : (
            <>
              Hey, just one last step. We’ve send you an email confirmation letter to your address
              <BodyText size={1} variation="black">
                {` `}
                {email}
              </BodyText>
              , please go and confirm your email address.
            </>
          )
        }
        bodyImg={<SvgIcon src={EmailConfirmationIcon} width={219} height={125} />}
        disableConfirm={counter > 0}
      />
    </UnauthorizedLayout>
  );
};
