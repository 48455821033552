import styled from 'styled-components';
import { Button, FlexColumn, FlexRow } from '@make-software/cspr-ui';
import React from 'react';
import { ProductType } from 'app/build-api';

export interface TabData {
  key: string;
  name: string;
}

export const statisticsTabs: TabData[] = [
  { key: ProductType.Click, name: 'CSPR.click' },
  { key: ProductType.Cloud, name: 'CSPR.cloud' }
];

const StyledTab = styled(Button)(({ theme }) =>
  theme.withMedia({
    height: '32px',
    fontSize: '16px',
    fontWeight: 400,
    width: '160px',
    ':first-child': {
      borderRadius: '4px 0 0 4px'
    },
    ':last-child': {
      borderRadius: '0 4px 4px 0'
    }
  })
);

export interface ActiveTabsProps {
  activeTab: TabData;
  onChange: (activeTab: TabData) => void;
}

export const ActiveTabs = (props: ActiveTabsProps) => {
  return (
    <FlexColumn gap={24}>
      <FlexRow justify="center">
        {statisticsTabs.map((tab) => (
          <StyledTab
            key={tab.key}
            color={props.activeTab.key === tab.key ? 'primaryBlue' : 'secondaryBlue'}
            onClick={() => props.onChange(tab)}
          >
            {tab.name}
          </StyledTab>
        ))}
      </FlexRow>
    </FlexColumn>
  );
};
