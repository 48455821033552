import React from 'react';
import styled from 'styled-components';
import {
  BodyText,
  FlexColumn,
  FlexRow,
  formatNumber,
  SvgIcon,
  useMatchMedia
} from '@make-software/cspr-ui';
import ArrowRightIcon from 'assets/icons/ic-arrow-right.svg';
import { RowsPerPage } from './rows-per-page';
import { PaginationArrowButton, PaginationButton } from './pagination-button';
import { PaginationInput } from './pagination-input';

const Container = styled(FlexRow)<{ showItemsCount?: boolean }>(({ theme, showItemsCount }) =>
  theme.withMedia({
    height: [80, 48],
    flexDirection: ['column', 'row', 'row'],
    justifyContent: showItemsCount ? 'space-between' : 'flex-end',
    padding: ['12px 10px', '12px 20px'],
    margin: ['0 0 10px 0', '0']
  })
);

const MirroredSvgIcon = styled(SvgIcon)(({ theme }) => ({
  transform: 'rotate(180deg)'
}));

export interface PaginationProps {
  pageCount?: number;
  currentPage?: number;
  pageLimit?: number;
  itemCount?: number;
  handlePageActions: (page: number, limit: number) => void;
}

const ROWS_PER_PAGE = [5, 10, 25, 50];

export const Pagination = ({
  pageCount = 1,
  currentPage = 1,
  pageLimit = 5,
  itemCount,
  handlePageActions
}: PaginationProps) => {
  const prevPageHandler = () => {
    handlePageActions(currentPage - 1, pageLimit);
  };

  const nextPageHandler = () => {
    handlePageActions(currentPage + 1, pageLimit);
  };

  const setPageItemLimit = (limit: number) => {
    handlePageActions(currentPage, limit);
  };

  const firstPageHandler = () => {
    handlePageActions(1, pageLimit);
  };

  const lastPageHandler = () => {
    handlePageActions(pageCount, pageLimit);
  };

  const handlePaginationChange = (page: number) => {
    handlePageActions(page, pageLimit);
  };

  const isFirstEnabled = currentPage > 1;
  const isPrevEnabled = currentPage > 1;
  const isNextEnabled = currentPage < pageCount;
  const isLastEnabled = currentPage < pageCount;

  const onMobile = (
    <FlexColumn itemsSpacing={4}>
      <FlexRow itemsSpacing={4}>
        <PaginationButton
          color={'secondaryRed'}
          disabled={!isFirstEnabled}
          onClick={firstPageHandler}
        >
          First
        </PaginationButton>
        <PaginationArrowButton
          color={'secondaryRed'}
          disabled={!isPrevEnabled}
          onClick={prevPageHandler}
        >
          <MirroredSvgIcon src={ArrowRightIcon} />
        </PaginationArrowButton>
        <PaginationInput
          pageCount={pageCount}
          currentPage={currentPage}
          onChange={handlePaginationChange}
        />
        <PaginationArrowButton
          color={'secondaryRed'}
          disabled={!isNextEnabled}
          onClick={nextPageHandler}
        >
          <SvgIcon src={ArrowRightIcon} />
        </PaginationArrowButton>
        <PaginationButton
          color={'secondaryRed'}
          disabled={!isLastEnabled}
          onClick={lastPageHandler}
        >
          Last
        </PaginationButton>
      </FlexRow>
      <RowsPerPage value={pageLimit} items={ROWS_PER_PAGE} onChange={setPageItemLimit} />
    </FlexColumn>
  );

  const onAbove = (
    <FlexRow itemsSpacing={40}>
      <RowsPerPage value={pageLimit} items={ROWS_PER_PAGE} onChange={setPageItemLimit} />
      <FlexRow itemsSpacing={4} align={'center'}>
        <PaginationButton
          disabled={!isFirstEnabled}
          onClick={firstPageHandler}
          color={'secondaryRed'}
        >
          First
        </PaginationButton>
        <PaginationArrowButton
          color={'secondaryRed'}
          disabled={!isPrevEnabled}
          onClick={prevPageHandler}
        >
          <MirroredSvgIcon src={ArrowRightIcon} />
        </PaginationArrowButton>
        <PaginationInput
          pageCount={pageCount}
          currentPage={currentPage}
          onChange={handlePaginationChange}
        />
        <PaginationArrowButton
          color={'secondaryRed'}
          disabled={!isNextEnabled}
          onClick={nextPageHandler}
        >
          <SvgIcon src={ArrowRightIcon} />
        </PaginationArrowButton>
        <PaginationButton
          disabled={!isLastEnabled}
          onClick={lastPageHandler}
          color={'secondaryRed'}
        >
          Last
        </PaginationButton>
      </FlexRow>
    </FlexRow>
  );

  const responsivePagination = useMatchMedia(
    [onMobile, onAbove],
    [pageLimit, currentPage, pageCount]
  );

  const noData = pageCount < 1;
  if (noData) {
    return (
      <Container>
        <span>&nbsp;</span>
      </Container>
    );
  }

  return (
    <Container showItemsCount={!!itemCount}>
      {itemCount && (
        <BodyText size={2} scale={'xs'} variation={'darkGray'}>
          {formatNumber(itemCount)} total rows
        </BodyText>
      )}
      {responsivePagination}
    </Container>
  );
};

export default Pagination;
