import React from 'react';
import styled from 'styled-components';
import { matchSize, TableData, TableRow, TableRowType } from '@make-software/cspr-ui';
import { SkeletonLoader } from 'app/components/common/skeleton-loader/skeleton-loader';

type Props = {
  columnsLength: number;
  rowsLength?: number;
  tableRowType?: TableRowType;
};

type TableLoaderRowProps = {
  type: TableRowType;
};

const TableLoaderRow = styled(TableRow)<TableLoaderRowProps>(({ theme, type }) => ({
  height: matchSize(
    {
      [TableRowType.TextWithAvatar]: '56px',
      [TableRowType.TextWithIcon]: '52px',
      [TableRowType.TextSingleLine]: '48px'
    },
    type
  )
}));

export const TableLoader = ({
  columnsLength,
  rowsLength = 5,
  tableRowType = TableRowType.TextSingleLine
}: Props) => {
  const tableData = Array(rowsLength).fill(undefined);
  const columnsRow = Array(columnsLength).fill(null);

  return (
    <>
      {tableData.map((item, index) => (
        <TableLoaderRow key={'row' + index} type={tableRowType} loading>
          {columnsRow.map((item2, index2) => (
            <TableData key={'column' + index2}>
              <SkeletonLoader />
            </TableData>
          ))}
        </TableLoaderRow>
      ))}
    </>
  );
};
