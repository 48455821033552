import { FlexColumn, FlexRow, SvgIcon } from '@make-software/cspr-ui';
import styled from 'styled-components';
import CheckmarkIcon from 'assets/icons/ic-check.svg';
import CloseIcon from 'assets/icons/ic-close.svg';
import { PASSWORD_LENGTH } from 'app/constants';

export interface PasswordHintsProps {
  password: string;
}

const HintRow = styled(FlexRow)(() => ({
  margin: '-8px 0 -8px 0'
}));

const StyledHint = styled.div<{ isValid: boolean }>(({ theme, isValid }) => ({
  color: isValid ? theme.styleguideColors.contentGreen : theme.styleguideColors.contentSecondary,
  fontSize: '11px',
  fontWeight: '500',
  lineHeight: '16px'
}));

export const PasswordHints = ({ password }: PasswordHintsProps) => {
  return (
    <HintRow gap={8}>
      <FlexColumn>
        {password.length < PASSWORD_LENGTH ? (
          <SvgIcon src={CloseIcon} />
        ) : (
          <SvgIcon src={CheckmarkIcon} />
        )}
      </FlexColumn>
      <FlexColumn>
        <StyledHint
          isValid={password.length >= PASSWORD_LENGTH}
        >{`At least ${PASSWORD_LENGTH} characters`}</StyledHint>
      </FlexColumn>
    </HintRow>
  );
};
