import { GetResponseType } from '../types';
import useApi from '../hooks/useApi';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import { decodeJWT } from 'app/components/utils/utils';

export interface InviteUserRequestType {
  full_name: string;
  email: string;
  access_list: AccessItem[];
}

export interface InviteUserResponseType {
  id: string;
  access_list: AccessItem[];
  organization_id: string;
  role: UserRole;
  full_name: string;
  email: string;
  is_email_confirmed: boolean;
  is_active: boolean;
  status_description: UserStatus;
  created_at: string;
  updated_at: string;
}

export interface GetInvitedUsersResponseType {
  item_count: number;
  page_count: number;
  data: InviteUserResponseType[] | null;
}

export interface UpdateUserRequestType {
  is_active: boolean;
  full_name: string;
  access_list: AccessItem[];
}

export enum UserStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum AccessItem {
  UserManagement = 'user_management',
  ClickKeyManagement = 'click_key_management',
  CloudKeyManagement = 'cloud_key_management',
  TierManagement = 'tier_management',
  Statistics = 'statistics'
}

export enum UserRole {
  Owner = 'owner'
}

export const inviteUser = async (
  payload: InviteUserRequestType
): Promise<GetResponseType<InviteUserResponseType>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/users`,
    'POST',
    payload,
    {},
    WITH_AUTH
  );
  return {
    data,
    httpCode,
    error,
    loading
  };
};

export const getInvitedUsers = async (
  page?: number,
  pageSize?: number
): Promise<GetResponseType<GetInvitedUsersResponseType>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const getPageOption = () => {
    if (!page && !pageSize) return '';
    if (page && pageSize) return `?page=${page}&page_size=${pageSize}`;
    if (!page && pageSize) return `?page_size=${pageSize}`;
    if (page && !pageSize) return `?page=${page}`;
  };

  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/users${getPageOption()}`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
  return {
    data,
    httpCode,
    error,
    loading
  };
};

export const updateUser = async (
  userId: string,
  payload: UpdateUserRequestType
): Promise<GetResponseType<InviteUserResponseType>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/users/${userId}`,
    'PUT',
    payload,
    {},
    WITH_AUTH
  );
  return {
    data,
    httpCode,
    error,
    loading
  };
};
