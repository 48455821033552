import { CloudLogItem } from 'app/build-api/statistics/cloud-logs/cloud-logs-list';
import {
  BodyText,
  CopyHash,
  FlexRow,
  TableData,
  TableRow,
  TruncateBox,
  Tooltip
} from '@make-software/cspr-ui';
import React from 'react';
import { formatDuration, formatSize } from 'app/components/utils/measures-format';
import { formatTimestamp } from 'app/components/utils/date-time';
import { CloudKeyResponseType } from 'app/build-api';

export interface RequestsLogListItemProps {
  item: CloudLogItem;
  apiName: string | number;
  cloudKey?: CloudKeyResponseType;
}

export const RequestsLogListItem = ({ item, apiName, cloudKey }: RequestsLogListItemProps) => {
  const key = item['@timestamp'];

  return (
    <TableRow>
      <TableData key={`${key}-time`}>
        <BodyText size={3}>{formatTimestamp(key)}</BodyText>
      </TableData>
      <TableData key={`${key}-key`}>
        <BodyText size={3} monotype>
          <div>{cloudKey?.name}</div>
          <BodyText variation={'gray'} size={3} scale={'xs'} >
            {cloudKey?.hash}
          </BodyText>
        </BodyText>
      </TableData>
      <TableData key={`${key}-api`}>
        <BodyText size={3}>{apiName}</BodyText>
      </TableData>
      <TableData key={`${key}-endpoint`}>
        <BodyText size={3}>
          <FlexRow itemsSpacing={16}>
            <Tooltip title={item.endpoint} scale={'xs'} lineHeight={'xs'} paddingScale={1}>
              <TruncateBox size={2}>{item.endpoint}</TruncateBox>
            </Tooltip>
            <CopyHash value={item.endpoint} label="" copiedLabel="" variation="gray" />
          </FlexRow>
        </BodyText>
      </TableData>
      <TableData key={`${key}-status`}>
        <BodyText size={3}>{item.status}</BodyText>
      </TableData>
      <TableData key={`${key}-size`}>
        <BodyText size={3}>{formatSize(item.size)}</BodyText>
      </TableData>
      <TableData key={`${key}-duration`}>
        <BodyText size={3}>{formatDuration(item.duration)}</BodyText>
      </TableData>
    </TableRow>
  );
};
