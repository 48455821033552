import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useTheme } from 'styled-components';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  BodyText,
  FlexRow,
  InfoColourIcon,
  SvgIcon,
  useClickAway,
  WarningMessage
} from '@make-software/cspr-ui';
import {
  ButtonsContainer,
  centerModalStyles,
  ErrorText,
  ErrorTextContainer,
  ModalContainer,
  StyledCaption,
  StyledCaptionText,
  StyledConfirmButton,
  StyledDismissButton,
  StyledInput
} from 'app/components/home/components/create-modal-styles';
import { ErrorResult } from 'app/build-api/types';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import NotFoundIcon from 'assets/logos/no-found.svg';
import { AccessItem, CloudKeyRequestType, CloudKeyStatus, ProductType } from 'app/build-api';
import { checkMaxLength } from 'app/validators';
import { ExpectedErrors } from 'app/components/home/components/cloud-keys/cloud-key-container';
import { SUBSCRIPTIONS_PATH } from 'app/route/paths';
import { checkGrantedAccess } from 'app/components/utils/utils';

export interface CreateCloudKeyWindowProps {
  isOpen: boolean;
  title: string;
  confirmLabel: string;
  onConfirm: (data: CloudKeyRequestType) => void;
  dismissLabel?: string;
  onDismiss: () => void;
  currentTire: string;
  expectedError?: ExpectedErrors | undefined;
  unexpectedError?: ErrorResult | null;
  isTierLimitExceeded?: boolean;
  portalClass?: string;
}

export const CreateCloudKeyWindow = ({
  isOpen,
  title,
  confirmLabel,
  onConfirm,
  dismissLabel,
  onDismiss,
  unexpectedError,
  currentTire,
  expectedError,
  isTierLimitExceeded,
  portalClass = 'portal'
}: CreateCloudKeyWindowProps) => {
  const theme = useTheme();
  const modalStyle = {
    overlay: {
      backgroundColor: theme.styleguideColors.backgroundOverlay,
      zIndex: 15
    },
    content: {
      ...centerModalStyles,
      ...{
        border: 'none',
        backgroundColor: theme.styleguideColors.backgroundPrimary,
        borderTop: `4px solid ${theme.styleguideColors.contentRed}`,
        borderColor: theme.styleguideColors.contentRed,
        boxShadow: '0px 16px 48px rgba(26, 25, 25, 0.2)'
      }
    }
  };

  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<Record<'name', string>>({
    name: ''
  });

  const tierAccessProvided = checkGrantedAccess(AccessItem.TierManagement);
  const capitalizedCurrentTier =
    currentTire && currentTire.charAt(0).toUpperCase() + currentTire.slice(1);

  const clearForm = () => {
    setName('');
    setFormErrors({
      ...formErrors,
      name: ''
    });
  };

  useEffect(() => {
    setShowModal(isOpen);
    clearForm();
  }, [isOpen]);

  const { ref } = useClickAway({
    callback: () => {
      clearForm();
      onDismiss();
    }
  });

  const handleKeyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setName(val);
  };

  const handleKeyNameOnBlur = () => {
    setFormErrors({
      ...formErrors,
      name: !name.length ? 'This field is required' : checkMaxLength(name)
    });
  };

  const handleConfirm = () => {
    if (!name.length) {
      setFormErrors({
        ...formErrors,
        name: 'This field is required'
      });
      return;
    }

    onConfirm && !formErrors.name && onConfirm({ name: name, status: CloudKeyStatus.ACTIVE });
  };

  const handleOnDismiss = () => {
    clearForm();
    onDismiss();
  };

  const handleUpgradeTier = () => {
    handleOnDismiss();
    navigate({
      pathname: SUBSCRIPTIONS_PATH,
      search: createSearchParams({
        activeTab: ProductType.Cloud
      }).toString()
    });
  };

  return (
    <>
      {showModal && (
        <ReactModal
          isOpen={showModal}
          style={modalStyle}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          portalClassName={portalClass}
        >
          <ModalContainer ref={ref} gap={20}>
            {unexpectedError ? (
              <ErrorWindow
                title={unexpectedError.code || ''}
                information={unexpectedError.message}
                withModal
                icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
              />
            ) : (
              <>
                <StyledCaption>
                  <StyledCaptionText size={1} scale="lg">
                    {title}
                  </StyledCaptionText>
                </StyledCaption>

                {isTierLimitExceeded ? (
                  <WarningMessage
                    title={`The "${capitalizedCurrentTier}" tier comes with certain restrictions`}
                    message={
                      tierAccessProvided
                        ? 'Upgrade your plan to access this feature.'
                        : 'Please ask an organization owner to upgrade the plan.'
                    }
                    iconSrc={InfoColourIcon}
                    margin={'0'}
                  />
                ) : (
                  <>
                    <FlexRow justify="center">
                      <StyledInput
                        value={name}
                        label={<BodyText size={1}>Name</BodyText>}
                        placeholder="Key name"
                        onChange={handleKeyName}
                        onBlur={handleKeyNameOnBlur}
                        error={!!formErrors.name}
                        validationText={formErrors.name}
                      />
                    </FlexRow>
                    {expectedError && Object.keys(expectedError).length !== 0 && (
                      <ErrorTextContainer justify={'center'}>
                        <ErrorText size={3}>{expectedError.plainError}</ErrorText>
                      </ErrorTextContainer>
                    )}
                  </>
                )}
                <ButtonsContainer justify={'space-between'}>
                  {dismissLabel && (
                    <StyledDismissButton
                      color={'utility'}
                      onClick={handleOnDismiss}
                      fullWidth={isTierLimitExceeded && !tierAccessProvided}
                    >
                      {dismissLabel}
                    </StyledDismissButton>
                  )}

                  {isTierLimitExceeded && tierAccessProvided ? (
                    <StyledConfirmButton onClick={handleUpgradeTier}>Upgrade</StyledConfirmButton>
                  ) : !isTierLimitExceeded ? (
                    <StyledConfirmButton onClick={handleConfirm}>
                      {confirmLabel}
                    </StyledConfirmButton>
                  ) : (
                    ''
                  )}
                </ButtonsContainer>
              </>
            )}
          </ModalContainer>
        </ReactModal>
      )}
    </>
  );
};

export default CreateCloudKeyWindow;
