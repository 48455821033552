import { useState } from 'react';
import { buildApi, GetResponseType } from 'app/build-api/types';
import { useOnMountUnsafe } from 'hooks/useOnMountUnsafe';
import { GetCloudKeyResponseType } from 'app/build-api/cloud-key/cloud-key';

export const useGetCloudKeys = (page?: number, pageSize?: number) => {
  const [getCloudKeyResponse, setGetCloudKeyResponse] = useState<
    GetResponseType<GetCloudKeyResponseType>
  >({
    data: null,
    loading: false,
    error: null
  });

  const fetchCloudKeys = async (page?: number, pageSize?: number) => {
    const response = await buildApi.getCloudKeys(page, pageSize);
    setGetCloudKeyResponse(response);
  };

  useOnMountUnsafe(() => fetchCloudKeys(page, pageSize));

  const refetch = (page?: number, pageSize?: number) => fetchCloudKeys(page, pageSize);

  return {
    data: getCloudKeyResponse.data,
    httpCode: getCloudKeyResponse.httpCode,
    error: getCloudKeyResponse.error,
    loading: getCloudKeyResponse.loading,
    refetch: refetch
  };
};
