import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import UnauthorizedLayout from 'app/layouts/unauthorized-layout';
import { ResetPasswordWindow } from 'app/components/reset-password/reset-password-window';
import CSPRBuildLogoOriginal from 'assets/logos/cspr-build-logo-original.svg';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import { BodyText, FlexRow, SvgIcon } from '@make-software/cspr-ui';
import NotFoundIcon from 'assets/logos/no-found.svg';
import {
  BLANK_LAYOUT,
  GENERAL_ERROR_DESCRIPTION,
  GENERAL_ERROR_MESSAGE,
  statusCode,
  TOKEN_EXPIRED_ERROR
} from 'app/constants';
import { HOME_PATH, SIGN_IN_PATH } from 'app/route/paths';

const TOKEN = 'token';
const EMAIL = 'email';
export const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [error, setError] = useState<ErrorResult | null>(null);

  const token = searchParams.get(TOKEN) || '';
  const email = searchParams.get(EMAIL)?.replaceAll(' ', '+') || '';

  const handleSubmit = async (password: string) => {
    const response = await buildApi.resetPassword(password, token);

    if (response.data?.token) {
      navigate(HOME_PATH);
    }

    if (response.error) {
      const err = {
        code: GENERAL_ERROR_MESSAGE,
        message: GENERAL_ERROR_DESCRIPTION
      };
      switch (response.httpCode) {
        case statusCode.authentication_error:
          if (response.error?.code === TOKEN_EXPIRED_ERROR) {
            setError({
              ...response.error,
              description: (
                <>
                  Your token has expired.
                  <br /> To get the new one, please go to the{' '}
                  <Link to={SIGN_IN_PATH}>
                    <BodyText size={1}>Sign in</BodyText>
                  </Link>{' '}
                  page
                </>
              )
            });
          } else {
            setError(response.error);
            Sentry.captureException(response.error);
          }
          break;
        case statusCode.invalid_input_error:
        case statusCode.access_denied_error:
        case statusCode.validation_error:
          setError(response.error);
          Sentry.captureException(response.error);
          break;
        case statusCode.unexpected_error:
          setError(err);
          break;
        default:
          setError(err);
          break;
      }
    }
  };

  if (error) {
    return (
      <UnauthorizedLayout title="Reset password" type={BLANK_LAYOUT}>
        <ErrorWindow
          title={error.code}
          information={error.description ? error.description : error.message}
          icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
        />
      </UnauthorizedLayout>
    );
  }

  return (
    <UnauthorizedLayout title="Reset password" type={BLANK_LAYOUT}>
      <FlexRow>
        <SvgIcon src={CSPRBuildLogoOriginal} width={249} height={44} />
      </FlexRow>

      <ResetPasswordWindow
        title="Set a new password"
        information="Please set a new password for your account."
        confirmColor="red"
        onConfirm={handleSubmit}
        confirmLabel="Done"
        customEmail={email}
      />
    </UnauthorizedLayout>
  );
};
