import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useLayoutEffect, useState } from 'react';
import { SIGN_IN_PATH } from 'app/route/paths';
import { AUTH_TOKEN } from 'app/constants';
import AuthorizedLayout from 'app/layouts/authorized-layout';
import { SkeletonLoader } from 'app/components/common/skeleton-loader/skeleton-loader';
import { capitalizeFirstLetter } from 'app/components/utils/utils';

export const ProtectedRoutes = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  const pageTitle = location.pathname.split('/')[1];

  useLayoutEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      setLoggedIn(true);
      setLoading(false);
    } else {
      setLoggedIn(false);
      setLoading(false);
    }
  }, []);

  if (loading) return <SkeletonLoader height={'100vh'} />;

  return loggedIn ? (
    <AuthorizedLayout title={pageTitle ? capitalizeFirstLetter(pageTitle) : 'Home'}>
      <Outlet />
    </AuthorizedLayout>
  ) : (
    <Navigate to={SIGN_IN_PATH} replace state={{ from: location }} />
  );
};
