import { CreatedEntityResponseType, GetResponseType } from '../types';
import useApi from '../hooks/useApi';
import { WITH_AUTH } from 'app/constants';

export const sendConfirmEmail = async (
  token: string
): Promise<GetResponseType<CreatedEntityResponseType>> => {
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/auth/email-confirmation`,
    'POST',
    null,
    {},
    WITH_AUTH,
    token
  );
  return {
    data,
    httpCode,
    error,
    loading
  };
};
