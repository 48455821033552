import { ClickFilterState, ClickUniqueAccountsCountGraphFilter } from './filter';
import { GetResponseType } from 'app/build-api/types';
import { UniqueHistogramResponse } from '../common';
import { getSearchParamsFromClickFilters } from 'app/components/utils/url-utils';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import { decodeJWT } from 'app/components/utils/utils';
import useApi from 'app/build-api/hooks/useApi';
import { getApiQueryParamsFromGraphFilters } from '../url-utils';

export const getUniqueAccountsHistogram = async (
  filters: ClickFilterState,
  graphFilter: ClickUniqueAccountsCountGraphFilter
): Promise<GetResponseType<UniqueHistogramResponse>> => {
  const searchParams = new URLSearchParams();
  const searchParamsWithFilters = getSearchParamsFromClickFilters(searchParams, filters);
  const searchParamsWithGraphFilters = getApiQueryParamsFromGraphFilters({
    searchParams: searchParamsWithFilters,
    groupBy: graphFilter.clickUniqueAccountsCountGroupBy,
    period: graphFilter.clickUniqueAccountsCountPeriod
  });
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));

  return await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/click-logs/unique-accounts-count?${searchParamsWithGraphFilters.toString()}`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
};
