import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { BodyText, FlexRow, SvgIcon, useClickAway } from '@make-software/cspr-ui';
import {
  ButtonsContainer,
  centerModalStyles,
  ErrorText,
  ErrorTextContainer,
  ModalContainer,
  StyledCaptionText,
  StyledConfirmButton,
  StyledDismissButton
} from 'app/components/home/components/create-modal-styles';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import NotFoundIcon from 'assets/logos/no-found.svg';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { AUTH_TOKEN, generalError, statusCode } from 'app/constants';
import { SIGN_IN_PATH } from 'app/route/paths';
import SuccessEntityModal from 'app/components/common/success-entity-modal/success-entity-modal';
import CreatedSuccessIcon from 'assets/logos/success-entity.svg';

export interface CustomRequestWindowProps {
  isOpen: boolean;
  onDismiss: () => void;
  portalClass?: string;
}

const TextAreaWrapper = styled.textarea<{ isEmpty?: boolean }>(({ theme, isEmpty }) =>
  theme.withMedia({
    resize: 'none',
    height: '120px',
    fontSize: '14px',
    width: '448px',
    border: `${isEmpty ? `1px solid ${theme.styleguideColors.contentRed}` : 'none'}`,
    backgroundColor: theme.styleguideColors.fillSecondary,
    borderRadius: '4px',
    padding: '8px',
    color: theme.styleguideColors.contentSecondary
  })
);

export const CustomRequestWindow = ({
  isOpen,
  onDismiss,
  portalClass = 'portal'
}: CustomRequestWindowProps) => {
  const theme = useTheme();
  const modalStyle = {
    overlay: {
      backgroundColor: theme.styleguideColors.backgroundOverlay,
      zIndex: 15
    },
    content: {
      ...centerModalStyles,
      ...{
        border: 'none',
        backgroundColor: theme.styleguideColors.backgroundPrimary,
        borderTop: `4px solid ${theme.styleguideColors.contentRed}`,
        borderColor: theme.styleguideColors.contentRed,
        boxShadow: '0px 16px 48px rgba(26, 25, 25, 0.2)'
      }
    }
  };
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
  const [unexpectedError, setUnexpectedError] = useState<ErrorResult | null>(null);
  const [formErrors, setFormErrors] = useState<Record<'message', string>>({
    message: ''
  });

  const clearForm = () => {
    setMessage('');
    setUnexpectedError(null);
    setFormErrors({
      ...formErrors,
      message: ''
    });
  };

  useEffect(() => {
    setShowModal(isOpen);
    clearForm();
  }, [isOpen]);

  const { ref } = useClickAway({
    callback: () => {
      clearForm();
      onDismiss();
    }
  });

  const handleMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = event.target.value;
    setMessage(val);
  };

  const handleConfirm = async () => {
    if (!message.length) {
      setFormErrors({
        ...formErrors,
        message: 'This field is required'
      });
      return;
    }

    const response = await buildApi.requestCustomTierMessage({ message });
    if (response.httpCode === statusCode.success) {
      setRequestSubmitted(true);
      handleOnDismiss();
    }

    if (response.error) {
      switch (response.httpCode) {
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        default:
          setUnexpectedError(generalError);
          break;
      }
    }
  };

  const handleOnDismiss = () => {
    clearForm();
    onDismiss();
  };

  const handleCloseSuccessWindow = () => {
    setRequestSubmitted(false);
  };

  return (
    <>
      {showModal && (
        <ReactModal
          isOpen={showModal}
          style={modalStyle}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          portalClassName={portalClass}
        >
          <ModalContainer ref={ref} gap={20}>
            {unexpectedError ? (
              <ErrorWindow
                title={unexpectedError.code || ''}
                information={unexpectedError.message}
                withModal
                icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
              />
            ) : (
              <>
                <StyledCaptionText size={1} scale="lg">
                  Custom plan request
                </StyledCaptionText>
                <BodyText size={3} scale={'sm'} variation={'darkGray'}>
                  Please describe your request in detail so that we can develop an optimal
                  personalized plan for you.
                </BodyText>
                <FlexRow justify="center">
                  <TextAreaWrapper
                    isEmpty={Boolean(formErrors.message)}
                    maxLength={2055}
                    value={message}
                    onChange={handleMessage}
                    placeholder="Describe your request"
                  />
                </FlexRow>
                {formErrors.message && (
                  <ErrorTextContainer>
                    <ErrorText size={2} scale={'xs'}>
                      Please describe your request
                    </ErrorText>
                  </ErrorTextContainer>
                )}
                <ButtonsContainer justify={'space-between'}>
                  <StyledDismissButton color={'utility'} onClick={handleOnDismiss}>
                    Cancel
                  </StyledDismissButton>
                  <StyledConfirmButton onClick={handleConfirm}>Submit</StyledConfirmButton>
                </ButtonsContainer>
              </>
            )}
          </ModalContainer>
        </ReactModal>
      )}
      {requestSubmitted && (
        <SuccessEntityModal
          isOpen={true}
          icon={<SvgIcon src={CreatedSuccessIcon} height={120} width={212} />}
          title={'Request Submitted'}
          information={"We've received your request and will be contacting you soon."}
          onDismiss={handleCloseSuccessWindow}
        />
      )}
    </>
  );
};

export default CustomRequestWindow;
