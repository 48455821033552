import { BodyText, NavLink } from '@make-software/cspr-ui';
import RouterLink, { RouterLinkProps } from './router-link';

export const MenuLink = ({ to, children, onClick }: RouterLinkProps & { onClick?: () => void }) => (
  <RouterLink
    to={to}
    render={(props) => (
      <NavLink
        {...props}
        href={to}
        onClick={(ev) => {
          if (!(ev.ctrlKey || ev.metaKey || ev.shiftKey)) {
            ev.preventDefault();
            props.onClick && props.onClick();
            onClick && onClick();
          }
        }}
      >
        <BodyText size={3}>{children}</BodyText>
      </NavLink>
    )}
  />
);
