import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PageFooter } from './components/page-footer';
import { PageHeader } from './components/page-header';

const Container = styled.div(({ theme }) => ({
  overflow: 'auto',
  minWidth: theme.minWidth,
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  backgroundColor: theme.styleguideColors.backgroundSecondary,
  color: theme.styleguideColors.contentPrimary,
  flexDirection: 'column',
  height: '100vh'
}));

const Content = styled.div(({ theme }) =>
  theme.withMedia({
    width: '100%',
    maxWidth: theme.maxWidth,
    flex: 1
  })
);

export interface PageLayoutProps {
  children?: React.ReactNode;
  title?: string;
  renderHeader?: () => React.ReactNode;
  hasNavBar?: boolean;
  showBreadcrumb?: boolean;
}

export const AuthorizedLayout = (props: PageLayoutProps) => {
  const { children, title } = props;

  useEffect(() => {
    document.title = `${title}`;
  }, [title]);

  return (
    <>
      <Container>
        <PageHeader />
        <Content>{children}</Content>
        <PageFooter />
      </Container>
    </>
  );
};

export default AuthorizedLayout;
