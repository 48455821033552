import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  FlexRow,
  HeaderText,
  PageTile,
  TableRow,
  TableRowType
} from '@make-software/cspr-ui';
import Table from 'app/components/table/table';
import BaseTableHeader from 'app/components/common/base-table/base-table-header';
import { ErrorResult, Pagination } from 'app/build-api/types';
import { InviteUserResponseType } from 'app/build-api';
import { UserListItem } from './user-list-item';

interface UserListProps {
  loading: boolean;
  pageCount: number;
  error?: ErrorResult | null;
  users: InviteUserResponseType[] | null;
  refetch?: (page: number, limit: number) => void;
  onOpenInviteUserWindow: () => void;
  onOpenEditUserWindow: (user: InviteUserResponseType) => void;
}

const StyledTableHeader = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginBottom: '24px'
  })
);

const StyledButton = styled(Button)(({ theme }) =>
  theme.withMedia({
    width: ['123px', '130px', '176px']
  })
);

const Container = styled.div(({ theme }) =>
  theme.withMedia({
    width: ['90%', '70%', '100%']
  })
);

export const UserList = ({
  users,
  loading,
  error,
  refetch,
  onOpenInviteUserWindow,
  onOpenEditUserWindow,
  pageCount
}: UserListProps) => {
  const [pagination, setPagination] = useState<Pagination>({ currentPage: 1, pageSize: 5 });
  const [userData, setUserData] = useState<InviteUserResponseType[] | null>(null);

  useEffect(() => {
    users && setUserData([...users]);
  }, [users]);

  const handleOpenEditUserWindow = (user: InviteUserResponseType) => {
    onOpenEditUserWindow(user);
  };

  const handlePageActions = (page: number, limit: number) => {
    if (pagination.pageSize != limit) {
      page = 1;
    }
    setPagination((prev) => ({
      ...prev,
      currentPage: page,
      pageSize: limit
    }));
    refetch && refetch(page, limit);
  };

  return (
    <Container>
      <StyledTableHeader justify={'space-between'}>
        <HeaderText size={1} scale={'xs'}>
          Users
        </HeaderText>
        <StyledButton color="primaryBlue" onClick={onOpenInviteUserWindow}>
          Invite new user
        </StyledButton>
      </StyledTableHeader>
      <PageTile>
        <Table
          loading={loading}
          data={userData}
          pageCount={pageCount}
          error={error}
          pageSize={pagination.pageSize}
          currentPage={pagination.currentPage}
          handlePageActions={handlePageActions}
          tableRowType={TableRowType.TextSingleLine}
          renderDataHeader={() => (
            <TableRow>
              <BaseTableHeader fixedWidthRem={30}>User Name</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={40}>User Email</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={5}>Status</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={10} align="right">
                Action
              </BaseTableHeader>
            </TableRow>
          )}
          renderPaginatedData={(invitedUser) => {
            return invitedUser.map((user) => (
              <UserListItem key={user.id} user={user} onOpenEditWindow={handleOpenEditUserWindow} />
            ));
          }}
        />
      </PageTile>
    </Container>
  );
};
