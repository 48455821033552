import React, { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Badge, Button, FlexRow, PageTile, SubtitleText, SvgIcon } from '@make-software/cspr-ui';
import styled from 'styled-components';
import { ClickSubscription } from './click-subscription/click-subscription';
import { CloudSubscription } from './cloud-subscription/cloud-subscription';
import {
  CurrentClickTierType,
  CurrentCloudTierType,
  ProductType,
  WithSubscriptionProps
} from 'app/build-api/subscription/subscription';
import { WithSubscriptions } from 'app/components/common/hoc/withSubscriptions';
import { BADGE_COLOR } from 'app/constants';
import SuccessEntityModal from 'app/components/common/success-entity-modal/success-entity-modal';
import CreatedSuccessIcon from 'assets/logos/success-entity.svg';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import NotFoundIcon from 'assets/logos/no-found.svg';
import { UnexpectedErrorWrapper } from 'app/components/home/components/create-modal-styles';
import { ErrorResult } from 'app/build-api/types';

const SubscriptionContainer = styled.div(({ theme }) =>
  theme.withMedia({
    margin: '60px 0'
  })
);

const SubscriptionContent = styled(PageTile)(({ theme }) =>
  theme.withMedia({
    padding: '32px 40px',
    marginTop: '24px'
  })
);

const StyledTab = styled(Button)(({ theme }) =>
  theme.withMedia({
    borderRadius: '0px',
    height: '32px',
    fontSize: ['11px', '14px', '14px'],
    width: '160px'
  })
);

const BadgeWrapper = styled.div(({ theme }) =>
  theme.withMedia({
    marginLeft: '8px'
  })
);

const StyledBadgeTitle = styled.div(({ theme }) =>
  theme.withMedia({
    textTransform: 'uppercase'
  })
);

const STRIPE_PARAM = 'event';
const TABS_PARAM = 'activeTab';

export const BaseSubscriptions = ({ tiersDetails }: WithSubscriptionProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [unexpectedError, setUnexpectedError] = useState<ErrorResult | null>(null);

  const fromClickContainer = location?.state?.activeTab === ProductType.Click;
  const [clickTab, setClickTab] = useState<boolean>(
    searchParams.get(TABS_PARAM)
      ? searchParams.get(TABS_PARAM) === ProductType.Click
      : fromClickContainer
  );
  const [cloudTab, setCloudTab] = useState<boolean>(
    searchParams.get(TABS_PARAM)
      ? searchParams.get(TABS_PARAM) === ProductType.Cloud
      : !fromClickContainer
  );

  const isPlanUpgradedSuccessfully = searchParams.get(STRIPE_PARAM) === 'success';
  const { clickTier, cloudTier } = tiersDetails;

  const handleClickTab = () => {
    setClickTab(true);
    setCloudTab(false);

    searchParams.delete(TABS_PARAM);
    setSearchParams(searchParams);
  };
  const handleCloudTab = () => {
    setClickTab(false);
    setCloudTab(true);

    searchParams.delete(TABS_PARAM);
    setSearchParams(searchParams);
  };

  const handleCloseSuccessWindow = () => {
    searchParams.delete(STRIPE_PARAM);
    setSearchParams(searchParams);
  };

  const handleUnexpectedError = (error: ErrorResult | null) => {
    setUnexpectedError(error);
  };

  return (
    <SubscriptionContainer>
      <SubtitleText size={1} scale={'lg'}>
        Subscriptions
      </SubtitleText>
      {unexpectedError ? (
        <UnexpectedErrorWrapper justify={'center'} align={'center'}>
          <ErrorWindow
            title={unexpectedError.code || ''}
            information={unexpectedError.message}
            icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
          />
        </UnexpectedErrorWrapper>
      ) : (
        <SubscriptionContent>
          <FlexRow direction={'column'} gap={24}>
            <FlexRow justify="center">
              <StyledTab
                color={clickTab ? 'primaryBlue' : 'secondaryBlue'}
                onClick={handleClickTab}
              >
                CSPR.click
                {clickTier.currentType === CurrentClickTierType.pro && (
                  <BadgeWrapper>
                    <Badge
                      label={<StyledBadgeTitle>{clickTier.currentType}</StyledBadgeTitle>}
                      variation={BADGE_COLOR}
                    />
                  </BadgeWrapper>
                )}
              </StyledTab>
              <StyledTab
                color={cloudTab ? 'primaryBlue' : 'secondaryBlue'}
                onClick={handleCloudTab}
              >
                CSPR.cloud
                {cloudTier.currentType === CurrentCloudTierType.pro && (
                  <BadgeWrapper>
                    <Badge
                      label={<StyledBadgeTitle>{cloudTier.currentType}</StyledBadgeTitle>}
                      variation={BADGE_COLOR}
                    />
                  </BadgeWrapper>
                )}
              </StyledTab>
            </FlexRow>
            {clickTab && (
              <ClickSubscription tiersDetails={tiersDetails} onError={handleUnexpectedError} />
            )}
            {cloudTab && (
              <CloudSubscription tiersDetails={tiersDetails} onError={handleUnexpectedError} />
            )}
          </FlexRow>

          {isPlanUpgradedSuccessfully && (
            <SuccessEntityModal
              isOpen={true}
              icon={<SvgIcon src={CreatedSuccessIcon} height={120} width={212} />}
              title={'Subscription Updated'}
              information={'Your subscription has been changed as requested.'}
              onDismiss={handleCloseSuccessWindow}
            />
          )}
        </SubscriptionContent>
      )}
    </SubscriptionContainer>
  );
};
const Subscriptions = WithSubscriptions(BaseSubscriptions);
export default Subscriptions;
