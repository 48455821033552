import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import {
  CONFIRM_EMAIL_PATH,
  FORGOT_PASSWORD_PATH,
  HOME_PATH,
  PRIVACY_POLICY,
  RESET_PASSWORD_PATH,
  SEND_CONFIRM_EMAIL_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  STATISTICS_PATH,
  SUBSCRIPTIONS_PATH,
  TOS
} from './paths';
import { ProtectedRoutes } from 'app/components/common/protected-routes/protected-routes';
import Home from 'app/components/home/home';
import { SignIn } from 'app/components/sing-in/sign-in';
import { SignUp } from 'app/components/sign-up/sign-up';
import { SendConfirmationEmail } from 'app/components/confirm-email/send-confirmation-email';
import { VerifyEmail } from 'app/components/confirm-email/verifyEmail';
import { ForgotPassword } from 'app/components/forgot-password/forgot-password';
import { ResetPassword } from 'app/components/reset-password/reset-password';
import { Tos } from 'app/components/privacy-policy/tos';
import { OpenRoutes } from 'app/components/common/open-routes/open-routes';
import { PrivacyPolicy } from 'app/components/privacy-policy/privacy-policy';
import { NotFound } from 'app/components/common/not-found/not-found';
import { SubscriptionContainer } from 'app/components/subscriptions/subscription-container';
import { StatisticsContainer } from 'app/components/statistics/statistics-container';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/** Non Authenticated Routes **/}
      <Route element={<OpenRoutes />}>
        <Route path={SIGN_UP_PATH} element={<SignUp />} />
        <Route path={SIGN_IN_PATH} element={<SignIn />} />
        <Route path={SEND_CONFIRM_EMAIL_PATH} element={<SendConfirmationEmail />} />
        <Route path={CONFIRM_EMAIL_PATH} element={<VerifyEmail />} />
        <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPassword />} />
        <Route path={RESET_PASSWORD_PATH} element={<ResetPassword />} />
      </Route>

      {/** Authenticated Routes **/}
      <Route element={<ProtectedRoutes />}>
        <Route path={HOME_PATH} element={<Home />} />
        <Route path={SUBSCRIPTIONS_PATH} element={<SubscriptionContainer />} />
        <Route path={STATISTICS_PATH} element={<StatisticsContainer />} />
      </Route>

      {/** Public Routes **/}
      <Route path={TOS} element={<Tos />} />
      <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path="*" element={<NotFound />} />
    </>
  )
);

export default router;
