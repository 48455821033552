import { BodyText, Button, FlexColumn, FlexRow, HeaderText } from '@make-software/cspr-ui';
import styled from 'styled-components';
import React from 'react';

interface PreloadTableWindowProps {
  icon: React.ReactElement;
  title: string;
  windowTitle: string;
  subtitle: string;
  onSubmitTitle: string;
  onSubmit: () => void;
}

const NoKeysRow = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginTop: '48px'
  })
);

const NoKeysRowContentWrapper = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    width: ['90%', '70%', '100%']
  })
);

const KeysRowTitle = styled(HeaderText)(({ theme }) =>
  theme.withMedia({
    display: 'block',
    marginBottom: '24px',
    fontSize: ['20px', '24px', '24px']
  })
);

const StyledContainer = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    width: '100%',
    backgroundColor: theme.styleguideColors.backgroundPrimary,
    padding: '60px 0',
    justifyContent: 'center',
    alignItems: 'center'
  })
);

const StyledTextContent = styled.div(({ theme }) =>
  theme.withMedia({
    display: 'flex',
    padding: ['0 31px', 'unset', 'unset']
  })
);

const StyledTitle = styled(HeaderText)(({ theme }) =>
  theme.withMedia({
    color: theme.styleguideColors.contentPrimary,
    margin: '32px 0 16px 0',
    fontSize: ['20px', '24px', '24px']
  })
);
const StyledSubTitle = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    color: theme.styleguideColors.contentSecondary,
    marginBottom: '32px'
  })
);

const StyledButton = styled(Button)(({ theme }) =>
  theme.withMedia({
    width: ['50%', '30%', '20%']
  })
);
export const PreloadTableWindow = ({
  icon,
  windowTitle,
  onSubmitTitle,
  title,
  onSubmit,
  subtitle
}: PreloadTableWindowProps) => {
  const handleSubmit = () => {
    onSubmit && onSubmit();
  };
  return (
    <NoKeysRow justify="center">
      <NoKeysRowContentWrapper>
        <KeysRowTitle size={1} scale={'xs'}>
          {windowTitle}
        </KeysRowTitle>
        <StyledContainer>
          {icon}
          <StyledTextContent>
            <StyledTitle size={1} scale={'xs'}>
              {title}
            </StyledTitle>
          </StyledTextContent>
          <StyledTextContent>
            <StyledSubTitle size={3}>{subtitle}</StyledSubTitle>
          </StyledTextContent>
          <StyledButton color={'primaryBlue'} onClick={handleSubmit}>
            {onSubmitTitle}
          </StyledButton>
        </StyledContainer>
      </NoKeysRowContentWrapper>
    </NoKeysRow>
  );
};
