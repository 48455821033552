import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  FlexRow,
  HeaderText,
  PageTile,
  TableRow,
  TableRowType
} from '@make-software/cspr-ui';
import Table from 'app/components/table/table';
import BaseTableHeader from 'app/components/common/base-table/base-table-header';
import { ErrorResult, Pagination } from 'app/build-api/types';
import { ClickKeyResponseType, CurrentClickTierType, ProductType } from 'app/build-api';
import { ClickKeyListItem } from './click-key-list-item';
import { TierTypeMark } from 'app/components/subscriptions/components/tier-type-mark';

interface ClickKeyListProps {
  loading: boolean;
  pageCount: number;
  error?: ErrorResult | null;
  clickKeys: ClickKeyResponseType[] | null;
  currentTire: string;
  refetch?: (page: number, limit: number) => void;
  onOpenCreateKeyWindow: () => void;
  onOpenEditKeyWindow: (key: ClickKeyResponseType) => void;
}

const StyledTableHeader = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginBottom: '24px'
  })
);

const StyledButton = styled(Button)(({ theme }) =>
  theme.withMedia({
    width: ['123px', '130px', '176px']
  })
);

const Container = styled.div(({ theme }) =>
  theme.withMedia({
    width: ['90%', '70%', '100%']
  })
);

export const ClickKeyList = ({
  clickKeys,
  loading,
  error,
  refetch,
  currentTire,
  onOpenCreateKeyWindow,
  onOpenEditKeyWindow,
  pageCount
}: ClickKeyListProps) => {
  const [pagination, setPagination] = useState<Pagination>({ currentPage: 1, pageSize: 5 });
  const [keys, setKeys] = useState<ClickKeyResponseType[] | null>(null);

  useEffect(() => {
    clickKeys && setKeys([...clickKeys]);
  }, [clickKeys]);

  const handleOpenEditKeyWindow = (key: ClickKeyResponseType) => {
    onOpenEditKeyWindow(key);
  };

  const handlePageActions = (page: number, limit: number) => {
    if (pagination.pageSize != limit) {
      page = 1;
    }
    setPagination((prev) => ({
      ...prev,
      currentPage: page,
      pageSize: limit
    }));
    refetch && refetch(page, limit);
  };

  return (
    <Container>
      <StyledTableHeader justify={'space-between'}>
        <HeaderText size={1} scale={'xs'}>
          CSPR.click keys
          {currentTire === CurrentClickTierType.pro && (
            <TierTypeMark currentTire={currentTire} productType={ProductType.Click} />
          )}
        </HeaderText>
        <StyledButton color="primaryBlue" onClick={onOpenCreateKeyWindow}>
          Create key
        </StyledButton>
      </StyledTableHeader>
      {currentTire === CurrentClickTierType.free && (
        <TierTypeMark currentTire={currentTire} productType={ProductType.Click} />
      )}
      <PageTile>
        <Table
          loading={loading}
          data={keys}
          pageCount={pageCount}
          error={error}
          pageSize={pagination.pageSize}
          currentPage={pagination.currentPage}
          handlePageActions={handlePageActions}
          tableRowType={TableRowType.TextSingleLine}
          renderDataHeader={() => (
            <TableRow>
              <BaseTableHeader fixedWidthRem={10}>Name</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={15}>AppID</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={20}>Key</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={10}>Cloud Key</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={10}>Domain</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={10}>Network</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={5}>Status</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={5} align="right">
                Action
              </BaseTableHeader>
            </TableRow>
          )}
          renderPaginatedData={(clickKeys) => {
            return clickKeys.map((clickKey) => (
              <ClickKeyListItem
                key={clickKey.id}
                clickKey={clickKey}
                onOpenEditWindow={handleOpenEditKeyWindow}
              />
            ));
          }}
        />
      </PageTile>
    </Container>
  );
};
