import React from 'react';
import styled from 'styled-components';
import { BodyText, SvgIcon, TableData, TableRow } from '@make-software/cspr-ui';
import { InviteUserResponseType, UserRole, UserStatus } from 'app/build-api';
import {
  TableValidationErrorWrapper,
  TableValidationText
} from 'app/components/home/components/create-modal-styles';
import { ExpectedErrors } from 'app/components/home/components/users/user-container';
import EditIcon from 'assets/icons/ic-edit.svg';

interface UserListItemProps {
  user: InviteUserResponseType;
  onOpenEditWindow: (user: InviteUserResponseType) => void;
  expectedError?: ExpectedErrors | undefined;
}

const StyledEditSvg = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    cursor: 'pointer'
  })
);

const StyledRoleWrapper = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    marginLeft: '8px'
  })
);

const StatusWrapper = styled(BodyText)<{ isActive?: boolean }>(({ theme, isActive }) =>
  theme.withMedia({
    textTransform: 'uppercase',
    color: isActive ? theme.styleguideColors.contentGreen : theme.styleguideColors.contentSecondary
  })
);

export const UserListItem = ({ user, onOpenEditWindow, expectedError }: UserListItemProps) => {
  const { id, email, full_name, is_active, status_description, role } = user;

  const openEditWindow = () => {
    onOpenEditWindow(user);
  };

  const showAppropriateError = () => {
    let specificError = '';
    expectedError &&
      Object.values(expectedError).some((item) => {
        if (Boolean(item)) {
          specificError = item;
        }
      });

    return (
      specificError && (
        <TableValidationErrorWrapper justify={'right'} top={'40px'}>
          <TableValidationText size={3}>{specificError}</TableValidationText>
        </TableValidationErrorWrapper>
      )
    );
  };

  return (
    <TableRow key={id}>
      <TableData>
        <BodyText size={3} monotype>
          {full_name}
          {role === UserRole.Owner && (
            <StyledRoleWrapper size={3} variation={'gray'}>
              Owner
            </StyledRoleWrapper>
          )}
        </BodyText>
      </TableData>
      <TableData>
        <BodyText size={3} monotype>
          {email}
        </BodyText>
      </TableData>
      <TableData>
        <StatusWrapper
          isActive={is_active && status_description === UserStatus.ACTIVE}
          size={3}
          monotype
        >
          {status_description}
        </StatusWrapper>
      </TableData>
      <TableData align="right">
        <BodyText size={3} monotype>
          <StyledEditSvg src={EditIcon} onClick={openEditWindow} />
          {showAppropriateError()}
        </BodyText>
      </TableData>
    </TableRow>
  );
};
