import { ClickKeyResponseType } from 'app/build-api';
import { formatKeysHash } from 'app/components/utils/measures-format';
import { HashLength } from '@make-software/cspr-ui';

export const formatProvidersDataToMultiSelect = (
  data: {
    label: string;
    value: string;
  }[]
) =>
  data.map((item) => ({
    ...item,
    id: item.value,
    chipLabel: item.label
  }));

export const formatAppDataToMultiSelect = (data: ClickKeyResponseType[]) =>
  data.map((item) => {
    const chipLabel = `${formatKeysHash(item.app_id, HashLength.TINY)}`;
    const label = `${item.name} ${item.app_id}`;
    return {
      id: item.app_id,
      label,
      value: item.app_id,
      chipLabel,
      chipName: item.name
    };
  });
