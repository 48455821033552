import styled from 'styled-components';
import React from 'react';
import { BodyText, Button, FlexColumn, FlexRow, HeaderText } from '@make-software/cspr-ui';
import { ROW_GAP_LG } from 'app/constants';
import { InformationWindowProps } from '../types';

const ModalContainer = styled(FlexColumn)<{ withModal?: boolean }>(({ theme, withModal }) =>
  theme.withMedia({
    width: withModal ? 'unset' : ['350px', '440px', '576px'],
    background: theme.styleguideColors.backgroundPrimary,
    borderColor: theme.styleguideColors.backgroundPrimary
  })
);

const ModalContentWrapper = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    padding: '26px 48px 48px 48px'
  })
);

const StyledHeaderText = styled(HeaderText)(({ theme }) =>
  theme.withMedia({
    wordBreak: 'break-word'
  })
);

const ImageWrapper = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginBottom: '10px'
  })
);

const StyledInformationText = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    marginTop: '8px',
    color: theme.styleguideColors.contentSecondary
  })
);

const ButtonsContainer = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginTop: '40px',
    flexDirection: ['column', 'row', 'row']
  })
);
export const InformationWindow = ({
  confirmLabel,
  confirmColor,
  onConfirm,
  dismissLabel,
  onDismiss,
  bodyImg,
  information,
  disableConfirm,
  disableDismiss,
  title,
  withModal
}: InformationWindowProps) => {
  const handleOnSubmit = () => {
    onConfirm && onConfirm();
  };

  const handleOnDismiss = () => {
    onDismiss && onDismiss();
  };

  return (
    <ModalContainer withModal={withModal}>
      <ModalContentWrapper>
        <ImageWrapper>{bodyImg && bodyImg}</ImageWrapper>
        <StyledHeaderText size={3} scale="md">
          {title}
        </StyledHeaderText>
        <StyledInformationText size={3} scale="sm">
          {information}
        </StyledInformationText>
        <ButtonsContainer gap={ROW_GAP_LG} justify={'space-between'}>
          {dismissLabel && (
            <Button color={'secondaryBlue'} onClick={handleOnDismiss} disabled={disableDismiss}>
              {dismissLabel}
            </Button>
          )}
          {confirmLabel && (
            <Button
              color={confirmColor === 'red' ? 'primaryRed' : 'primaryBlue'}
              onClick={handleOnSubmit}
              disabled={disableConfirm}
            >
              {confirmLabel}
            </Button>
          )}
        </ButtonsContainer>
      </ModalContentWrapper>
    </ModalContainer>
  );
};
