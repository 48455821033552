import { useState } from 'react';
import { buildApi, GetResponseType } from 'app/build-api/types';
import { useOnMountUnsafe } from 'hooks/useOnMountUnsafe';
import { GetSubscriptionResponseType } from '../subscription/subscription';

export const useGetSubscriptions = (page?: number, pageSize?: number) => {
  const [getSubscriptionResponse, setGetSubscriptionResponse] = useState<
    GetResponseType<GetSubscriptionResponseType>
  >({
    data: null,
    loading: false,
    error: null
  });

  const fetchSubscriptions = async (page?: number, pageSize?: number) => {
    const response = await buildApi.getSubscriptions(page, pageSize);
    setGetSubscriptionResponse(response);
  };

  useOnMountUnsafe(() => fetchSubscriptions(page, pageSize));

  const refetch = (page?: number, pageSize?: number) => fetchSubscriptions(page, pageSize);

  return {
    data: getSubscriptionResponse.data,
    httpCode: getSubscriptionResponse.httpCode,
    error: getSubscriptionResponse.error,
    loading: getSubscriptionResponse.loading,
    refetch: refetch
  };
};
