import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FlexRow } from '@make-software/cspr-ui';
import { Sidebar } from './components/sidebar';
import { BLANK_LAYOUT } from 'app/constants';

const Container = styled.div<{ type?: string }>(({ theme, type }) =>
  theme.withMedia({
    minWidth: theme.minWidth,
    display: 'flex',
    justifyContent: type === BLANK_LAYOUT ? 'center' : ['center', 'unset', 'unset'],
    alignItems: type === BLANK_LAYOUT ? 'center' : 'unset',
    color: theme.styleguideColors.contentPrimary,
    backgroundColor: theme.styleguideColors.backgroundSecondary,
    minHeight: '100vh'
  })
);

const ContentContainer = styled(FlexRow)<{ type?: string }>(({ theme, type }) =>
  theme.withMedia({
    width: ['100%', '66%', '66%'],
    padding: ['10px 0', '10px 0', '10px 0'],
    height: 'auto',
    flexDirection: type === BLANK_LAYOUT ? 'column' : 'unset',
    gap: type === BLANK_LAYOUT ? [30, 40, 60] : 'unset'
  })
);

export interface UnauthorizedLayoutProps {
  children?: React.ReactNode;
  title?: string;
  type?: string;
}

export const UnauthorizedLayout = ({ title, children, type }: UnauthorizedLayoutProps) => {
  useEffect(() => {
    document.title = `${title}`;
  }, [title]);

  return (
    <Container type={type}>
      <Sidebar hide={type === BLANK_LAYOUT} />
      <ContentContainer justify={'center'} align={'center'} type={type}>
        {children}
      </ContentContainer>
    </Container>
  );
};

export default UnauthorizedLayout;
