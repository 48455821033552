import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  FlexRow,
  HeaderText,
  PageTile,
  TableRow,
  TableRowType
} from '@make-software/cspr-ui';
import Table from 'app/components/table/table';
import BaseTableHeader from 'app/components/common/base-table/base-table-header';
import { ErrorResult, Pagination } from 'app/build-api/types';
import { CloudKeyResponseType, CurrentCloudTierType, ProductType } from 'app/build-api';
import { CloudKeyListItem } from './cloud-key-list-item';
import { TierTypeMark } from 'app/components/subscriptions/components/tier-type-mark';

interface CloudKeyListProps {
  loading: boolean;
  error?: ErrorResult | null;
  pageCount: number;
  cloudKeys: CloudKeyResponseType[] | null;
  refetch?: (page: number, limit: number) => void;
  currentTire: string;
  onOpenCreateKeyWindow: () => void;
  onOpenEditKeyWindow: (key: CloudKeyResponseType) => void;
}

const StyledTableHeader = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginBottom: '24px'
  })
);

const StyledButton = styled(Button)(({ theme }) =>
  theme.withMedia({
    width: ['123px', '130px', '176px']
  })
);

const Container = styled.div(({ theme }) =>
  theme.withMedia({
    width: ['90%', '70%', '100%']
  })
);

export const CloudKeyList = ({
  cloudKeys,
  loading,
  error,
  refetch,
  currentTire,
  onOpenCreateKeyWindow,
  onOpenEditKeyWindow,
  pageCount
}: CloudKeyListProps) => {
  const [pagination, setPagination] = useState<Pagination>({ currentPage: 1, pageSize: 5 });
  const [keys, setKeys] = useState<CloudKeyResponseType[] | null>(null);

  useEffect(() => {
    cloudKeys && setKeys([...cloudKeys]);
  }, [cloudKeys]);

  const handleOpenEditKeyWindow = (key: CloudKeyResponseType) => {
    onOpenEditKeyWindow(key);
  };

  const handlePageActions = (page: number, limit: number) => {
    if (pagination.pageSize != limit) {
      page = 1;
    }
    setPagination((prev) => ({
      ...prev,
      currentPage: page,
      pageSize: limit
    }));
    refetch && refetch(page, limit);
  };

  return (
    <Container>
      <StyledTableHeader justify={'space-between'}>
        <HeaderText size={1} scale={'xs'}>
          CSPR.cloud keys
          {currentTire === CurrentCloudTierType.pro && (
            <TierTypeMark currentTire={currentTire} productType={ProductType.Cloud} />
          )}
        </HeaderText>
        <StyledButton color="primaryBlue" onClick={onOpenCreateKeyWindow}>
          Create key
        </StyledButton>
      </StyledTableHeader>
      {currentTire === CurrentCloudTierType.free && (
        <TierTypeMark currentTire={currentTire} productType={ProductType.Cloud} />
      )}
      <PageTile>
        <Table
          loading={loading}
          data={keys}
          pageCount={pageCount}
          error={error}
          tableRowType={TableRowType.TextSingleLine}
          pageSize={pagination.pageSize}
          currentPage={pagination.currentPage}
          handlePageActions={handlePageActions}
          renderDataHeader={() => (
            <TableRow>
              <BaseTableHeader fixedWidthRem={20}>Name</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={40}>Key</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={5}>Status</BaseTableHeader>
              <BaseTableHeader fixedWidthRem={10} align="right">
                Action
              </BaseTableHeader>
            </TableRow>
          )}
          renderPaginatedData={(cloudKeys) => {
            return cloudKeys.map((cloudKey) => (
              <CloudKeyListItem
                key={cloudKey.id}
                cloudKey={cloudKey}
                onOpenEditWindow={handleOpenEditKeyWindow}
              />
            ));
          }}
        />
      </PageTile>
    </Container>
  );
};
