import UnauthorizedLayout from 'app/layouts/unauthorized-layout';
import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import EmailConfirmationIcon from 'assets/logos/email-confirmation.svg';
import CSPRBuildLogoOriginal from 'assets/logos/cspr-build-logo-original.svg';
import { useNavigate } from 'react-router-dom';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import { BodyText, FlexRow, SvgIcon } from '@make-software/cspr-ui';
import NotFoundIcon from 'assets/logos/no-found.svg';
import { InformationWindow } from 'app/components/common/information-window/information-window';
import { SIGN_IN_PATH } from 'app/route/paths';
import {
  BLANK_LAYOUT,
  GENERAL_ERROR_DESCRIPTION,
  GENERAL_ERROR_MESSAGE,
  statusCode
} from 'app/constants';
import { ForgotPasswordWindow } from './forgot-password-window';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<ErrorResult | null>(null);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userRecaptchaToken, setUserRecaptchaToken] = useState<string>('');
  const [recaptchaError, setRecaptchaError] = useState<string>('');
  const [emailNotFoundError, setEmailNotFoundError] = useState<string>('');
  const [responseCode, setResponseCode] = useState<number>();

  const loginRedirect = () => navigate(SIGN_IN_PATH);
  const resendEmail = async () => {
    await handleSubmit(userEmail, userRecaptchaToken);
  };

  const handleSubmit = async (email: string, reCaptchaToken: string) => {
    setUserEmail(email);
    setUserRecaptchaToken(reCaptchaToken);

    const response = await buildApi.forgotPassword(email, reCaptchaToken);

    if (response.error) {
      const err = {
        code: GENERAL_ERROR_MESSAGE,
        message: GENERAL_ERROR_DESCRIPTION
      };
      switch (response.httpCode) {
        case statusCode.invalid_input_error:
        case statusCode.validation_error:
          setError(response.error);
          Sentry.captureException(response.error);
          break;
        case statusCode.authentication_error:
          setRecaptchaError(response.error?.message);
          break;
        case statusCode.not_found_error:
          setEmailNotFoundError(response.error?.message);
          break;
        case statusCode.unexpected_error:
          setError(err);
          break;
        default:
          setError(err);
          break;
      }
    }

    if (response.httpCode === statusCode.no_content) {
      setResponseCode(statusCode.no_content);
    }
  };

  if (error) {
    return (
      <UnauthorizedLayout title="Forgot password" type={BLANK_LAYOUT}>
        <ErrorWindow
          title={error.code}
          information={error.message}
          icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
        />
      </UnauthorizedLayout>
    );
  }

  return (
    <UnauthorizedLayout title="Forgot password" type={BLANK_LAYOUT}>
      <FlexRow>
        <SvgIcon src={CSPRBuildLogoOriginal} width={249} height={44} />
      </FlexRow>

      {responseCode ? (
        <InformationWindow
          bodyImg={<SvgIcon src={EmailConfirmationIcon} width={219} height={125} />}
          title="Go check your mailbox"
          information={
            <>
              We’ve just sent you an email with password recovery link to your address
              <BodyText size={1} variation="black">
                {` `}
                {userEmail}
              </BodyText>
            </>
          }
          onDismiss={loginRedirect}
          onConfirm={resendEmail}
          dismissLabel="Back to login"
        />
      ) : (
        <ForgotPasswordWindow
          title="Password recovery"
          information="Enter your email and we’ll send you a link to create a new password."
          confirmColor="red"
          onConfirm={handleSubmit}
          confirmLabel="Send me a password recovery email"
          reCaptchaError={recaptchaError}
          emailNotFoundError={emailNotFoundError}
        />
      )}
    </UnauthorizedLayout>
  );
};
