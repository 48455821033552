import React, { useState } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { FlexColumn, SubtitleText } from '@make-software/cspr-ui';
import { ProductType } from 'app/build-api';
import { statisticsTabs, TabData } from './common/active-tabs/active-tabs';
import { ClickStatisticsContainer } from './click/container';
import { CloudStatisticsContainer } from './cloud/container';

const StatisticsContainer = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: theme.maxWidth,
    padding: ['20px 16px', '20px 16px', '60px 20px', '60px 0px'],
    '*': {
      boxSizing: 'border-box'
    }
  })
);

const TABS_PARAM = 'activeTab';

const Statistics = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getInitialTabState = (): TabData => {
    return (
      statisticsTabs.find((tab) => tab.key === searchParams.get(TABS_PARAM)) || statisticsTabs[1]
    );
  };

  const [activeTab, setActiveTab] = useState<TabData>(getInitialTabState);

  const handleSwitchTab = (activeTab: TabData) => {
    setSearchParams({ [TABS_PARAM]: activeTab.key });
    setActiveTab(activeTab);
  };

  return (
    <StatisticsContainer itemsSpacing={24}>
      <SubtitleText size={1} scale={'lg'}>
        Statistics
      </SubtitleText>
      {activeTab.key === ProductType.Click && (
        <ClickStatisticsContainer activeTab={activeTab} handleChangeTab={handleSwitchTab} />
      )}
      {activeTab.key === ProductType.Cloud && (
        <CloudStatisticsContainer activeTab={activeTab} handleChangeTab={handleSwitchTab} />
      )}
    </StatisticsContainer>
  );
};

export default Statistics;
