import React from 'react';
import styled, { useTheme } from 'styled-components';
import { SkeletonLoader } from 'app/components/common/skeleton-loader/skeleton-loader';

import {
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  AreaChart,
  Label,
  ResponsiveContainer
} from 'recharts';

import {
  FlexColumn,
  FlexRow,
  formatDateWithWeekday,
  useMatchMedia,
  CaptionText,
  BodyText
} from '@make-software/cspr-ui';
import { AxisDomain } from 'recharts/types/util/types';

const ChartContainer = styled.div<{ multipleGraphChart?: boolean }>(
  ({ theme, multipleGraphChart }) =>
    theme.withMedia({
      display: 'flex',
      width: '100%',
      padding: multipleGraphChart ? ['32px 0 ', '40px 0', '40px 0'] : '40px 0 0 0',
      fontSize: '10px'
    })
);

const NoDataContainer = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    width: '100%',
    height: '400px',
    justifyContent: 'center',
    alignItems: 'center'
  })
);

export const ChartTooltip = styled.div(({ theme }) =>
  theme.withMedia({
    background: theme.styleguideColors.backgroundPrimary,
    boxShadow: theme.boxShadow.tooltip,
    borderRadius: theme.borderRadius.base,
    padding: '8px 16px'
  })
);

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    [key: string]: string | number;
    name: string;
  }>;
  label?: string;
  format: (value: any) => string;
  tooltipLabelsMap: Record<string, string>;
}

const CustomTooltip = (props: CustomTooltipProps) => {
  const { active, payload, label, format, tooltipLabelsMap } = props;
  const convertGraphKeyToItemLabel = (key: string) => tooltipLabelsMap[key];

  if (active && payload && payload.length) {
    return (
      <ChartTooltip>
        <CaptionText size={2} variation="darkGray">
          {label && formatDateWithWeekday(label)}
        </CaptionText>
        <br />
        <FlexColumn itemsSpacing={4}>
          {payload.map((item) => (
            <FlexRow key={item.name} align="baseline" justify="space-between" itemsSpacing={10}>
              {payload.length > 1 && (
                <BodyText size={3} scale={'xs'} capitalizeFirstLetter>
                  {convertGraphKeyToItemLabel(item.name)}:
                </BodyText>
              )}
              <BodyText size={1} scale={'lg'} lineHeight={'sm'}>
                {format(item.value)}
              </BodyText>
            </FlexRow>
          ))}
        </FlexColumn>
      </ChartTooltip>
    );
  }

  return null;
};

export enum YAxisRangeStartType {
  Zero = 'Zero',
  MinValue = 'MinValue'
}

const yAxisRangeDataType = {
  [YAxisRangeStartType.Zero]: [0, 'dataMax'],
  [YAxisRangeStartType.MinValue]: ['dataMin', 'dataMax']
};

export interface GraphDataItemType {
  date: string;
  [key: string]: string | number;
}

export interface GraphAxisItemType {
  [key: string]: {
    [id: string]: string | number;
  };
}

export type GraphComponentDataFormatType = {
  graphKey: string;
  xAxisValueKey: string;
  checkboxLabel?: string;
  initiallySelected?: boolean;
  data: GraphDataItemType[] | null;
  themeColor?: string;
};

interface GraphComponentProps {
  graphData: GraphComponentDataFormatType[];
  loading: boolean;
  error?: React.ReactNode | string;
  axisLine?: boolean;
  yAxisLabelText?: string;
  yAxisTickFormatter: (value: any) => string;
  xAxisTickFormatter: (value: any) => string;
  tooltipFormatter?: (value: any) => string;
  yAxisValueKey: string;
  multipleGraphChart: boolean;
  yAxisRangeStart?: YAxisRangeStartType;
}

export const GraphComponent = ({
  graphData,
  loading,
  error,
  yAxisLabelText,
  yAxisValueKey,
  yAxisTickFormatter,
  tooltipFormatter,
  xAxisTickFormatter,
  axisLine = false,
  multipleGraphChart,
  yAxisRangeStart = YAxisRangeStartType.Zero
}: GraphComponentProps) => {
  const theme = useTheme();
  const responsiveSkeletonWidth = useMatchMedia(['330', '664', '872', '1136'], [theme]);

  const graphAxisData = graphData.reduce((acc, value) => {
    if (!value.data) {
      return acc;
    }
    value.data.forEach((dataItem: GraphDataItemType) => {
      // derive values for y-axis
      if (!acc[dataItem[yAxisValueKey]]) {
        acc[dataItem[yAxisValueKey]] = { name: dataItem[yAxisValueKey] };
      }

      // derive values for x-axis
      acc[dataItem[yAxisValueKey]][value.graphKey] = dataItem[value.xAxisValueKey];
    });
    return acc;
  }, {} as GraphAxisItemType);

  const data = Object.values(graphAxisData);

  const compareByHighestValueForKey = (
    a: GraphComponentDataFormatType,
    b: GraphComponentDataFormatType
  ) => {
    const getArrayOfValuesByKeys = (obj: GraphComponentDataFormatType) =>
      (obj.data ?? []).map((item) =>
        typeof item[obj.xAxisValueKey] === 'string'
          ? parseInt(item[obj.xAxisValueKey] as string)
          : item[obj.xAxisValueKey]
      );

    const arrayA = getArrayOfValuesByKeys(a) as number[];
    const arrayB = getArrayOfValuesByKeys(b) as number[];

    const highestValueA = Math.max(...arrayA);
    const highestValueB = Math.max(...arrayB);
    return highestValueB - highestValueA;
  };

  //sorting the data in descending order to render areas from the area with the highest value to the area with the lowest value to prevent cropping of parts of the graphs
  const graphDataDesc = graphData.sort(compareByHighestValueForKey);

  if (!graphData.length && !loading && !error) {
    return (
      <ChartContainer>
        <NoDataContainer>
          <BodyText scale={'md'} size={3} variation="black" noWrap>
            No data
          </BodyText>
        </NoDataContainer>
      </ChartContainer>
    );
  }

  const yAxisStartOptions = yAxisRangeDataType[yAxisRangeStart] as AxisDomain;
  const tooltipLabelsMap = graphDataDesc.reduce(
    (acc: Record<string, string>, obj: GraphComponentDataFormatType) => {
      acc[obj.graphKey] = obj.checkboxLabel || obj.graphKey;
      return acc;
    },
    {}
  );

  return (
    <ChartContainer multipleGraphChart={multipleGraphChart}>
      <ResponsiveContainer width={'100%'} height={400}>
        {loading ? (
          <>
            <SkeletonLoader width={responsiveSkeletonWidth} height={'400'} />
          </>
        ) : error ? (
          <>{error}</>
        ) : (
          <AreaChart data={data} width={500} height={400}>
            <defs>
              {graphDataDesc.map((item) => (
                <linearGradient id={item.graphKey} key={item.graphKey} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={item.themeColor} stopOpacity={0.16} />
                  <stop offset="95%" stopColor={item.themeColor} stopOpacity={0} />
                </linearGradient>
              ))}
            </defs>
            <CartesianGrid vertical={false} stroke={theme.styleguideColors.borderPrimary} />
            <XAxis
              dataKey="name"
              tickFormatter={xAxisTickFormatter}
              stroke={theme.styleguideColors.contentSecondary}
              axisLine={axisLine}
              tickSize={0}
              tickMargin={10}
              minTickGap={69}
              interval="preserveEnd"
            />
            <YAxis
              tickFormatter={yAxisTickFormatter}
              stroke={theme.styleguideColors.contentSecondary}
              axisLine={axisLine}
              tickSize={0}
              tickMargin={12}
              minTickGap={35}
              type="number"
              interval="preserveEnd"
              dataKey={graphData[0].graphKey}
              domain={yAxisStartOptions}
            >
              {yAxisLabelText && (
                <Label
                  offset={0}
                  position="insideLeft"
                  angle={-90}
                  dy={30}
                  style={{
                    fill: theme.styleguideColors.contentSecondary,
                    fontSize: '11px'
                  }}
                >
                  {yAxisLabelText}
                </Label>
              )}
            </YAxis>
            <Tooltip
              content={
                <CustomTooltip
                  format={tooltipFormatter || yAxisTickFormatter}
                  tooltipLabelsMap={tooltipLabelsMap}
                />
              }
            />
            {graphDataDesc.map((item) => (
              <Area
                key={item.graphKey}
                type="monotone"
                dataKey={item.graphKey}
                stroke={item.themeColor}
                fillOpacity={1}
                fill={`url(#${item.graphKey})`}
              />
            ))}
          </AreaChart>
        )}
      </ResponsiveContainer>
    </ChartContainer>
  );
};
