import { CloudFiltersState, CloudRequestCountFilter } from './filter';
import { getSearchParamsFromCloudFilters } from 'app/components/utils/url-utils';
import { decodeJWT } from 'app/components/utils/utils';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import useApi from 'app/build-api/hooks/useApi';
import { GetResponseType } from 'app/build-api/types';
import { CountHistogramResponse } from '../common';
import { getApiQueryParamsFromGraphFilters } from '../url-utils';

export const getCloudLogsRequestCountHistogram = async (
  filters: CloudFiltersState,
  graphFilter: CloudRequestCountFilter
): Promise<GetResponseType<CountHistogramResponse>> => {
  const searchParams = new URLSearchParams();
  const searchParamsWithFilters = getSearchParamsFromCloudFilters(searchParams, filters);
  const searchParamsWithGraphFilters = getApiQueryParamsFromGraphFilters({
    searchParams: searchParamsWithFilters,
    groupBy: graphFilter.groupBy,
    period: graphFilter.period
  });
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));

  return await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/cloud-logs/request-count?${searchParamsWithGraphFilters.toString()}`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
};
