export enum Period {
  Day = 'day',
  Week = 'week',
  Month = 'month'
}

export interface AggregationSum {
  key: string;
  doc_count: number;
}

export interface CountHistogramAggregation {
  date: string;
  doc_count: number;
  aggregation?: AggregationSum[];
}

export interface CountHistogramResponse {
  total: number;
  total_by_group_field?: AggregationSum[];
  histogram: CountHistogramAggregation[];
}

export const DateRangeKey = 'dateRange';

export interface DateRange {
  from: Date;
  to: Date;
}

export interface UniqueAggregationSum {
  key: string;
  doc_count: number;
  unique_count: number;
}

export interface UniqueHistogramAggregation {
  date: string;
  doc_count: number;
  unique_count: number;
  aggregation?: UniqueAggregationSum[];
}

export interface UniqueHistogramResponse {
  total: number;
  total_by_group_field?: UniqueAggregationSum[];
  histogram: UniqueHistogramAggregation[];
}
