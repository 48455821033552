import React from 'react';
import styled from 'styled-components';
import { BodyText, SvgIcon } from '@make-software/cspr-ui';
import { ErrorResult } from 'app/build-api/types';
import SearchIcon from 'assets/icons/ic-search.svg';

const FailedToFetchWrapper = styled('div')(({ theme }) => ({
  height: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledBodyText = styled(BodyText)(({ theme }) => ({
  marginTop: 8,
  color: theme.styleguideColors.contentSecondary
}));

const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
  path: {
    fill: theme.styleguideColors.fillPrimaryRed
  },
  marginBottom: 20
}));

export const FailedToFetch = ({ error }: { error: ErrorResult | null }) => {
  return (
    <FailedToFetchWrapper>
      <StyledSvgIcon src={SearchIcon} size={60} />
      <BodyText scale={'lg'} lineHeight={'sm'} size={1}>
        {error?.code ? error.code : 'Failed to load data'}
      </BodyText>
      <StyledBodyText size={3}>
        {error?.message ? error.message : 'Please try again later'}
      </StyledBodyText>
    </FailedToFetchWrapper>
  );
};

export interface TableErrorProps {
  columnsLength?: number;
  error?: ErrorResult | null;
}

export const TableError = ({ columnsLength, error }: TableErrorProps) => {
  return (
    <tr>
      <td colSpan={columnsLength}>
        <FailedToFetch error={error || null} />
      </td>
    </tr>
  );
};
