import { AccessItem } from 'app/build-api';
import { AUTH_TOKEN, MONTH_NAMES } from 'app/constants';

export const decodeJWT = (token: string | null) => {
  if (!token) return '';
  return decodeURIComponent(
    atob(token.split('.')[1].replace('-', '+').replace('_', '/'))
      .split('')
      .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join('')
  );
};

export const checkGrantedAccess = (item: AccessItem) => {
  const accessList =
    localStorage.getItem(AUTH_TOKEN) &&
    JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN))).access_list
      ? JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN))).access_list
      : [];

  return accessList.includes(item);
};

export const capitalizeFirstLetter = (string: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number
): ((...args: Parameters<T>) => void) => {
  let timeout: ReturnType<typeof setTimeout>;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};
