import { useState } from 'react';
import { buildApi, GetResponseType } from 'app/build-api/types';
import { useOnMountUnsafe } from 'hooks/useOnMountUnsafe';
import { GetClickKeyResponseType } from 'app/build-api/click-key/click-key';

export const useGetClickKeys = (page?: number, pageSize?: number) => {
  const [getClickKeyResponse, setGetClickKeyResponse] = useState<
    GetResponseType<GetClickKeyResponseType>
  >({
    data: null,
    loading: false,
    error: null
  });

  const fetchClickKeys = async (page?: number, pageSize?: number) => {
    const response = await buildApi.getClickKeys(page, pageSize);
    setGetClickKeyResponse(response);
  };

  useOnMountUnsafe(() => fetchClickKeys(page, pageSize));

  const refetch = (page?: number, pageSize?: number) => fetchClickKeys(page, pageSize);

  return {
    data: getClickKeyResponse.data,
    httpCode: getClickKeyResponse.httpCode,
    error: getClickKeyResponse.error,
    loading: getClickKeyResponse.loading,
    refetch: refetch
  };
};
