// Function to format the timestamp
import { HashLength } from '@make-software/cspr-ui';

// Function to format the duration from nanoseconds to a single human-readable format
export const formatDuration = (nanoseconds: number) => {
  let duration = nanoseconds;
  let unit = 'ns';

  if (duration >= 1e3) {
    duration /= 1e3;
    unit = 'µs';
  }
  if (duration >= 1e3) {
    duration /= 1e3;
    unit = 'ms';
  }
  if (duration >= 1e3) {
    duration /= 1e3;
    unit = 's';
  }

  return `${duration.toFixed(2)}${unit}`;
};

// Function to format the size from bytes to a single human-readable format
export const formatSize = (bytes: number) => {
  let size = bytes;
  let unit = 'B';

  if (size >= 1e3) {
    size /= 1e3;
    unit = 'KB';
  }
  if (size >= 1e3) {
    size /= 1e3;
    unit = 'MB';
  }
  if (size >= 1e3) {
    size /= 1e3;
    unit = 'GB';
  }

  return `${parseFloat(size.toFixed(2))}${unit}`;
};

export const formatKeysHash = (hash: string, visibleHashLength: HashLength = HashLength.TINY) => {
  const MIN_TRUNCATE_HASH_LENGTH = HashLength.TINY * 2 + 3;

  const hashLength = hash.length;

  if (visibleHashLength === HashLength.FULL || hashLength <= MIN_TRUNCATE_HASH_LENGTH) {
    return hash;
  }

  const firstPart = hash.substring(0, visibleHashLength);
  const secondPart = hash.substring(hashLength - visibleHashLength);

  const truncatedHash = `${firstPart}...${secondPart}`;

  return truncatedHash;
};
