import styled from 'styled-components';
import React, { useState } from 'react';
import {
  BodyText,
  Button,
  FlexColumn,
  FlexRow,
  HeaderText,
  Input,
  InputValidationType
} from '@make-software/cspr-ui';
import { PASSWORD_LENGTH, ROW_GAP_MD } from 'app/constants';
import { SetPasswordWindowProps } from './types';
import { invalidPasswordMessage } from 'app/validators';
import { PasswordHints } from 'app/components/common/password-hints/password-hints';

const ModalContainer = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    width: ['400px', '440px', '576px'],
    background: theme.styleguideColors.backgroundPrimary,
    borderColor: theme.styleguideColors.backgroundPrimary
  })
);

const ModalContentWrapper = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    gap: '40px',
    padding: ['20px', '48px', '48px']
  })
);

const StyledInformationText = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    marginTop: '8px',
    color: theme.styleguideColors.contentSecondary
  })
);

const ButtonsContainer = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    flexDirection: ['column', 'row', 'row']
  })
);

export const ResetPasswordWindow = ({
  confirmLabel,
  confirmColor,
  onConfirm,
  information,
  disableConfirm,
  title,
  customEmail
}: SetPasswordWindowProps) => {
  const [password, setPassword] = useState<string>('');
  const [formErrors, setFormErrors] = useState<Record<'password', string | null>>({
    password: null
  });

  const handleOnSubmit = () => {
    if (!password.length) {
      setFormErrors({
        ...formErrors,
        password: 'Please enter password'
      });
    }
    const validForm = !formErrors.password && password.length;
    validForm && onConfirm(password);
  };

  const handleOnBlur = () => {
    setFormErrors({
      ...formErrors,
      password: password.length < PASSWORD_LENGTH ? invalidPasswordMessage : ''
    });
  };

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pass = event.target.value;
    setPassword(pass);
  };

  return (
    <ModalContainer>
      <ModalContentWrapper>
        <FlexRow direction="column">
          <HeaderText size={3} scale="md">
            {title}
          </HeaderText>
          <StyledInformationText size={3} scale="sm">
            {information}
          </StyledInformationText>
        </FlexRow>

        <FlexRow gap={ROW_GAP_MD} direction="column">
          <Input value={customEmail} label={<BodyText size={1}>Email</BodyText>} disabled={true} />
          <>
            <Input
              value={password}
              label={<BodyText size={1}>Password</BodyText>}
              validationType={InputValidationType.password}
              onChange={handlePassword}
              error={!!formErrors.password}
              validationText={formErrors.password}
              onBlur={handleOnBlur}
            />

            {password && <PasswordHints password={password} />}
          </>
        </FlexRow>

        <ButtonsContainer justify={'space-between'}>
          {confirmLabel && (
            <Button
              color={confirmColor === 'red' ? 'primaryRed' : 'primaryBlue'}
              onClick={handleOnSubmit}
              disabled={disableConfirm}
            >
              {confirmLabel}
            </Button>
          )}
        </ButtonsContainer>
      </ModalContentWrapper>
    </ModalContainer>
  );
};
