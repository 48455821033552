import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled, { useTheme } from 'styled-components';
import {
  BodyText,
  CopyHash,
  FlexColumn,
  FlexRow,
  SubtitleText,
  SvgIcon,
  useClickAway
} from '@make-software/cspr-ui';
import CloseIcon from 'assets/icons/ic-close.svg';
import ShowIcon from 'assets/icons/ic-show.svg';
import HideIcon from 'assets/icons/ic-hide.svg';
import { HiddenText } from '../hidden-text/hidden-text';

export interface SuccessEntityWindowProps {
  isOpen: boolean;
  title: string;
  information: string;
  icon?: React.ReactElement;
  previewData?: string;
  onDismiss: () => void;
}

const centerModalStyles = {
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  borderRadius: 'none',
  padding: '32px 24px 24px 24px',
  top: '50%',
  transform: 'translate(-50%, -50%)'
};

const ModalContainer = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    width: ['300px', '400px', '446px'],
    background: theme.styleguideColors.backgroundPrimary,
    borderColor: theme.styleguideColors.backgroundPrimary
  })
);

const ImageWrapper = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    margin: '15px 0 32px 0'
  })
);

const StyledCloseIcons = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    cursor: 'pointer'
  })
);

const StyledCaption = styled.div(({ theme }) =>
  theme.withMedia({
    textAlign: 'center',
    marginBottom: '16px'
  })
);

const StyledInformationText = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    color: theme.styleguideColors.contentSecondary
  })
);

const StyledCaptionText = styled(SubtitleText)(({ theme }) =>
  theme.withMedia({
    fontWeight: [600, 600, 700],
    fontSize: ['20px', '24px', '24px'],
    color: theme.styleguideColors.contentPrimary
  })
);

const PreviewContainer = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginTop: '32px',
    wordBreak: 'break-all',
    padding: ['16px', '16px 0', '16px 0'],
    fontSize: ['20px', '24px', '24px'],
    backgroundColor: theme.styleguideColors.fillSecondary
  })
);

const StyledSvgIcon = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    marginRight: '15px',
    cursor: 'pointer'
  })
);

export const SuccessEntityWindow = ({
  isOpen,
  title,
  information,
  onDismiss,
  icon,
  previewData
}: SuccessEntityWindowProps) => {
  const [showKey, setShowKey] = useState<boolean>(false);
  const handleShowKey = () => setShowKey((prevState) => !prevState);
  const theme = useTheme();
  const modalStyle = {
    overlay: {
      backgroundColor: theme.styleguideColors.backgroundOverlay,
      zIndex: 15
    },
    content: {
      ...centerModalStyles,
      ...{
        border: 'none',
        backgroundColor: theme.styleguideColors.backgroundPrimary,
        borderTop: `4px solid ${theme.styleguideColors.contentRed}`,
        borderColor: theme.styleguideColors.contentRed,
        boxShadow: '0px 16px 48px rgba(26, 25, 25, 0.2)'
      }
    }
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [clickedCopy, setClickedCopy] = useState<boolean>(false);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const { ref } = useClickAway({
    callback: () => {
      if (!clickedCopy) {
        onDismiss();
      }
      setClickedCopy(false);
    }
  });

  return (
    <>
      {showModal && (
        <ReactModal
          isOpen={showModal}
          style={modalStyle}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
        >
          <ModalContainer ref={ref}>
            <FlexRow justify="flex-end">
              <StyledCloseIcons src={CloseIcon} onClick={onDismiss} />
            </FlexRow>
            {icon && (
              <FlexRow justify="center">
                <ImageWrapper>{icon}</ImageWrapper>
              </FlexRow>
            )}
            <StyledCaption>
              <StyledCaptionText size={1} scale="lg">
                {title}
              </StyledCaptionText>
            </StyledCaption>
            <FlexRow justify="center">
              <StyledInformationText size={3} scale="sm">
                {information}
              </StyledInformationText>
            </FlexRow>
            {previewData && (
              <PreviewContainer
                justify={'space-around'}
                align={'center'}
                onClick={() => {
                  setClickedCopy(true);
                }}
              >
                <BodyText size={3} scale={'xs'}>
                  <HiddenText
                    text={previewData}
                    hide={!showKey}
                    dotsNumber={previewData.length - 8}
                  />
                </BodyText>
                <FlexColumn direction={'row'}>
                  <StyledSvgIcon src={showKey ? ShowIcon : HideIcon} onClick={handleShowKey} />
                  <CopyHash value={previewData} label="" copiedLabel="" variation="gray" />
                </FlexColumn>
              </PreviewContainer>
            )}
          </ModalContainer>
        </ReactModal>
      )}
    </>
  );
};

export default SuccessEntityWindow;
