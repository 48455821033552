import styled from 'styled-components';
import {
  FlexRow,
  PageTile,
  SubtitleText,
  SvgIcon,
  TableRow,
  TableRowType
} from '@make-software/cspr-ui';
import React, { useEffect, useState } from 'react';
import { buildApi, DefaultResponse, GetResponseType, Pagination } from 'app/build-api/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Table from 'app/components/table/table';
import { RequestsLogListItem } from './requests-log-list-item';
import { AUTH_TOKEN, statusCode } from 'app/constants';
import * as Sentry from '@sentry/react';
import NoFound from 'assets/logos/no-found.svg';
import { PreloadTableWindow } from 'app/components/common/preload-table-window/preload-table-window';
import { SIGN_IN_PATH } from 'app/route/paths';
import {
  CloudApiItem,
  CloudFiltersState,
  CloudKeyResponseType,
  CloudLogItem,
  GetCloudKeyResponseType,
  GetCloudLogResponse
} from 'app/build-api';
import { RequestLogSearchLimit } from '../constants/constants';
import BaseTableHeader, {
  BaseTableHeaderProps,
  HeaderFontSize
} from 'app/components/common/base-table/base-table-header';

const BlockContent = styled(PageTile)(({ theme }) =>
  theme.withMedia({
    margin: '32px 0px'
  })
);

const StyledTableHeader = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    padding: '16px'
  })
);

const getPaginationFromSearchParams = (
  searchParams: URLSearchParams,
  itemLimit?: number
): Pagination => {
  const pagination = {
    currentPage: parseInt(searchParams.get('page') as string) || 1,
    pageSize: parseInt(searchParams.get('pageSize') as string) || 10
  };
  if (itemLimit && itemLimit < pagination.pageSize * pagination.currentPage) {
    searchParams.set('page', '1');
    searchParams.set('pageSize', '10');
    return { currentPage: 1, pageSize: 10 };
  }
  return pagination;
};

const RequestLogTableHeader = (props: BaseTableHeaderProps) => (
  <BaseTableHeader headerFontSize={HeaderFontSize.small} {...props} />
);

const getLogApiName = (api: number, apiList: CloudApiItem[]) => {
  const apiItem = apiList.find((item) => item.id === api);
  return apiItem ? apiItem.name : api;
};

const getLogKey = (key: string, keyList: CloudKeyResponseType[]) => {
  return keyList.find((item) => item.id == key);
};

interface RequestsLogListProps {
  filters: CloudFiltersState;
  apiList: GetResponseType<CloudApiItem[]>;
  keyList: GetResponseType<GetCloudKeyResponseType>;
}

export const RequestsLogList: React.FC<RequestsLogListProps> = ({ filters, apiList, keyList }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const initPagination = getPaginationFromSearchParams(searchParams, RequestLogSearchLimit);

  const [pagination, setPagination] = useState<Pagination>(initPagination);
  const [cloudLogs, setCloudLogs] = useState<GetResponseType<GetCloudLogResponse>>(DefaultResponse);

  useEffect(() => {
    (async () => {
      setCloudLogs(await buildApi.getCloudLogs(pagination, filters));
    })();
  }, [pagination, filters]);

  const handlePageActions = (page: number, limit: number) => {
    if (pagination.pageSize != limit) {
      page = 1;
    }
    setSearchParams({ page: page.toString(), pageSize: limit.toString() });
    setPagination((prev) => ({
      ...prev,
      currentPage: page,
      pageSize: limit
    }));
  };

  if (cloudLogs.error) {
    switch (cloudLogs.httpCode) {
      case statusCode.invalid_input_error:
      case statusCode.access_denied_error:
      case statusCode.validation_error:
        Sentry.captureException(cloudLogs.error);
        break;
      case statusCode.authentication_error:
        localStorage.removeItem(AUTH_TOKEN);
        navigate(SIGN_IN_PATH);
        break;
    }
  }

  return (
    <BlockContent>
      <StyledTableHeader>
        <SubtitleText size={1} scale={'md'}>
          Requests Log
        </SubtitleText>
      </StyledTableHeader>
      {cloudLogs.data?.data?.length === 0 ? (
        <PreloadTableWindow
          icon={<SvgIcon src={NoFound} height={120} width={224} />}
          title="No logs found"
          windowTitle=""
          subtitle="Please check your filters, or refer to the documentation to make your first request."
          onSubmitTitle="Documentation"
          onSubmit={() => {
            window.open('https://docs.cspr.cloud/documentation/getting-started', '_blank');
          }}
        />
      ) : (
        <Table
          handlePageActions={handlePageActions}
          loading={cloudLogs.loading || apiList.loading || keyList.loading}
          data={cloudLogs.data?.data || null}
          pageCount={cloudLogs.data?.page_count || 0}
          currentPage={pagination.currentPage}
          error={cloudLogs.error}
          tableRowType={TableRowType.TextSingleLine}
          pageSize={pagination.pageSize}
          itemCount={cloudLogs.data?.item_count || 0}
          renderDataHeader={() => (
            <TableRow>
              <RequestLogTableHeader fixedWidthRem={15} key="date">
                Date UTC
              </RequestLogTableHeader>
              <RequestLogTableHeader fixedWidthRem={10} key="key">
                Key
              </RequestLogTableHeader>
              <RequestLogTableHeader fixedWidthRem={12} key="api">
                API
              </RequestLogTableHeader>
              <RequestLogTableHeader fixedWidthRem={28} key="endpoint">
                Endpoint
              </RequestLogTableHeader>
              <RequestLogTableHeader fixedWidthRem={5} key="status">
                Status
              </RequestLogTableHeader>
              <RequestLogTableHeader fixedWidthRem={5} key="size">
                Size
              </RequestLogTableHeader>
              <RequestLogTableHeader fixedWidthRem={5} key="duration">
                Duration
              </RequestLogTableHeader>
            </TableRow>
          )}
          renderPaginatedData={(cloudLogs) =>
            cloudLogs.map((log: CloudLogItem, index) => (
              <RequestsLogListItem
                key={`${log['@timestamp']}-${index}`}
                item={log}
                apiName={getLogApiName(log.api, apiList.data || [])}
                cloudKey={getLogKey(log.auth, keyList.data?.data || [])}
              />
            ))
          }
        />
      )}
    </BlockContent>
  );
};
