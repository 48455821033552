import { DateRange, DateRangeKey, Period } from '../common';

export enum CloudInputFilters {
  keys = 'keys',
  apis = 'apis',
  endpoints = 'endpoints',
  statuses = 'statuses'
}

export interface CloudFiltersState {
  [DateRangeKey]: DateRange;
  [CloudInputFilters.keys]: string[];
  [CloudInputFilters.apis]: string[];
  [CloudInputFilters.endpoints]: string[];
  [CloudInputFilters.statuses]: string[];
}

export enum CloudGroupByTypes {
  'API' = 'API',
  'Key' = 'Key',
  'Status' = 'Status'
}

export enum CloudGroupBy {
  Api = 'api',
  Key = 'auth',
  Status = 'status'
}

export interface CloudRequestCountFilter {
  period: Period;
  groupBy: CloudGroupBy;
}

export const cloudFiltersSearchParams: Record<string, string> = {
  [CloudInputFilters.keys]: 'keys',
  [CloudInputFilters.apis]: 'apis',
  [CloudInputFilters.endpoints]: 'endpoints',
  [CloudInputFilters.statuses]: 'statuses'
};
