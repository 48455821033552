import styled from 'styled-components';
import {
  BodyText,
  FlexColumn,
  FlexRow,
  MainMenu,
  MainMenuItem,
  SvgIcon
} from '@make-software/cspr-ui';
import { HOME_PATH, STATISTICS_PATH, SUBSCRIPTIONS_PATH } from '../../route/paths';
import { MenuLink as Link } from 'app/components/common/link/link';
import { checkGrantedAccess } from 'app/components/utils/utils';
import { AccessItem } from 'app/build-api';
import UserIcon from 'assets/icons/ic-user.svg';
import LogOutIcon from 'assets/icons/ic-logout.svg';
import { MobileMenuLink } from './mobile-menu-link';

const MainMenuContainer = styled.div(({ theme }) =>
  theme.withMedia({
    position: 'relative',
    padding: 0,
    margin: 0,
    width: 'auto',
    '*': {
      boxSizing: 'border-box'
    },

    '& li': {
      fontSize: '14px',

      ':last-child': {
        paddingRight: 0
      }
    }
  })
);

const MobileMenuContainer = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    background: theme.styleguideColors.backgroundTertiary,
    justifyContent: 'space-between',
    alignItems: 'baseline',
    height: 'calc(100svh - 70px)',
    padding: '0px',
    margin: 0,
    position: 'fixed',
    left: 0,
    top: 71,
    width: '100%',
    zIndex: 1
  })
);

const StyledFlexColumn = styled(FlexColumn)(({ theme }) => ({
  width: '100%'
}));

const LogOutSection = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    borderTop: '1px solid #D2D4D933',
    width: '100%',
    padding: '16px 32px',
    justifyContent: 'space-between',
    alignItems: 'center'
  })
);

interface NavigationMenuProps {
  email: string;
  handleDocsRedirect: () => void;
  handleLogout: () => void;
}

export const DesktopNavigationMenu = ({
  email,
  handleDocsRedirect,
  handleLogout
}: NavigationMenuProps) => {
  return (
    <MainMenuContainer>
      <MainMenu>
        <MainMenuItem>
          <Link to={HOME_PATH}>Home</Link>
        </MainMenuItem>
        <MainMenuItem>
          <BodyText size={3} onClick={handleDocsRedirect}>
            Documentation
          </BodyText>
        </MainMenuItem>
        {checkGrantedAccess(AccessItem.TierManagement) && (
          <MainMenuItem>
            <Link to={SUBSCRIPTIONS_PATH}>Subscriptions</Link>
          </MainMenuItem>
        )}
        {checkGrantedAccess(AccessItem.Statistics) && (
          <MainMenuItem>
            <Link to={STATISTICS_PATH}>Statistics</Link>
          </MainMenuItem>
        )}
        <MainMenuItem>
          <SvgIcon src={UserIcon} marginRight />
          <span>{email}</span>
        </MainMenuItem>
        <MainMenuItem>
          <span onClick={handleLogout}>Log out</span>
        </MainMenuItem>
      </MainMenu>
    </MainMenuContainer>
  );
};

export const MobileNavigationMenu = ({
  email,
  handleLogout,
  handleDocsRedirect,
  setMenuVisible
}: NavigationMenuProps & { setMenuVisible: (isOpen: boolean) => void }) => {
  const handleCloseMenu = () => {
    setMenuVisible(false);
  };

  const handleDocsRedirectAndClose = () => {
    handleDocsRedirect();
    handleCloseMenu();
  };

  return (
    <MainMenuContainer>
      <MobileMenuContainer justify="space-between">
        <StyledFlexColumn>
          <MobileMenuLink to={HOME_PATH} onClick={handleCloseMenu}>
            Home
          </MobileMenuLink>
          <MainMenuItem>
            <span onClick={handleDocsRedirectAndClose}>Documentation</span>
          </MainMenuItem>
          {checkGrantedAccess(AccessItem.TierManagement) && (
            <MobileMenuLink to={SUBSCRIPTIONS_PATH} onClick={handleCloseMenu}>
              Subscriptions
            </MobileMenuLink>
          )}
          {checkGrantedAccess(AccessItem.Statistics) && (
            <MobileMenuLink to={STATISTICS_PATH} onClick={handleCloseMenu}>
              Statistics
            </MobileMenuLink>
          )}
        </StyledFlexColumn>
        <LogOutSection>
          <FlexRow align={'center'}>
            <SvgIcon src={UserIcon} marginRight />
            <BodyText scale={'sm'} size={3} variation={'lightGray'}>
              {email}
            </BodyText>
          </FlexRow>
          <SvgIcon src={LogOutIcon} onClick={handleLogout} />
        </LogOutSection>
      </MobileMenuContainer>
    </MainMenuContainer>
  );
};
