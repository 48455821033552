import { GetResponseType } from 'app/build-api/types';
import useApi from 'app/build-api/hooks/useApi';
import { WITH_AUTH } from 'app/constants';

export interface CloudApiItem {
  id: number;
  name: string;
}

export const getCloudApiList = async (): Promise<GetResponseType<CloudApiItem[]>> => {
  return await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/cloud-apis`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
};
