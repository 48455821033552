import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FlexRow } from '@make-software/cspr-ui';
import {
  CurrentCloudTierType,
  ProductType,
  WithSubscriptionProps
} from 'app/build-api/subscription/subscription';
import { TierItem } from '../tier-item/tier-item';
import CustomRequestWindow from '../custom-request-window/custom-request-window';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { AUTH_TOKEN, CLOUD_PRO_TIER_ID, generalError, statusCode } from 'app/constants';
import { SIGN_IN_PATH } from 'app/route/paths';

interface CloudSubscriptionProps extends WithSubscriptionProps {
  onError: (error: ErrorResult | null) => void;
}

const Container = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    flexDirection: ['column', 'column', 'row', 'row']
  })
);

const cloudTiersOptions = {
  free: {
    title: 'Free',
    price: 0,
    properties: [
      'Up to 1 key',
      '100 API req / min',
      '6,000 API req / day',
      '100,000 API req / mo',
      '100 Casper Node req / min',
      '1,200 Casper Node req / day',
      '20,000 Casper Node req / mo',
      '3 simultaneous Streaming API and SSE connections'
    ]
  },
  pro: {
    title: 'Pro',
    price: 199,
    properties: [
      'Up to 10 keys',
      '400 API req / min',
      '30,000 API req / day',
      '400,000 API req / mo',
      '200 Casper Node req / min',
      '6,000 Casper Node req / day',
      '100 simultaneous Streaming API and SSE connections',
      'Persistent Streaming API connections'
    ]
  },
  custom: {
    title: 'Custom',
    price: 0,
    properties: [
      'Higher requests quotas',
      'Higher rate limits',
      'Dedicated resources',
      'Private network infrastructure',
      'Engineering team support'
    ]
  }
};

export const CloudSubscription = ({ tiersDetails, onError }: CloudSubscriptionProps) => {
  const navigate = useNavigate();
  const { cloudTier } = tiersDetails;
  const [openRequestCustomPlanModal, setOpenRequestCustomPlanModal] = useState(false);

  const handleOpenRequestCustomPlanModal = () => {
    setOpenRequestCustomPlanModal(true);
  };

  const handleCloseRequestCustomPlanModal = () => {
    setOpenRequestCustomPlanModal(false);
  };

  const handleUpgradePlan = async () => {
    const payload = {
      tier_id: CLOUD_PRO_TIER_ID,
      tier_type: ProductType.Cloud
    };

    const response = await buildApi.createCheckoutSession(payload);

    if (response.data?.url) {
      window.location.replace(response.data?.url);
    }

    if (response.error) {
      switch (response.httpCode) {
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        default:
          onError(generalError);
          break;
      }
    }
  };

  const handleCustomerPortal = async () => {
    const response = await buildApi.createCustomerPortal();

    if (response.data?.url) {
      window.location.replace(response.data?.url);
    }

    if (response.error) {
      switch (response.httpCode) {
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        default:
          onError(generalError);
          break;
      }
    }
  };

  return (
    <>
      <Container justify="center" gap={16}>
        <TierItem
          tierTitle={cloudTiersOptions.free.title}
          tierPrice={cloudTiersOptions.free.price}
          tierItems={cloudTiersOptions.free.properties}
          buttonLabel={cloudTier.currentType !== CurrentCloudTierType.free ? 'Select' : ''}
          onSubmit={
            cloudTier.currentType !== CurrentCloudTierType.free ? handleCustomerPortal : undefined
          }
          isCurrentTier={cloudTier.currentType === CurrentCloudTierType.free}
        />
        <TierItem
          tierTitle={cloudTiersOptions.pro.title}
          tierPrice={cloudTiersOptions.pro.price}
          tierItems={cloudTiersOptions.pro.properties}
          buttonLabel={
            cloudTier.cancelledDate && cloudTier.currentType === CurrentCloudTierType.pro
              ? 'Renew'
              : cloudTier.currentType === CurrentCloudTierType.pro
                ? 'Manage'
                : 'Upgrade'
          }
          onSubmit={
            cloudTier.currentType === CurrentCloudTierType.pro
              ? handleCustomerPortal
              : handleUpgradePlan
          }
          cancelledDate={cloudTier.cancelledDate}
          expirationDate={cloudTier.expirationDate}
          isCurrentTier={cloudTier.currentType === CurrentCloudTierType.pro}
        />
        <TierItem
          tierTitle={cloudTiersOptions.custom.title}
          tierItems={cloudTiersOptions.custom.properties}
          buttonLabel={cloudTier.currentType === CurrentCloudTierType.custom ? 'Manage' : 'Request'}
          onSubmit={handleOpenRequestCustomPlanModal}
          isCurrentTier={cloudTier.currentType === CurrentCloudTierType.custom}
        />
      </Container>

      <CustomRequestWindow
        isOpen={openRequestCustomPlanModal}
        onDismiss={handleCloseRequestCustomPlanModal}
      />
    </>
  );
};
