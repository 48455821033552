import styled from 'styled-components';
import { BodyText, NavLink } from '@make-software/cspr-ui';
import RouterLink, { RouterLinkProps } from 'app/components/common/link/router-link';

const MobileLinkContainer = styled.li<{ active?: boolean }>(({ theme, active }) =>
  theme.withMedia({
    display: 'flex',
    width: '100%',
    height: '100%',
    color: theme.styleguideColors.contentTertiary,
    padding: ['16px 32px', '16px 48px', '0 32px 0 0', '0 32px 0 0'],
    alignItems: 'center',
    ...(active && {
      color: theme.styleguideColors.contentOnFill,
      'span ': {
        fontWeight: 600
      },
      backgroundColor: theme.styleguideColors.backgroundQuaternary
    })
  })
);

export const MobileMenuLink = ({
  to,
  children,
  onClick
}: RouterLinkProps & { onClick?: () => void }) => (
  <RouterLink
    to={to}
    render={(props) => (
      <MobileLinkContainer active={props.active}>
        <NavLink
          {...props}
          href={to}
          onClick={(ev) => {
            if (!(ev.ctrlKey || ev.metaKey || ev.shiftKey)) {
              ev.preventDefault();
              props.onClick && props.onClick();
              onClick && onClick();
            }
          }}
        >
          <BodyText size={3}>{children}</BodyText>
        </NavLink>
      </MobileLinkContainer>
    )}
  />
);
