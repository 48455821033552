import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useLayoutEffect, useState } from 'react';
import { SignIn } from 'app/components/sing-in/sign-in';
import { HOME_PATH } from 'app/route/paths';
import { AUTH_TOKEN } from 'app/constants';
import { decodeJWT } from 'app/components/utils/utils';

export const OpenRoutes = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  useLayoutEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN);
    const validToken = token && !(JSON.parse(decodeJWT(token)).exp * 1000 < Date.now());

    if (validToken) {
      setLoggedIn(true);
      setLoading(false);
    } else {
      localStorage.removeItem(AUTH_TOKEN);
      setLoggedIn(false);
      setLoading(false);
    }
  }, []);

  if (loading) return <SignIn />;

  return loggedIn ? <Navigate to={HOME_PATH} replace state={{ from: location }} /> : <Outlet />;
};
