import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { SignInForm } from './components/sign-in-form';
import UnauthorizedLayout from 'app/layouts/unauthorized-layout';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { decodeJWT } from 'app/components/utils/utils';
import { SEND_CONFIRM_EMAIL_PATH } from 'app/route/paths';
import {
  BLANK_LAYOUT,
  GENERAL_ERROR_DESCRIPTION,
  GENERAL_ERROR_MESSAGE,
  statusCode
} from 'app/constants';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import { SvgIcon } from '@make-software/cspr-ui';
import NotFoundIcon from 'assets/logos/no-found.svg';
import CSPRBuildLogoOriginal from 'assets/logos/cspr-build-logo-original.svg';
import { SignInRequestData } from 'app/build-api';

export const SignIn = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [expectedError, setExpectedError] = useState<string>('');
  const [unexpectedError, setUnexpectedError] = useState<ErrorResult | null>(null);
  const handleSubmit = async (data: SignInRequestData) => {
    setExpectedError('');
    const response = await buildApi.signIn(data);
    const navigatePath = state?.from ? `${state.from.pathname}${state.from.search}` : '/';

    if (response.data?.token) {
      const decodedJwt = JSON.parse(decodeJWT(response.data?.token));
      if (decodedJwt.assignment === 'email_confirmation') {
        navigate(
          `${SEND_CONFIRM_EMAIL_PATH}?email=${decodedJwt.email}&token=${response.data.token}`
        );
      } else {
        navigate(navigatePath);
      }
    }

    if (response.error) {
      const err = {
        code: GENERAL_ERROR_MESSAGE,
        message: GENERAL_ERROR_DESCRIPTION
      };
      switch (response.httpCode) {
        case statusCode.invalid_input_error:
        case statusCode.validation_error:
          setUnexpectedError(response.error);
          Sentry.captureException(response.error);
          break;
        case statusCode.authentication_error:
        case statusCode.access_denied_error:
          setExpectedError(response.error.message);
          break;
        case statusCode.unexpected_error:
          setUnexpectedError(err);
          break;
        default:
          setUnexpectedError(err);
          break;
      }
    }
  };

  if (unexpectedError) {
    return (
      <UnauthorizedLayout title="Sign in" type={BLANK_LAYOUT}>
        <ErrorWindow
          title={unexpectedError.code}
          information={unexpectedError.message}
          icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
        />
      </UnauthorizedLayout>
    );
  }
  return (
    <UnauthorizedLayout title="Sign in" type={BLANK_LAYOUT}>
      <SvgIcon src={CSPRBuildLogoOriginal} width={249} height={44} />
      <SignInForm onSubmit={handleSubmit} expectedError={expectedError} />
    </UnauthorizedLayout>
  );
};
