import React from 'react';
import { InformationWindow } from 'app/components/common/information-window/information-window';

interface ErrorWindowProps {
  title: string;
  information?: string | React.ReactElement;
  withModal?: boolean;
  icon?: React.ReactElement;
}

export const ErrorWindow = ({ title, information, icon, withModal }: ErrorWindowProps) => {
  return (
    <InformationWindow
      title={title}
      information={information}
      bodyImg={icon}
      withModal={withModal}
    />
  );
};
