import React, { Children } from 'react';
import { BaseTable, BaseTableRenderProps } from 'app/components/common/base-table/base-table';
import { TableRowType } from '@make-software/cspr-ui';
import { TableLoader } from './table-loader';
import { TableError } from './table-error';
import { ErrorResult } from 'app/build-api/types';
import { SortingProps } from 'app/components/common/pagination/types';
import Pagination from '../common/pagination/pagination';

type RenderProps = BaseTableRenderProps & { sortingProps: SortingProps };

export type TableProps<Entity> = {
  loading: boolean;
  error?: ErrorResult | null;
  data: null | Entity[];
  renderTableHeader?: (renderProps: RenderProps) => React.ReactElement;
  renderDataHeader: (renderProps: RenderProps) => React.ReactElement;
  renderPaginatedData: (
    paginatedData: Entity[],
    renderProps: RenderProps
  ) => React.ReactElement | React.ReactElement[];
  tableRowType?: TableRowType;
  handlePageActions: (page: number, limit: number) => void;
  pageCount: number;
  currentPage: number;
  pageSize?: number;
  itemCount?: number;
};

export const Table = <Entity extends any>({
  loading,
  data,
  renderTableHeader,
  renderDataHeader,
  renderPaginatedData,
  error,
  tableRowType = TableRowType.TextWithAvatar,
  handlePageActions,
  pageCount,
  currentPage,
  pageSize,
  itemCount,
  ...props
}: TableProps<Entity>) => {
  const renderFooter = () =>
    !error && (
      <Pagination
        itemCount={itemCount}
        pageCount={pageCount}
        currentPage={currentPage}
        pageLimit={pageSize}
        handlePageActions={handlePageActions}
      />
    );

  return (
    <BaseTable
      renderTableHeader={(renderProps: BaseTableRenderProps) =>
        renderTableHeader &&
        renderTableHeader({
          ...renderProps,
          sortingProps: null!
        })
      }
      renderDataHeaders={(renderProps) => renderDataHeader({ ...renderProps, sortingProps: null! })}
      renderData={(renderProps) =>
        (data == null && !error) || loading ? (
          <TableLoader
            columnsLength={Children.count(
              renderDataHeader({
                ...renderProps,
                sortingProps: {}!
              }).props.children
            )}
            tableRowType={tableRowType}
          />
        ) : error ? (
          <TableError
            columnsLength={Children.count(
              renderDataHeader({
                ...renderProps,
                sortingProps: {}!
              }).props.children
            )}
            error={error}
          />
        ) : data ? (
          renderPaginatedData(data, {
            ...renderProps,
            sortingProps: {}!
          })
        ) : (
          <></>
        )
      }
      renderFooter={renderFooter}
      {...props}
    />
  );
};

export default Table;
