import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { BodyText, TableBody, TableHead } from '@make-software/cspr-ui';

export interface BaseTableRenderProps {}

export interface BaseTableProps {
  renderDataHeaders?: (renderProps: BaseTableRenderProps) => ReactNode;
  renderData?: (renderProps: BaseTableRenderProps) => ReactNode;
  renderTableHeader?: (renderProps: BaseTableRenderProps) => ReactNode;
  renderFooter?: (renderProps: BaseTableRenderProps) => ReactNode;
  noDataMessage?: string;
  noData?: boolean;
  paddingBottom?: number;
}

export const TableContainer = styled.div<{ paddingBottom?: number }>(
  ({ theme, paddingBottom }) => ({
    overflowX: 'auto',
    ...(paddingBottom && { paddingBottom }),
    minHeight: '360px'
  })
);

const StyledTable = styled.table(({ theme }) => ({
  width: '100%',
  position: 'relative',
  borderCollapse: 'collapse'
}));

const NoDataContainer = styled.div(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const BaseTable = ({
  renderData,
  renderDataHeaders,
  renderTableHeader,
  renderFooter,
  noDataMessage,
  noData,
  paddingBottom
}: BaseTableProps) => {
  const renderProps = {};

  return (
    <>
      {renderTableHeader && renderTableHeader(renderProps)}
      <TableContainer paddingBottom={paddingBottom}>
        <StyledTable>
          {renderDataHeaders && <TableHead>{renderDataHeaders(renderProps)}</TableHead>}
          {renderData && <TableBody>{renderData(renderProps)}</TableBody>}
        </StyledTable>
      </TableContainer>
      {renderFooter && renderFooter(renderProps)}
      {noDataMessage && noData && (
        <NoDataContainer>
          <BodyText size={1}>{noDataMessage}</BodyText>
        </NoDataContainer>
      )}
    </>
  );
};

export default BaseTable;
