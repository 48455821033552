import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Switch from 'react-switch';
import { useTheme } from 'styled-components';
import { BodyText, FlexRow, SvgIcon, useClickAway } from '@make-software/cspr-ui';
import {
  ButtonsContainer,
  centerModalStyles,
  ErrorText,
  ErrorTextContainer,
  ModalContainer,
  StyledCaption,
  StyledCaptionText,
  StyledConfirmButton,
  StyledDismissButton,
  StyledInput,
  StyledToggle,
  TOGGLE_ACTIVE_COLOR
} from 'app/components/home/components/create-modal-styles';
import { ErrorResult } from 'app/build-api/types';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import NotFoundIcon from 'assets/logos/no-found.svg';
import { CloudKeyResponseType, CloudKeyStatus, UpdateCloudKeyRequestType } from 'app/build-api';
import { checkMaxLength } from 'app/validators';
import { ExpectedErrors } from 'app/components/home/components/cloud-keys/cloud-key-container';

export interface EditCloudKeyWindow {
  isOpen: boolean;
  keyToEdit: CloudKeyResponseType;
  title: string;
  confirmLabel: string;
  onUpdate: (id: string, data: UpdateCloudKeyRequestType) => void;
  dismissLabel?: string;
  onDismiss: () => void;
  expectedError?: ExpectedErrors | undefined;
  unexpectedError?: ErrorResult | null;
  portalClass?: string;
}

export const EditCloudKeyWindow = ({
  isOpen,
  title,
  keyToEdit,
  confirmLabel,
  onUpdate,
  dismissLabel,
  onDismiss,
  unexpectedError,
  expectedError,
  portalClass = 'portal'
}: EditCloudKeyWindow) => {
  const theme = useTheme();
  const modalStyle = {
    overlay: {
      backgroundColor: theme.styleguideColors.backgroundOverlay,
      zIndex: 15
    },
    content: {
      ...centerModalStyles,
      ...{
        border: 'none',
        backgroundColor: theme.styleguideColors.backgroundPrimary,
        borderTop: `4px solid ${theme.styleguideColors.contentRed}`,
        borderColor: theme.styleguideColors.contentRed,
        boxShadow: '0px 16px 48px rgba(26, 25, 25, 0.2)'
      }
    }
  };

  const { id, name, status } = keyToEdit;
  const [keyName, setKeyName] = useState<string>(name);
  const [isStatusActive, setIsStatusActive] = useState<boolean>(status === CloudKeyStatus.ACTIVE);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<Record<'keyName', string>>({
    keyName: ''
  });

  const clearForm = () => {
    setKeyName(name);
    setIsStatusActive(status === CloudKeyStatus.ACTIVE);
    setFormErrors({
      ...formErrors,
      keyName: ''
    });
  };

  useEffect(() => {
    setShowModal(isOpen);
    clearForm();
  }, [isOpen]);

  const { ref } = useClickAway({
    callback: () => {
      clearForm();
      onDismiss();
    }
  });

  const handleKeyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setKeyName(val);
  };

  const handleKeyNameOnBlur = () => {
    setFormErrors({
      ...formErrors,
      keyName: !keyName.length ? 'This field is required' : checkMaxLength(keyName)
    });
  };

  const handleChangeKeyStatus = () => {
    setIsStatusActive((prevState) => !prevState);
  };

  const handleConfirm = () => {
    if (!keyName.length) {
      setFormErrors({
        ...formErrors,
        keyName: 'This field is required'
      });
      return;
    }

    onUpdate &&
      !formErrors.keyName &&
      onUpdate(id, {
        name: keyName,
        status: isStatusActive ? CloudKeyStatus.ACTIVE : CloudKeyStatus.INACTIVE
      });
  };

  const handleOnDismiss = () => {
    clearForm();
    onDismiss();
  };

  return (
    <>
      {showModal && (
        <ReactModal
          isOpen={showModal}
          style={modalStyle}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          portalClassName={portalClass}
        >
          <ModalContainer ref={ref} gap={20}>
            {unexpectedError ? (
              <ErrorWindow
                title={unexpectedError.code || ''}
                information={unexpectedError.message}
                withModal
                icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
              />
            ) : (
              <>
                <StyledCaption>
                  <StyledCaptionText size={1} scale="lg">
                    {title}
                  </StyledCaptionText>
                </StyledCaption>
                <FlexRow justify="center">
                  <StyledInput
                    value={keyName}
                    label={<BodyText size={1}>Name</BodyText>}
                    placeholder="Key name"
                    onChange={handleKeyName}
                    onBlur={handleKeyNameOnBlur}
                    error={!!formErrors.keyName}
                    validationText={formErrors.keyName}
                  />
                </FlexRow>
                {expectedError && Object.keys(expectedError).length !== 0 && (
                  <ErrorTextContainer justify={'center'}>
                    <ErrorText size={3}>{expectedError.plainError}</ErrorText>
                  </ErrorTextContainer>
                )}
                <StyledToggle>
                  <BodyText size={1}>Active status:</BodyText>
                  <Switch
                    checked={isStatusActive}
                    onChange={handleChangeKeyStatus}
                    onColor={TOGGLE_ACTIVE_COLOR}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </StyledToggle>
                <ButtonsContainer justify={'space-between'}>
                  {dismissLabel && (
                    <StyledDismissButton color={'utility'} onClick={handleOnDismiss}>
                      {dismissLabel}
                    </StyledDismissButton>
                  )}
                  <StyledConfirmButton onClick={handleConfirm}>{confirmLabel}</StyledConfirmButton>
                </ButtonsContainer>
              </>
            )}
          </ModalContainer>
        </ReactModal>
      )}
    </>
  );
};

export default EditCloudKeyWindow;
