import React from 'react';
import styled from 'styled-components';
import UnauthorizedLayout from 'app/layouts/unauthorized-layout';
import { FlexRow, SvgIcon } from '@make-software/cspr-ui';
import CSPRBuildLogoOriginal from 'assets/logos/cspr-build-logo-original.svg';
import { BLANK_LAYOUT } from 'app/constants';

const ContentContainer = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    height: '100%',
    width: '100%',
    overflow: 'auto',
    paddingLeft: ['50px', 'unset', 'unset']
  })
);

const Content = styled.div(({ theme }) =>
  theme.withMedia({
    width: ['86%', '90%', '100%']
  })
);
const StyledSvg = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    marginTop: '20px'
  })
);
const StyledTD = styled.td(({ theme }) =>
  theme.withMedia({
    width: '20%'
  })
);
export const PrivacyPolicy = () => {
  return (
    <UnauthorizedLayout title="Privacy Policy" type={BLANK_LAYOUT}>
      <StyledSvg src={CSPRBuildLogoOriginal} width={249} height={44} />
      <ContentContainer>
        <Content>
          <h2>Privacy Policy</h2>
          <p>
            This Privacy Policy describes the privacy practices of MAKE Technology LLC and our
            subsidiaries and affiliates (collectively, “MAKE”, “we”, “us”, or “our”) and how we
            handle personal information that we collect through our websites, mobile applications,
            and related services in connection with our Casper Wallet, CSPR.name service, CSPR.click
            service, CSPR.cloud middleware, NFT minting services and marketplace, and block explorer
            (collectively, the “Services”).
          </p>
          <p>
            <strong>Personal information we collect</strong>
          </p>
          <p>
            <strong>Information you provide to us.</strong>&nbsp;Personal information you may
            provide to us through the Services or otherwise includes:
          </p>
          <ul role="list">
            <li>
              <strong>Contact data,&nbsp;</strong>such as your first and last name, , email and
              mailing addresses, and phone number, that you may choose to provide when you create an
              NFT Collection or in other specific instances. You do not need to provide this contact
              information to us for general use of our Services.&nbsp;&nbsp;
            </li>
            <li>
              <strong>Wallet information</strong>, including the cryptocurrency wallet’s public
              key&nbsp; you provide to us when you interact with any of our Services, including when
              you buy, store, and transfer NFTs or other cryptocurrency assets on our NFT
              marketplace, or when you use our wallet extensions. Note that MAKE does not have
              access to your private keys.
            </li>
            <li>
              <strong>
                Cryptocurrency transaction information, including information about the transactions
                you make using our Services, such as the public key of the recipient, transaction
                amount, and timestamp.&nbsp;
              </strong>
            </li>
            <li>
              <strong>
                Interests, usage, and connections, including information about your interests (if
                you choose to add them), how you use the Services, such as the types of content you
                create and engage with, content you share, features you use, actions you take, and
                people or accounts you interact and connect with.
              </strong>
            </li>
            <li>
              <strong>
                Communications that we exchange with you, including when you contact us with
                questions, feedback, or otherwise.
              </strong>
            </li>
            <li>
              <strong>Marketing data</strong>, such as your preferences for receiving our marketing
              communications, and details about your engagement with them.
            </li>
            <li>
              <strong>Other data</strong>&nbsp;not specifically listed here, which we will use as
              described in this Privacy Policy or as otherwise disclosed at the time of collection.
            </li>
          </ul>
          <p>
            <strong>Third party sources.&nbsp;</strong>We may combine personal information we
            receive from you with information we obtain from other sources, such as:
          </p>
          <ul role="list">
            <li>
              <strong>Third-party login information.&nbsp;</strong>When you link, connect, or login
              to the Services with a third party service (e.g. Google or Single Sign-On), you direct
              the service to send us information such as your registration, name, email address,
              photo, and profile information as controlled by that service or as authorized by you
              via your privacy settings at that service.
            </li>
            <li>
              <strong>
                Public sources, including blockchains and social media companies we maintain pages
                with, such as Discord and Twitter.
              </strong>
            </li>
            <li>
              <strong>
                Marketing partners, such as companies that have entered into joint marketing
                relationships with us.
              </strong>
            </li>
          </ul>
          <p>
            <strong>Automatic data collection.</strong>&nbsp;We, our service providers, and our
            advertising partners may automatically log information about you, your computer or
            mobile device, and your interaction over time with the Services, our communications and
            other online Services, such as:
          </p>
          <ul role="list">
            <li>
              <strong>Device data,&nbsp;</strong>such as your computer’s or mobile device’s
              operating system type and version, manufacturer and model, browser type, screen
              resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP
              address, unique identifiers (including identifiers used for advertising purposes),
              language settings, mobile device carrier, radio/network information (e.g., WiFi, LTE,
              3G).
            </li>
            <li>
              <strong>Online activity data,&nbsp;</strong>such as pages or screens you viewed, how
              long you spent on a page or screen, the website you visited before browsing to the
              Services, navigation paths between pages or screens, information about your activity
              on a page or screen, access times, and duration of access, and whether you have opened
              our marketing emails or clicked links within them.
            </li>
          </ul>
          <p>
            We collect this information using cookies and other similar technologies. For more
            information, please visit our Cookie Policy.&nbsp;
          </p>
          <p>
            <strong>How we use personal information</strong>
          </p>
          <p>
            We use your personal information for the following purposes or as otherwise described at
            the time of collection:
          </p>
          <p>
            <strong>Providing our Services</strong>. We use personal information to operate,
            maintain, and provide you with our Services, including to process your transactions. In
            particular, we use personal information to perform our contractual obligations under our
            Terms of Use.
          </p>
          <p>
            <strong>Communicating with you about our Services</strong>. It is in our legitimate
            business interests to use personal information to respond to your requests, provide
            customer support, and communicate with you about our Services, including by sending
            announcements, updates, security alerts and support and administrative messages.
          </p>
          <p>
            <strong>Improving, monitoring, personalizing, and protecting our Services</strong>. It
            is in our legitimate business interests to improve and keep our Services safe for our
            users, which includes:&nbsp;
          </p>
          <ul role="list">
            <li>
              understanding your needs and interests, and personalize your experience with the
              Services and our communications;
            </li>
            <li>troubleshooting, testing and research and to keep the Services secure; and</li>
            <li>
              investigating and protecting against fraudulent, harmful, unauthorized or illegal
              activity.
            </li>
          </ul>
          <p>
            <strong>Research and development.</strong>&nbsp;We may use personal information for
            research and development purposes in our legitimate business interests, including to
            analyze and improve the services and our business. As part of these activities, we may
            create or use aggregated, de-identified or other anonymized data from personal
            information we collect. We make personal information into anonymized data by removing
            information that makes the data personally identifiable to you. We may use this
            anonymized data and share it with third parties for our lawful business purposes,
            including to analyze and improve the services and promote our business.&nbsp;
          </p>
          <p>
            <strong>Marketing and advertising.</strong>&nbsp;We, our service providers, and our
            third-party advertising partners may collect and use personal information for the
            following marketing and advertising purposes:
          </p>
          <ul role="list">
            <li>
              <strong>Direct marketing.</strong>&nbsp;We may send you direct marketing
              communications as permitted by law, including by email. You may opt-out of our
              marketing communications as described in the Opt-out of marketing communications
              section below.
            </li>
            <li>
              <strong>Interest-based advertising.&nbsp;</strong>We may engage third-party
              advertising companies, such as Google, to display our ads on their online services. We
              may also share information about our users with these companies to facilitate
              advertising for our services to them or similar users on other online platforms. For
              more information, or to understand your choices, please visit our&nbsp;
              <strong>Cookie Policy.</strong>
            </li>
          </ul>
          <p>
            Except where we seek your consent, we undertake such marketing and advertising on the
            basis of our legitimate business interests. Where we seek your consent, you may withdraw
            your consent at any time.&nbsp;
          </p>
          <p>
            <strong>Compliance and protection.&nbsp;</strong>We may use personal information to
            comply with legal obligations, and it is in our legitimate business interests to use
            your personal information to defend us against legal claims or disputes, including to:
          </p>
          <ul role="list">
            <li>
              protect our, your or others’ rights, privacy, safety or property (including by making
              and defending legal claims);
            </li>
            <li>
              audit our internal processes for compliance with legal and contractual requirements
              and internal policies;
            </li>
            <li>enforce the terms and conditions that govern the services;</li>
            <li>
              prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical
              or illegal activity, including cyberattacks and identity theft; and
            </li>
            <li>
              comply with applicable laws, lawful requests and legal process, such as to respond to
              subpoenas or requests from government authorities.
            </li>
          </ul>
          <p>
            <strong>How we share personal information</strong>
          </p>
          <p>
            We may share your personal information with the following parties and as otherwise
            described in this Privacy Policy or at the time of collection:
          </p>
          <p>
            <strong>At your direction.&nbsp;</strong>For example, you may direct us to share your
            personal information with NFT creators when you purchase an NFT in the
            marketplace.&nbsp;
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>Affiliates.&nbsp;</strong>Our corporate parent, subsidiaries, and affiliates,
            for purposes consistent with this Privacy Policy.
          </p>
          <p>
            <strong>Service providers.&nbsp;</strong>Companies and individuals that provide services
            on our behalf or help us operate the Services or our business (such as hosting,
            information technology, customer support, email delivery, and website analytics
            services).
          </p>
          <p>
            <strong>Advertising partners.&nbsp;</strong>Third party advertising companies for the
            interest-based advertising purposes described above. The disclosure of this information
            may constitute a data “sale” under certain privacy laws.
          </p>
          <p>
            <strong>Professional advisors.&nbsp;</strong>Professional advisors, such as lawyers,
            auditors, bankers and insurers, where necessary in the course of the professional
            Services that they render to us.
          </p>
          <p>
            <strong>Authorities and others.&nbsp;</strong>Law enforcement, government authorities,
            and private parties, as we believe in good faith to be necessary or appropriate for the
            compliance and protection purposes described above.
          </p>
          <p>
            <strong>Business transferees.</strong>&nbsp;Acquirers and other relevant participants in
            business transactions (or negotiations for such transactions) involving a corporate
            divestiture, merger, consolidation, acquisition, reorganization, sale or other
            disposition of all or any portion of the business or assets of, or equity interests in,
            MAKE or our affiliates (including, in connection with a bankruptcy or similar
            proceedings).&nbsp;
          </p>
          <p>
            <strong>How you share personal information</strong>
          </p>
          <p>You may share your personal information with the following parties:</p>
          <p>
            <strong>The public.&nbsp;</strong>When you establish an account with us, you share
            certain profile information with the MAKE community on our Services. This information
            may include your name, username, NFTs owned, public blockchain transactions, and your
            wallet public addresses.
          </p>
          <p>
            <strong>MAKE users.&nbsp;</strong>MAKE users with whom you have some affiliation may
            receive notifications about actions you take on the platform, such as following or
            interacting with content and/or users. MAKE users with whom you transact on the NFT
            marketplace may receive additional information.
          </p>
          <p>
            <strong>Other third parties and partners.&nbsp;</strong>Users will have the option to
            share their personal information with interested third parties, social media platforms,
            and our partners.
          </p>
          <p>
            <strong>Privacy rights and choices</strong>
          </p>
          <p>
            <strong>Opt out of marketing communications.&nbsp;</strong>You may opt out of
            marketing-related communications by following the opt-out or unsubscribe instructions
            contained in the marketing communication we send you or by contacting us as provided in
            the How to Contact Us section below. You may continue to receive services-related and
            other non-marketing emails.
          </p>
          <p>
            <strong>Online tracking opt out.&nbsp;</strong>You can opt out of third-party cookies as
            described in our Cookie Policy.<strong>&nbsp;&nbsp;</strong>
          </p>
          <p>
            <strong>Personal information requests.&nbsp;</strong>We also offer you choices that
            affect how we handle the personal information that we control. Depending on your
            location and the nature of your interactions with our services, you may request the
            following in relation to personal information:&nbsp;
          </p>
          <ul role="list">
            <li>
              <strong>Information</strong>&nbsp;about how we have collected and used personal
              information. We have made this information available to you without having to request
              it by including it in this Privacy Policy.
            </li>
            <li>
              <strong>Access</strong>&nbsp;to a copy of the personal information that we have
              collected about you. Where applicable, we will provide the information in a portable,
              machine-readable, readily usable format.
            </li>
            <li>
              <strong>Correction&nbsp;</strong>of personal information that is inaccurate or out of
              date.
            </li>
            <li>
              <strong>Deletion</strong>&nbsp;of personal information that we no longer need to
              provide the Services or for other lawful purposes. You can delete any personal
              information we might collect about you by logging out of your account.&nbsp;
            </li>
            <li>
              <strong>Opt out of targeted advertising and the sale of personal information</strong>.
              We collect and share personal information with advertising partners that display
              targeted advertisements to users around the web. You can limit online tracking as
              described in our Cookie Policy or by clicking Your Privacy Choices on the footer of
              our website.&nbsp;
            </li>
            <li>
              <strong>Additional rights</strong>, such as to&nbsp;<strong>object</strong>&nbsp;to
              and request that we&nbsp;<strong>restrict</strong>&nbsp;our use of personal
              information.
            </li>
          </ul>
          <p>
            To make a request, please email us or write to us as provided in the How to Contact Us
            section below. We may ask for specific information from you to help us confirm your
            identity. Depending on where you reside, you may be entitled to empower an “authorized
            agent” to submit requests on your behalf. We will require authorized agents to confirm
            their identity and authority, in accordance with applicable laws. You are entitled to
            exercise the rights described above free from discrimination.
          </p>
          <p>
            <strong>Limits on your privacy rights and choices</strong>. In some instances, your
            choices may be limited, such as where fulfilling your request would impair the rights of
            others, our ability to provide a service you have requested, or our ability to comply
            with our legal obligations and enforce our legal rights. Additionally, as data on a
            blockchain cannot be changed or deleted by design, your ability to exercise your
            personal information requests with respect to on-chain personal information may be
            affected. If you are not satisfied with how we address your request, you may submit a
            complaint by contacting us as provided in the How to Contact Us section below.&nbsp;
          </p>
          <p>
            <strong>Right to complain</strong>. Depending on where you reside, such as if you reside
            in the European Economic Area or United Kingdom, you may have the right to complain to a
            data protection regulator where you live or work, or where you feel a violation has
            occurred. Click&nbsp;
            <a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">
              here
            </a>
            &nbsp;to find your local supervisory authority.
          </p>
          <p>
            <strong>Other sites and services</strong>
          </p>
          <p>
            The Services may contain links to websites and other online services operated by third
            parties. In addition, our content may be integrated into web pages or other online
            services that are not associated with us. These links and integrations are not an
            endorsement of, or representation that we are affiliated with, any third party. We do
            not control websites or online services operated by third parties, and we are not
            responsible for their actions.
          </p>
          <p>
            <strong>Security</strong>
          </p>
          <p>
            We employ a number of technical, organizational and physical safeguards designed to
            protect the personal information we collect. However, no security measures are failsafe
            and we cannot guarantee the security of your personal information.
          </p>
          <p>
            <strong>International data transfer</strong>
          </p>
          <p>
            You will provide personal information directly to our website in the United States. We
            may also transfer personal information to our affiliates and service providers in the
            United States and other jurisdictions. Please note that such jurisdictions may not
            provide the same protections as the data protection laws in your home country.
          </p>
          <p>
            When we engage in cross-border data transfers, we will ensure that relevant safeguards
            are in place to afford adequate protection for personal information and we will comply
            with applicable data protection laws, in particular by relying on an EU Commission or UK
            government adequacy decision or on contractual protections for the transfer of personal
            information. For more information about how we transfer personal information
            internationally, please contact us as set out in the How to Contact Us section below.
          </p>
          <p>
            <strong>Children</strong>
          </p>
          <p>
            The Services is not intended for use by children under 18 years of age. If we learn that
            we have collected personal information through the Services from a child under 18
            without the consent of the child’s parent or guardian as required by law, we will delete
            it.
          </p>
          <p>
            <strong>Retention of personal information</strong>
          </p>
          <p>
            Where required under applicable laws, we retain personal information only for as long as
            is necessary to fulfil the purposes for which it was collected and processed, in
            accordance with our retention policies, and in accordance with applicable laws and
            regulatory obligations or until you withdraw your consent (where applicable).&nbsp;
          </p>
          <p>
            To determine the appropriate retention period for personal information, we consider the
            amount, nature, and sensitivity of the personal information, the potential risk of harm
            from unauthorized use or disclosure of personal information, the purposes for which we
            use personal information and whether we can achieve those purposes through other means,
            and the applicable legal and regulatory requirements.&nbsp;
          </p>
          <p>
            <strong>Changes to this privacy policy</strong>
          </p>
          <p>
            We reserve the right to modify this Privacy Policy at any time. We will post the update
            Privacy Policy on the website. If we make material changes to this Privacy Policy, we
            will let you know.&nbsp;
          </p>
          <p>
            <strong>How to contact us</strong>
          </p>
          <p>
            <strong>Responsible entity.&nbsp;</strong>MAKE Technology LLC is the entity responsible
            for the processing of personal information under this Privacy Policy (as a controller,
            where provided under applicable law). MAKE is not the controller of information that is
            stored on public blockchains.
          </p>
          <p>
            <strong>Contact us.&nbsp;</strong>If you have any questions or comments about this
            Policy, our privacy practices, or if you would like to exercise your rights with respect
            to your personal information, please contact us by email at&nbsp;
            <a href="mailto:privacy@CSPR.live">privacy@CSPR.live</a>&nbsp;or by writing to us at:
          </p>
          <p>
            MAKE Technology LLC
            <br />
            30 N Gould St., Ste R<br />
            Sheridan, WY 82801
            <br />
            United States of America
          </p>
          <p>
            <strong>EEA and UK Representative Contact Information.&nbsp;</strong>For users in the
            EEA, we have appointed DataRep, at 12 Northbrook Road, Dublin, Ireland as our EU data
            representative and our UK data representative. You can contact our EU/UK representative
            at the address above or alternatively by sending an e-mail to DataRep at&nbsp;
            <a href="mailto:datarequest@datarep.com">datarequest@datarep.com</a>&nbsp;quoting
            &lt;MAKE Technology, LLC&gt; in the subject line.&nbsp;&nbsp;
          </p>
          <p>
            <strong>Privacy Snapshot</strong>
          </p>
          <table>
            <thead>
              <tr>
                <th>Data Categories Collected</th>
                <th>How We Collect</th>
                <th>Primary Purposes of Processing</th>
                <th>Key Recipients / Disclosures</th>
                <th>Can You Limit Sharing?</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <StyledTD>
                  Identifiers such as a real name, Internet Protocol address, email address, or
                  other similar identifiers
                </StyledTD>
                <StyledTD>When you visit our website or use our Services</StyledTD>
                <StyledTD>
                  To provide our Services; to improve, monitor, and personalize our Services; and
                  for marketing and advertising
                </StyledTD>
                <StyledTD>Service providersAdvertising partners</StyledTD>
                <StyledTD>No / Yes</StyledTD>
              </tr>
              <tr>
                <StyledTD>
                  Personal information categories listed in the California Customer Records Statute
                  (e.g., name, contact details)
                </StyledTD>
                <StyledTD>When you visit our website or use our Services</StyledTD>
                <StyledTD>
                  To provide our Services; to improve, monitor, and personalize our Services; and
                  for marketing and advertising
                </StyledTD>
                <StyledTD>Service providersAdvertising partners</StyledTD>
                <StyledTD>No / Yes</StyledTD>
              </tr>
              <tr>
                <StyledTD>
                  Commercial information, including but not limited to records of products or
                  services purchased
                </StyledTD>
                <StyledTD>When you visit our website or use our Services</StyledTD>
                <StyledTD>
                  To provide our Services; to improve, monitor, and personalize our Services; and to
                  communicate with you
                </StyledTD>
                <StyledTD>Service providers</StyledTD>
                <StyledTD>No</StyledTD>
              </tr>
              <tr>
                <StyledTD>
                  Internet or other electronic network activity information, including but not
                  limited to browsing history and search history
                </StyledTD>
                <StyledTD>When you visit our website or use our Services</StyledTD>
                <StyledTD>To provide our Services; and for marketing and advertising</StyledTD>
                <StyledTD>Service providersAdvertising partners</StyledTD>
                <StyledTD>No / Yes</StyledTD>
              </tr>
              <tr>
                <StyledTD>Geolocation data</StyledTD>
                <StyledTD>When you visit our website or use our Services</StyledTD>
                <StyledTD>To provide our Services; and for marketing and advertising</StyledTD>
                <StyledTD>Service providersAdvertising partners</StyledTD>
                <StyledTD>No / Yes</StyledTD>
              </tr>
            </tbody>
          </table>
        </Content>
      </ContentContainer>
    </UnauthorizedLayout>
  );
};
