import { GetResponseType } from '../types';
import useApi from '../hooks/useApi';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import { decodeJWT } from 'app/components/utils/utils';

interface RequestType {
  name: string;
  status: CloudKeyStatus;
}

export interface CloudKeyRequestType extends RequestType {}

export interface UpdateCloudKeyRequestType extends RequestType {}

export interface CloudKeyResponseType {
  id: string;
  organization_id: string;
  name: string;
  hash: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export interface GetCloudKeyResponseType {
  item_count: number;
  page_count: number;
  data: CloudKeyResponseType[] | null;
}

export enum CloudKeyStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export const createCloudKey = async (
  payload: CloudKeyRequestType
): Promise<GetResponseType<CloudKeyResponseType>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/cloud-keys`,
    'POST',
    payload,
    {},
    WITH_AUTH
  );
  return {
    data,
    httpCode,
    error,
    loading
  };
};

export const getCloudKeys = async (
  page?: number,
  pageSize?: number,
  isMaskedHash?: boolean
): Promise<GetResponseType<GetCloudKeyResponseType>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const getPageOption = () => {
    if (!page && !pageSize) return '';
    if (page && pageSize) return `?page=${page}&page_size=${pageSize}`;
    if (!page && pageSize) return `?page_size=${pageSize}`;
    if (page && !pageSize) return `?page=${page}`;
  };

  let urlPath = `organizations/${decodedJwt.organizationID}/cloud-keys`;
  if (isMaskedHash) {
    urlPath = urlPath + '/masked-hashes';
  }
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/${urlPath}${getPageOption()}`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
  return {
    data,
    httpCode,
    error,
    loading
  };
};

export const updateCloudKey = async (
  id: string,
  payload: UpdateCloudKeyRequestType
): Promise<GetResponseType<CloudKeyResponseType>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/cloud-keys/${id}`,
    'PUT',
    payload,
    {},
    WITH_AUTH
  );
  return {
    data,
    httpCode,
    error,
    loading
  };
};
