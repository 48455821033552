export const makeSocialChannels = () => {
  return [
    {
      label: 'website',
      key: 'website',
      urlUserId: 'https://make.services/'
    },
    {
      label: 'LinkedIn',
      key: 'linkedin',
      urlUserId: 'WeAreTeamMAKE'
    },
    {
      label: 'X',
      key: 'twitter_x',
      urlUserId: '@WeAreTeamMAKE'
    }
  ];
};

export const aboutCSPRBuild = () => {
  return [
    {
      label: 'Documentation',
      url: 'https://docs.cspr.build/',
      target: '_blank'
    },
    {
      label: 'CSPR.cloud Pro',
      url: 'https://cspr.cloud/pricing/',
      target: '_blank'
    },
    {
      label: 'CSPR.click Pro',
      url: 'https://cspr.click/pricing/',
      target: '_blank'
    },
    {
      label: '24/7 support',
      url: 'https://t.me/CSPRhub',
      target: '_blank'
    }
  ];
};

export const aboutMake = () => {
  return [
    {
      label: 'Stake with us',
      url: 'https://makestake.io/'
    },
    {
      label: 'Github',
      url: 'https://github.com/make-software/'
    },
    {
      label: 'Contact Us',
      url: 'https://make.services/#section-contact'
    }
  ];
};

export const aboutCSPRSuite = () => {
  return [
    {
      label: 'CSPR.live',
      url: 'https://cspr.live/'
    },
    {
      label: 'CSPR.market',
      url: 'https://cspr.market/'
    },
    {
      label: 'CSPR.click',
      url: 'https://cspr.click/'
    },
    {
      label: 'CSPR.studio',
      url: 'https://cspr.studio/'
    },
    {
      label: 'CSPR.cloud',
      url: 'https://cspr.cloud/'
    },
    {
      label: 'CSPR.name',
      url: 'https://cspr.name/'
    },
    {
      label: 'Casper Wallet',
      url: 'https://casperwallet.io'
    }
  ];
};

export const tosLinks = () => {
  return [
    {
      label: 'Privacy Policy',
      url: '/privacy-policy',
      target: '_blank'
    },
    {
      label: 'Terms of Service',
      url: '/terms-of-service',
      target: '_blank'
    }
  ];
};
