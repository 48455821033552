import { GetResponseType, TokenResponseType } from '../types';
import useApi from '../hooks/useApi';
import { NO_AUTH } from 'app/constants';

export interface SignUpRequestData {
  email: string;
  password: string;
  full_name: string;
  is_individual_person: boolean;
  organization_name: string;
}

export const signUp = async (
  requestData: SignUpRequestData,
  reCaptchaToken: string
): Promise<GetResponseType<TokenResponseType>> => {
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/auth/sign-up`,
    'POST',
    requestData,
    {},
    NO_AUTH,
    undefined,
    reCaptchaToken
  );

  return {
    data,
    httpCode,
    error,
    loading
  };
};
