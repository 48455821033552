import { useState } from 'react';
import { GetInvitedUsersResponseType } from 'app/build-api/user/user';
import { buildApi, GetResponseType } from 'app/build-api/types';
import { useOnMountUnsafe } from 'hooks/useOnMountUnsafe';

export const useGetInvitedUsers = (page?: number, pageSize?: number) => {
  const [getInvitedUsersResponse, setInvitedUsersResponse] = useState<
    GetResponseType<GetInvitedUsersResponseType>
  >({
    data: null,
    loading: false,
    error: null
  });

  const fetchInvitedUsers = async (page?: number, pageSize?: number) => {
    const response = await buildApi.getInvitedUsers(page, pageSize);
    setInvitedUsersResponse(response);
  };

  useOnMountUnsafe(() => fetchInvitedUsers(page, pageSize));

  const refetch = (page?: number, pageSize?: number) => fetchInvitedUsers(page, pageSize);

  return {
    data: getInvitedUsersResponse.data,
    httpCode: getInvitedUsersResponse.httpCode,
    error: getInvitedUsersResponse.error,
    loading: getInvitedUsersResponse.loading,
    refetch: refetch
  };
};
