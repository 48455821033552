import {
  clickFiltersSearchParams,
  ClickFilterState,
  cloudFiltersSearchParams,
  CloudFiltersState,
  CloudInputFilters,
  DateRange,
  DateRangeKey
} from 'app/build-api';
import { formatDateToISODate } from './date-time';

export const getSearchParamsFromDateRange = (
  searchParams: URLSearchParams,
  dateRange: DateRange
) => {
  searchParams.set('from', formatDateToISODate(dateRange.from));
  searchParams.set('to', formatDateToISODate(dateRange.to));
};

export const getSearchParamsFromCloudFilters = (
  searchParams: URLSearchParams,
  filters: CloudFiltersState
): URLSearchParams => {
  Object.entries(filters).forEach(([key, value]) => {
    if (cloudFiltersSearchParams[key]) {
      const paramKey = cloudFiltersSearchParams[key];

      if (key === CloudInputFilters.endpoints) {
        value = value.map((i: string) => i.replace(',', '~|~'));
      }

      const paramValue = value.join(',');
      return value && value.length
        ? searchParams.set(paramKey, paramValue)
        : searchParams.delete(paramKey);
    }

    if (key === DateRangeKey) {
      getSearchParamsFromDateRange(searchParams, value);
    }
  });

  return searchParams;
};

export const getSearchParamsFromClickFilters = (
  searchParams: URLSearchParams,
  filters: ClickFilterState
): URLSearchParams => {
  Object.entries(filters).forEach(([key, value]) => {
    if (clickFiltersSearchParams[key]) {
      const paramKey = clickFiltersSearchParams[key];

      const paramValue = value.join(',');
      return value && value.length
        ? searchParams.set(paramKey, paramValue)
        : searchParams.delete(paramKey);
    }

    if (key === DateRangeKey) {
      getSearchParamsFromDateRange(searchParams, value);
    }
  });

  return searchParams;
};
