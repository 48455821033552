//** UI constants **//

export const ROW_GAP_LG = 40;
export const ROW_GAP_MD = 28;
export const ROW_GAP_SM = 18;

// App constants
export const PASSWORD_LENGTH = 12;
export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

//** Layout constants **//
export const BLANK_LAYOUT = 'blank';

//** API constants **//
export const GENERAL_ERROR_MESSAGE = 'Something went wrong';
export const GENERAL_ERROR_DESCRIPTION =
  'We’re not sure what has happened. Please reload the page and start over again.';
export const TOKEN_EXPIRED_ERROR = 'token_expired';
export const AUTH_TOKEN = 'token';
export const WITH_AUTH = true;
export const NO_AUTH = false;

export const statusCode = {
  success: 200,
  no_content: 204,
  invalid_input_error: 400,
  authentication_error: 401,
  payment_required_error: 402,
  access_denied_error: 403,
  not_found_error: 404,
  conflict_error: 409,
  validation_error: 422,
  unexpected_error: 500
};

export const jsonErrorCodes = {
  tokenExpiredError: 'token_expired',
  validationError: 'validation_error',
  accessDenied: 'access denied',
  internalError: 'internal_error',
  authenticationError: 'authentication_error',
  conflictError: 'conflict',
  invalidInputError: 'invalid_input',
  notFoundError: 'not_found',
  paymentRequiredError: 'payment_required',
  networkValidationError: 'network_validation',
  usersLimitExceeded: 'users_limit_exceeded'
};

export const generalError = {
  code: GENERAL_ERROR_MESSAGE,
  message: GENERAL_ERROR_DESCRIPTION
};

//** NETWORK constants **//
export const TESTNET = 'casper-test';
export const MAINNET = 'casper';
export const CASPER_TEST = 'Casper Test';
export const CASPER = 'Casper';

//** SUBSCRIPTION constants **//
export const CLICK_FREE_TIER_ID: number = Number(process.env.REACT_APP_CLICK_FREE_TIER_ID);
export const CLOUD_FREE_TIER_ID: number = Number(process.env.REACT_APP_CLOUD_FREE_TIER_ID);
export const CLICK_PRO_TIER_ID: number = Number(process.env.REACT_APP_CLICK_PRO_TIER_ID);
export const CLOUD_PRO_TIER_ID: number = Number(process.env.REACT_APP_CLOUD_PRO_TIER_ID);

export const FREE_TIER = 'free';
export const PRO_TIER = 'pro';
export const CUSTOM_TIER = 'custom';

export const BADGE_COLOR = '#7490FF';
