import {
  ClickKeyResponseType,
  CloudApiItem,
  CloudKeyResponseType,
  CountHistogramResponse,
  UniqueHistogramResponse
} from 'app/build-api';
import { GraphComponentDataFormatType, GraphDataItemType } from './graph-component';

export const getRequestCount = (data: CountHistogramResponse): GraphDataItemType[] => {
  const defaultTransformedItem: GraphDataItemType = { date: '' };
  data.total_by_group_field?.forEach((item) => {
    defaultTransformedItem[item.key] = 0;
  });

  return data.histogram.map((item) => {
    let transformedItem: GraphDataItemType = { ...defaultTransformedItem, date: item.date };

    item.aggregation?.forEach((agg) => {
      transformedItem[agg.key.toString()] = agg.doc_count;
    });

    return transformedItem;
  });
};

export const getUniqueCount = (data: UniqueHistogramResponse): GraphDataItemType[] => {
  const defaultTransformedItem: GraphDataItemType = { date: '' };
  data.total_by_group_field?.forEach((item) => {
    defaultTransformedItem[item.key] = 0;
  });

  return data.histogram.map((item) => {
    let transformedItem: GraphDataItemType = { ...defaultTransformedItem, date: item.date };
    item.aggregation?.forEach((agg) => {
      transformedItem[agg.key.toString()] = agg.unique_count;
    });

    return transformedItem;
  });
};

export type ChartLegendsType = { name: string; id: string };

export const prepareApiListDataToChartLegends = (data: CloudApiItem[]): ChartLegendsType[] =>
  data.map((api) => ({
    id: api.id.toString(),
    name: api.name
  }));

export const prepareKeyListDataToChartLegends = (
  data: CloudKeyResponseType[]
): ChartLegendsType[] =>
  data.map((key) => {
    return {
      id: key.id,
      name: key.name + ' ' + key.hash
    };
  });

export const prepareStatusListDataToChartLegends = (
  data: {
    label: string;
    id: string;
  }[]
): ChartLegendsType[] =>
  data.map((status) => ({
    id: status.id,
    name: status.label
  }));

export const prepareAppListDataToChartLegends = (
  data: ClickKeyResponseType[]
): ChartLegendsType[] =>
  data.map((app) => {
    const name = `${app.name} ${app.app_id}`;
    return {
      id: app.app_id.toString(),
      name
    };
  });

export const prepareProvidersListDataToChartLegends = (
  data: {
    label: string;
    value: string;
  }[]
): ChartLegendsType[] =>
  data.map((app) => ({
    id: app.value.toString(),
    name: app.label
  }));

export const getChartValuesDataWithLabels = (
  xAxisAndLegendData: ChartLegendsType[],
  yAxisData: GraphDataItemType[]
): GraphComponentDataFormatType[] =>
  yAxisData?.length && xAxisAndLegendData?.length
    ? xAxisAndLegendData
        .filter((item) => Object.keys(yAxisData[0]).includes(item.id.toString()))
        .map((item) => ({
          graphKey: item.id.toString(),
          xAxisValueKey: item.id.toString(),
          checkboxLabel: item.name,
          data: yAxisData,
          initiallySelected: true
        }))
    : [];
