export const HOME_PATH = '/';
export const SIGN_UP_PATH = '/sign-up';
export const SIGN_IN_PATH = '/sign-in';
export const SEND_CONFIRM_EMAIL_PATH = '/email-confirmation';
export const CONFIRM_EMAIL_PATH = '/verify-email';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password';
export const TOS = '/terms-of-service';
export const PRIVACY_POLICY = '/privacy-policy';
export const SUBSCRIPTIONS_PATH = '/subscriptions';
export const STATISTICS_PATH = '/statistics';

export type RouterPathsMap = {
  [HOME_PATH]: {
    urlParams: undefined;
    queryParams: undefined;
  };
  [SIGN_UP_PATH]: {
    urlParams: undefined;
    queryParams: undefined;
  };
  [SIGN_IN_PATH]: {
    urlParams: undefined;
    queryParams: undefined;
  };
  [SEND_CONFIRM_EMAIL_PATH]: {
    urlParams: undefined;
    queryParams: { email: string };
  };
  [CONFIRM_EMAIL_PATH]: {
    urlParams: undefined;
    queryParams: { token: string };
  };
  [FORGOT_PASSWORD_PATH]: {
    urlParams: undefined;
    queryParams: undefined;
  };
  [RESET_PASSWORD_PATH]: {
    urlParams: undefined;
    queryParams: { token: string };
  };
  [TOS]: {
    urlParams: undefined;
    queryParams: undefined;
  };
  [PRIVACY_POLICY]: {
    urlParams: undefined;
    queryParams: undefined;
  };
  [SUBSCRIPTIONS_PATH]: {
    urlParams: undefined;
    queryParams: { activeTab: string; event: string };
  };
  [STATISTICS_PATH]: {
    urlParams: undefined;
    queryParams: undefined;
  };
};
