import { ClickFilterState, ClickSessionCountGraphFilter } from './filter';
import { CountHistogramResponse } from '../common';
import { getSearchParamsFromClickFilters } from 'app/components/utils/url-utils';
import { GetResponseType } from 'app/build-api/types';
import { decodeJWT } from 'app/components/utils/utils';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import useApi from 'app/build-api/hooks/useApi';
import { getApiQueryParamsFromGraphFilters } from '../url-utils';

export const getClickSessionCountHistogram = async (
  filters: ClickFilterState,
  graphFilter: ClickSessionCountGraphFilter
): Promise<GetResponseType<CountHistogramResponse>> => {
  const searchParams = new URLSearchParams();
  const searchParamsWithFilters = getSearchParamsFromClickFilters(searchParams, filters);
  const searchParamsWithGraphFilters = getApiQueryParamsFromGraphFilters({
    searchParams: searchParamsWithFilters,
    groupBy: graphFilter.clickSessionsCountGroupBy,
    period: graphFilter.clickSessionsCountPeriod
  });

  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));

  return await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/click-logs/sessions-count?${searchParamsWithGraphFilters.toString()}`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
};
