import React from 'react';
import {
  BodyText,
  Button,
  FlexColumn,
  FlexRow,
  SubtitleText,
  SvgIcon
} from '@make-software/cspr-ui';
import styled from 'styled-components';
import CheckmarkIcon from 'assets/icons/ic-checkmark.svg';

interface TierItemProps {
  tierTitle: string;
  tierItems: string[];
  tierPrice?: number;
  buttonLabel?: string;
  onSubmit?: () => void;
  cancelledDate?: string;
  expirationDate?: string;
  isCurrentTier: boolean;
}

const SubscriptionInfo = styled(FlexRow)<{ isActive: boolean }>(({ theme, isActive }) =>
  theme.withMedia({
    backgroundColor: theme.styleguideColors.fillSecondary,
    height: ['620px', '570px', '675px', '628px'],
    width: ['90%', '90%', '100%', '100%'],
    padding: ['20px', '20px', '40px 30px 28px', '40px 40px 28px'],
    border: `${isActive && `3px solid ${theme.styleguideColors.contentLightBlue}`}`,
    borderRadius: isActive && `4px`
  })
);

const ItemContainer = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginTop: '32px',
    height: ['460px', '400px', '510px', '468px']
  })
);

const StyledCheckmarkWrapper = styled.div(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '28px'
}));

const CheckmarkRow = styled(FlexRow)(() => ({
  marginBottom: '28px'
}));

const PaymentDetails = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginTop: '36px',
    borderTop: `1px solid ${theme.styleguideColors.borderSecondary}`,
    height: '64px'
  })
);

const AndMoreText = styled.div(({ theme }) =>
  theme.withMedia({
    fontSize: '16px',
    marginTop: '10px'
  })
);

const StyledPrice = styled.div(({ theme }) =>
  theme.withMedia({
    position: 'relative',
    bottom: '6px'
  })
);

const NextChargeText = styled.div(({ theme }) =>
  theme.withMedia({
    fontSize: '11px',
    color: theme.styleguideColors.contentSecondary
  })
);

export const TierItem = ({
  tierTitle,
  tierItems,
  tierPrice,
  buttonLabel,
  cancelledDate,
  expirationDate,
  onSubmit,
  isCurrentTier
}: TierItemProps) => {
  return (
    <SubscriptionInfo direction="column" isActive={isCurrentTier}>
      <SubtitleText size={1} scale={'lg'}>
        {tierTitle}
      </SubtitleText>
      <ItemContainer direction={'column'}>
        <StyledCheckmarkWrapper>
          {tierItems.map((item) => (
            <CheckmarkRow gap={12} align={'center'} key={item}>
              <FlexColumn>
                <SvgIcon src={CheckmarkIcon} size={20} />
              </FlexColumn>
              <FlexColumn>{item}</FlexColumn>
            </CheckmarkRow>
          ))}
        </StyledCheckmarkWrapper>
        {tierTitle === 'Custom' && <AndMoreText>And more...</AndMoreText>}
      </ItemContainer>
      <PaymentDetails align={'end'} justify={'space-between'}>
        {tierTitle === 'Custom' ? (
          <StyledPrice>
            <BodyText size={1} scale={'lg'}>
              Custom
            </BodyText>
          </StyledPrice>
        ) : (
          <FlexRow align={'start'} direction={'column'}>
            <StyledPrice>
              <BodyText size={1} scale={'lg'}>
                ${tierPrice}
              </BodyText>
              <BodyText size={1} scale={'lg'} variation={'darkGray'}>
                {` / mo`}
              </BodyText>
            </StyledPrice>
            {(cancelledDate || expirationDate) && (
              <NextChargeText>
                {cancelledDate ? `active till ${cancelledDate}` : `next charge ${expirationDate}`}
              </NextChargeText>
            )}
          </FlexRow>
        )}
        {buttonLabel && (
          <Button width={'100'} onClick={onSubmit}>
            {buttonLabel}
          </Button>
        )}
      </PaymentDetails>
    </SubscriptionInfo>
  );
};
