import { GetResponseType } from 'app/build-api/types';
import useApi from 'app/build-api/hooks/useApi';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import { decodeJWT } from 'app/components/utils/utils';
import { formatDateToISODate } from 'app/components/utils/date-time';

export interface CloudEndpointItem {
  key: string;
  doc_count: number;
}

export const getCloudPopularEndpointsList = async (
  from: Date,
  to: Date
): Promise<GetResponseType<CloudEndpointItem[]>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const searchParams = new URLSearchParams();

  searchParams.append('from', formatDateToISODate(from));
  searchParams.append('to', formatDateToISODate(to));

  return await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/cloud-logs/endpoints/popular?${searchParams.toString()}`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
};

export const getCloudAutocompleteEndpointsList = async (
  from: Date,
  to: Date,
  search: string
): Promise<GetResponseType<CloudEndpointItem[]>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const searchParams = new URLSearchParams();

  searchParams.append('from', formatDateToISODate(from));
  searchParams.append('to', formatDateToISODate(to));

  if (search) {
    searchParams.append('search', encodeURIComponent(search));
  }

  return await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/cloud-logs/endpoints/autocomplete?${searchParams.toString()}`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
};
