import { GetResponseType } from '../types';
import useApi from '../hooks/useApi';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import { decodeJWT } from 'app/components/utils/utils';

export interface RequestCustomTierMessageRequestType {
  message: string;
}

export const requestCustomTierMessage = async (
  requestData: RequestCustomTierMessageRequestType
): Promise<GetResponseType<any>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));

  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/payments/custom-request`,
    'POST',
    requestData,
    {},
    WITH_AUTH
  );

  return {
    data,
    httpCode,
    error,
    loading
  };
};
