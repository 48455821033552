import React, { useState } from 'react';
import styled from 'styled-components';
import { BodyText, CopyHash, FlexRow, SvgIcon, TableData, TableRow } from '@make-software/cspr-ui';
import { CloudKeyResponseType, CloudKeyStatus } from 'app/build-api';
import { HiddenText } from 'app/components/common/hidden-text/hidden-text';
import ShowIcon from 'assets/icons/ic-show.svg';
import HideIcon from 'assets/icons/ic-hide.svg';
import EditIcon from 'assets/icons/ic-edit.svg';

interface CloudKeyListItemProps {
  cloudKey: CloudKeyResponseType;
  onOpenEditWindow: (key: CloudKeyResponseType) => void;
}

const StatusWrapper = styled(BodyText)<{ status?: string }>(({ theme, status }) =>
  theme.withMedia({
    textTransform: 'uppercase',
    color:
      status === CloudKeyStatus.ACTIVE
        ? theme.styleguideColors.contentGreen
        : theme.styleguideColors.contentSecondary
  })
);

const StyledHash = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    marginRight: '15px'
  })
);

const StyledSvgIcon = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    marginRight: '15px',
    cursor: 'pointer'
  })
);

const StyledEditSvg = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    cursor: 'pointer'
  })
);

export const CloudKeyListItem = ({ cloudKey, onOpenEditWindow }: CloudKeyListItemProps) => {
  const { id, name, hash, status } = cloudKey;

  const [showKey, setShowKey] = useState<boolean>(false);
  const handleShowKey = () => setShowKey((prevState) => !prevState);

  const openEditWindow = () => {
    onOpenEditWindow(cloudKey);
  };

  return (
    <TableRow key={id}>
      <TableData>
        <BodyText size={3} monotype>
          {name}
        </BodyText>
      </TableData>
      <TableData>
        <FlexRow justify={'flex-start'} align={'center'}>
          <StyledHash size={3} scale={'xs'} monotype>
            <HiddenText text={hash} hide={!showKey} dotsNumber={hash.length - 8} />
          </StyledHash>
          <StyledSvgIcon src={showKey ? ShowIcon : HideIcon} onClick={handleShowKey} />
          <CopyHash value={hash} label="" copiedLabel="" variation="gray" />
        </FlexRow>
      </TableData>
      <TableData>
        <StatusWrapper status={status} size={3} monotype>
          {status}
        </StatusWrapper>
      </TableData>
      <TableData align="right">
        <BodyText size={3} monotype>
          <StyledEditSvg src={EditIcon} onClick={openEditWindow} />
        </BodyText>
      </TableData>
    </TableRow>
  );
};
