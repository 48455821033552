import { GetResponseType, TokenResponseType } from '../types';
import useApi from '../hooks/useApi';
import { AUTH_TOKEN, NO_AUTH } from 'app/constants';

export interface SignInRequestData {
  email: string;
  password: string;
  is_long_session: boolean;
}

export const signIn = async (
  requestData: SignInRequestData
): Promise<GetResponseType<TokenResponseType>> => {
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/auth/session`,
    'POST',
    requestData,
    {},
    NO_AUTH
  );

  if (data && data.token) {
    localStorage.setItem(AUTH_TOKEN, data.token);
  }

  return {
    data,
    httpCode,
    error,
    loading
  };
};
