import React from 'react';

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface SortingProps {
  orderBy?: string | undefined;
  orderDirection?: OrderDirection;
  setOrder?: (orderBy: string | undefined, direction: OrderDirection) => void;
  reverseSortingDirection?: boolean;
  id?: string;
  className?: string;
  children?: any;
  style?: React.CSSProperties;
  onClick?: (ev: any) => void;
}
