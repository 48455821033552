import React from 'react';
import styled from 'styled-components';
import UnauthorizedLayout from 'app/layouts/unauthorized-layout';
import { FlexRow, SvgIcon } from '@make-software/cspr-ui';
import CSPRBuildLogoOriginal from 'assets/logos/cspr-build-logo-original.svg';
import { BLANK_LAYOUT } from 'app/constants';

const ContentContainer = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    height: '100%',
    width: '100%',
    overflow: 'auto',
    paddingLeft: ['50px', 'unset', 'unset']
  })
);

const Content = styled.div(({ theme }) =>
  theme.withMedia({
    width: ['86%', '90%', '100%']
  })
);
const StyledSvg = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    marginTop: '20px'
  })
);

export const Tos = () => {
  return (
    <UnauthorizedLayout title="Terms of Services" type={BLANK_LAYOUT}>
      <StyledSvg src={CSPRBuildLogoOriginal} width={249} height={44} />
      <ContentContainer>
        <Content>
          <h2>Terms of Services</h2>
          <div>
            <p>
              MAKE Technology, LLC (“<strong>Company</strong>,” “<strong>we</strong>,” “
              <strong>us</strong>,” “<strong>our</strong>”) provides its Services (as defined below)
              to you through its websites located at CSPR.live, CasperWallet.io, CSPR.studio,
              CSPR.market, CSPR.name, CSPR.cloud and CSPR.click (the “<strong>Sites</strong>”), and
              through our mobile applications and related technologies (“
              <strong>Mobile Apps</strong>,” and collectively, such Mobile Apps and the Sites,
              including any updated or new features, functionality and technology), subject to the
              following Terms of Service (as amended from time to time, the “
              <strong>Terms of Service</strong>”). Company reserves the right, at our sole
              discretion, to change or modify portions of these Terms of Service at any time. If
              Company does this, Company will post the changes on this page and will indicate at the
              top of this page the date these terms were last revised. Company will also notify you,
              either through the Services user interface, in an email notification or through other
              reasonable means. Any such changes will become effective no earlier than fourteen (14)
              days after they are posted, except that changes addressing new functions of the
              Services or changes made for legal reasons will be effective immediately. Your
              continued use of the Service after the date any such changes become effective
              constitutes your acceptance of the new Terms of Service. If you are entering into this
              Terms of Service on behalf of a company, business or other legal entity, you represent
              that you have the authority to bind such entity and its affiliates to this Terms of
              Service, in which case the terms “you” or “your” shall refer to such entity and its
              affiliates. If you do not have such authority, or if you do not agree with this Terms
              of Service, you must not accept this Terms of Service and may not use the Service.
              Crypto-cashback services marketed as CSPR.shopping are provided by a third party:
              Bring Web3 Ltd (Bring). By using these services, you agree to be bound by Bring's{' '}
              <a href="http://www.bringweb3.io/termsofuse">Terms of Use</a>
              and <a href="http://www.bringweb3.io/privacypolicy">Privacy Policy</a>.
            </p>
            <p>
              <strong>
                <em>
                  PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO
                  ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES,
                  AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT
                  YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, AND
                  FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST COMPANY ON AN
                  INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
                  REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF
                  (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS,
                  AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A
                  JURY OR IN A COURT OF LAW.
                </em>
              </strong>
            </p>
            <p>
              <strong>Your Privacy:&nbsp;</strong>At Company, we respect the privacy of our users.
              For more information, please see our privacy policies, located on CSPR.live,
              CasperWallet.io, CSPR.studio, CSPR.market, CSPR.name, CSPR.cloud and CSPR.click
              (collectively, the “Privacy Policies”). By using the Service, you consent to our
              collection, use and disclosure of personal data and other data as outlined therein.
            </p>
            <p>
              <strong>Additional Terms:</strong>&nbsp;In addition, when using certain features
              through the Service, you will be subject to any additional terms applicable to such
              features that may be posted on or within the Service from time to time. All such terms
              are hereby incorporated by reference into these Terms of Service.
            </p>
            <p>
              <strong>Definitions</strong>
            </p>
            <p>
              The capitalized terms used in these Terms of Service have the meanings ascribed to
              them in this section or where they are elsewhere defined in these Terms of Service.
              Any term defined in the singular will have the corresponding definition in the plural
              (and vice versa). As used in these Terms of Service
            </p>
            <ol role="list">
              <li>
                “<strong>Accepted Digital Currency</strong>” shall mean only those particular
                Digital Currencies listed as available to transfer or receive in the Wallet,
                including Casper.
              </li>
              <li>
                “<strong>Digital Currencies</strong>” shall mean any cryptocurrencies, decentralized
                application tokens and protocol tokens, whether issued in a private or public
                transaction.
              </li>
              <li>
                “<strong>Digital Currency Network</strong>” means any blockchain network for which
                the Platform is able to perform the Service, including the Casper Network.
              </li>
              <li>
                “<strong>Your</strong>” or “<strong>you</strong>” means the Party using the Service.
              </li>
              <li>
                “<strong>Party</strong>” means you or Company, as applicable, and “Parties” means
                you and Company collectively.
              </li>
              <li>
                “<strong>Platform</strong>” means Company’s technology platform and infrastructure
                that it uses to perform the Service.
              </li>
              <li>
                “<strong>Service</strong>” means, collectively, the transaction inspection
                functionality provided by Company on its CSPR.live block explorer (“
                <strong>Block Explorer</strong>”), the NFT minting functionality provided by Company
                on its CSPR.studio application (“<strong>NFT Minting</strong>”), the NFT Marketplace
                provided by Company on its CSPR.market application and white label instances thereof
                (“<strong>NFT Marketplace</strong>”), the non-custodial digital currency wallet
                application provided by the Company known as Casper Wallet (“
                <strong>Casper Wallet</strong>”), the single sign-on and wallet SDK provided by
                Company known as CSPR.click, the API middleware platform provided by Company known
                as CSPR.cloud, the decentralized domain name services provided by Company known as
                CSPR.name, and any related mobile applications and services provided by Company in
                connection with the aforementioned, and the Sites.
              </li>
              <li>
                “<strong>Wallet</strong>” means a non-custodial digital currency wallet for storing
                the private key(s) with which one can sign Accepted Digital Currency transactions.
              </li>
              <li>
                “<strong>NFT</strong>” means a non-fungible token that uses smart contracts on a
                Digital Currency Network.
              </li>
              <li>
                <strong>“NFT Marketplace”</strong>&nbsp;means a marketplace through which You may
                buy or sell NFTs using Digital Currencies.&nbsp;
              </li>
            </ol>
            <p>
              <strong>Risk Disclosure</strong>
            </p>
            <p>
              <strong>
                Given the fact that (1) Digital Currencies are not legal tender and are not backed
                by government; (2) the nature of Digital Currencies may lead to an increased risk of
                fraud or cyberattack; and (3) the market price of Digital Currencies fluctuates
                significantly, holding and transferring Digital Currencies involve high risks and is
                therefore NOT advisable for everyone. Before using the Services provided by us, we
                recommend that you make sure you have sufficient knowledge and experience related to
                cryptocurrency and digital currency industry and that you fully understand the risks
                of holding and transferring such Digital Currencies.
              </strong>
            </p>
            <p>
              <strong>Access and Use of the Service</strong>
            </p>
            <p>
              <strong>Eligibility:&nbsp;</strong>To be eligible to use the Services: (i) you must be
              at least eighteen (18) years old and legally competent to enter into these Terms of
              Service; (ii) you must not be a resident of sanctioned jurisdictions according to any
              trade embargoes, UN Security Council Resolutions (“<strong>UNSCR</strong>”) or HM
              Treasury's financial sanctions regime; and (iii) you must not be currently the subject
              of or subject to economic sanctions such as the United Nations Security Council
              Sanctions List, the list of specially designated nationals maintained by OFAC, the
              denied persons or entity list of the U.S. Department of Commerce or any similar list
              maintained by any other relevant sanctions authority. If you are using our Services on
              behalf of a legal entity, such legal entity will be deemed to be a Party to these
              Terms of Service and you further represent and warrant that: (iv) the legal entity is
              duly organized and validly existing under the applicable laws of the jurisdiction of
              its organization; and (v) you are duly authorized by such legal entity to act on its
              behalf. You can only use our Services if permitted under the laws of your
              jurisdiction. For the avoidance of doubt, you may not use our Services if you are
              located in, or a citizen or resident of any state, country, territory, or other
              jurisdiction where your use of our Services would be illegal or otherwise violate any
              applicable laws. Please make sure that these Terms of Service are in compliance with
              all laws, rules, and regulations that apply to you. You agree that you are only using
              our Services with legally-obtained funds that rightfully belong to you. By using the
              Services, you represent and warrant that you meet all eligibility requirements that we
              outline in these Terms of Service. We may still refuse to let certain people access or
              use the Wallet, however, and we reserve the right to change our eligibility criteria
              at any time.
            </p>
            <p>
              <strong>Services:&nbsp;</strong>Upon your successful registration on the Digital
              Currency Network, the Company will provide you access to the Services.&nbsp;
            </p>
            <p>
              <strong>Wallet:</strong>&nbsp; Your Wallet allows you to (i) store keys for Accepted
              Digital Currency locally on your own devices, (ii) link to decentralized applications,
              including, without limitation, decentralized exchanges (collectively “
              <strong>Dapp(s)</strong>”),&nbsp; (iii) from the Wallet user interface, swap assets on
              a peer-to-peer basis via third-party Dapps, (iv) view addresses and information that
              are part of Digital Currency Networks and broadcast transactions, and (v) manage your
              balances of Accepted Digital Currencies on their supported Digital Currency Network.
              Your Wallet is intended solely for proper use of Accepted Digital Currencies as
              designated on the Service. Under no circumstances should you attempt to use your
              Wallet to store, send, or receive Digital Currencies that are not Accepted Digital
              Currencies. We assume no responsibility in connection with any attempt to use your
              Wallet to store, send or receive Digital Currencies that are not Accepted Digital
              Currencies.
            </p>
            <p>
              When you create a Wallet, the Wallet software generates a cryptographic private and
              public key pair that you may use to send and receive any supported Accepted Digital
              Currencies via the relevant Digital Currency Network.&nbsp;
            </p>
            <p>
              <strong>
                YOU MUST STORE, OUTSIDE OF THE SERVICES, A BACKUP OF ALL WALLET CREDENTIALS,
                INCLUDING YOUR PASSPHRASES, IDENTIFIERS, BACKUP PHRASES, PRIVATE KEYS AND NETWORK
                ADDRESSES
              </strong>
              . If you do not maintain a backup of your Wallet data outside of the Services, you
              will not be able to access Digital Currency previously accessed using your Wallet in
              the event that we discontinue or no longer offer some or all of the Services or may
              otherwise lose access to Digital Currency. We are not responsible for maintaining this
              data on your behalf.
            </p>
            <p>
              The Service allows you to send Accepted Digital Currency to, and request, receive, and
              store Accepted Digital Currency from, third parties. Your transfer of Accepted Digital
              Currencies between your other digital currency wallets (including wallets off the
              Service) and to and from third parties is a “
              <strong>Digital Currency Transfer</strong>.” The Service allows you or a third party
              to send Digital Currency to your Wallet from an external wallet (“
              <strong>Inbound Transfers</strong>”). The Service also allows you to send Digital
              Currency from your Wallet to an external wallet (“<strong>Outbound Transfers</strong>
              ”). Whenever you initiate an Inbound Transfer or Outbound Transfer to or from your
              Wallet, you must cryptographically sign the applicable transfer with your private key.
              Digital Currency Transfers cannot be reversed once they have been broadcast to the
              relevant Digital Currency Network, although they may be in a pending state, and
              designated accordingly, while the transaction is processed by network operators.
              Company does not control any blockchain or Digital Currency Network and makes no
              guarantees that a Digital Currency Transfer will be confirmed by a Digital Currency
              Network.&nbsp;&nbsp;
            </p>
            <p>
              <strong>Password and Security:&nbsp;</strong>You are responsible for maintaining the
              confidentiality of your private keys and any other credentials, if any, and are fully
              responsible for any and all activities that occur with respect to your Wallet, whether
              or not you know about them, and you are solely responsible for your conduct, and the
              tasks and activities you undertake, on or utilizing the Sites or Services. Company
              will not be liable for any loss or damage arising from your failure to comply with
              this Section.
            </p>
            <p>
              You acknowledge and understand that, in certain circumstances, such as if you lose or
              forget your password for your Wallet, you will need to use a recovery phrase to access
              any Digital Currency stored in your wallet (the "<strong>Recovery Phrase</strong>").
              You are solely responsible for the retention and security of your Recovery Phrase.
              Your Recovery Phrase is the only way to restore access to the Digital Currency stored
              in your Wallet if you lose access to your Wallet. Anyone who knows your Recovery
              Phrase can access, transfer or spend your Digital Currency. If you lose your Recovery
              Phrase, you may not be able to access, transfer or spend your Digital Currency. You
              acknowledge and agree that Company is not responsible in any way for the security of
              your Recovery Phrase, and you agree to hold Company, its affiliates, representatives,
              agents and personnel harmless and that no such party shall be liable in any way in the
              event you lose your Recovery Phrase and cannot access, transfer or spend your Digital
              Currency. You bear sole responsibility for any loss of your cryptocurrency due to
              failure to retain and/or secure your Recovery Phrase.
            </p>
            <p>
              <strong>Modifications to Service:&nbsp;</strong>Company reserves the right to modify
              or discontinue, temporarily or permanently, the Service (or any part thereof) at any
              time with or without notice. You agree that Company will not be liable to you or to
              any third party for any modification, suspension, or discontinuance of the Service. If
              Company decides to discontinue Service, it will publicly announce its intention to do
              so providing a minimum of 30 days’ notice using public channels including the Sites.
            </p>
            <p>
              <strong>General Practices Regarding Use and Storage:</strong>&nbsp;You acknowledge
              that Company may establish general practices and limits concerning the use of the
              Service, including without limitation the maximum period of time that data or other
              content will be retained by the Service and the maximum storage space that will be
              allotted on Company’s servers on your behalf. You agree that Company has no
              responsibility or liability for the deletion or failure to store any data or other
              content maintained or uploaded by the Service. You further acknowledge that Company
              reserves the right to change these general practices and limits at any time, in its
              sole discretion, with or without notice.
            </p>
            <p>
              <strong>Mobile Services:&nbsp;</strong>The Service includes certain services that are
              available via a mobile device, including (i) the ability to upload content to the
              Service via a mobile device, (ii) the ability to browse the Service and the Sites from
              a mobile device and (iii) the ability to access certain features through an
              application downloaded and installed on a mobile device (collectively, the “
              <strong>Mobile Services</strong>”). To the extent you access the Service through a
              mobile device, your wireless service carrier’s standard charges, data rates and other
              fees may apply. In addition, downloading, installing, or using certain Mobile Services
              may be prohibited or restricted by your carrier, and not all Mobile Services may work
              with all carriers or devices.
            </p>
            <p>
              <strong>Block Explorer:</strong>&nbsp;Block Explorer is intended to permit our users
              to access data and analytics regarding the Digital Currency Networks supported by
              Block Explorer.&nbsp; Block Explorer is provided for informational purposes only and
              no data, analytics, or information accessible in connection with Block Explorer
              (collectively, “<strong>BE Data</strong>”) is financial advice. Subject to your
              compliance with the terms and conditions of this Agreement, Company hereby grants you
              a limited, non-exclusive, non-transferable, non-sublicensable right to use the BE Data
              for internal, non-commercial business purposes only.&nbsp;
              <strong>
                <em>
                  All BE Data is provided “AS IS”, and you (i) acknowledge and agree that Company is
                  not responsible or liable for the accuracy or completeness of any BE Data, and
                  (ii) shall be responsible and liable for all use of the BE Data.
                </em>
              </strong>
            </p>
            <p>
              <strong>Transfer of NFTs; License Rights to NFT Content</strong>
            </p>
            <p>
              <strong>NFTs:</strong>&nbsp;The Service provides functionality to enable users,
              including artists or creators (“<strong>Creators</strong>”) and NFT owners (“
              <strong>NFT Owners</strong>”) to transfer ownership of NFTs, including to sell,
              purchase, list for auction, make offers on, and bid on NFTs (each a “
              <strong>Transaction</strong>”). Each NFT is a non-fungible token that uses smart
              contracts on one or more Digital Currency Networks (“<strong>Smart Contracts</strong>
              ”). A Digital Currency Network provides an immutable ledger of all Transactions that
              occur on that Digital Currency Network. This means that all NFTs are outside of the
              control of any one party, including Company, and are subject to many risks and
              uncertainties. We neither own nor control any Digital Currency Network, your browser,
              or any other third-party site, product, or service (including third-party wallets or
              marketplaces) that you might access, visit, or use for the purpose of enabling you to
              use the Service or to engage in a Transaction. Except with respect to transferring
              control of a NFT to the initial transferee through the Service (“
              <strong>Initial Transferee</strong>”), Company has no responsibility with respect to
              any Transaction. Company will not be liable for the acts or omissions of any third
              parties, nor will Company be liable for any damage that you may suffer as a result of
              your Transactions or any other interaction with any third parties. You understand that
              your Digital Currency Network public address will be made publicly visible whenever
              you engage in a Transaction. Aside from transferring control of a NFT to the Initial
              Transferee, Company has no control over the transfer, storage, ownership, or
              maintenance of such NFT.
            </p>
            <p>‍</p>
            <p>
              <strong>Noncustodial:</strong>&nbsp;While Company offers a NFT Marketplace, it does
              not buy, sell, or ever take custody or possession of any NFTs. The Services facilitate
              collection of NFTs, but neither Company nor the Services are custodians of any NFTs.
              You understand and acknowledge that the Smart Contracts do not give Company custody,
              possession, or control of any user’s NFTs or cryptocurrency at any time for the
              purpose of facilitating transactions on the Services. You affirm that you are aware
              and acknowledge that Company is a non-custodial service provider and has designed the
              Services to be directly accessible by its users without any involvement or actions
              taken by Company or any third-party. Company facilitates transactions between the
              users of the Services but is not a party to any agreement between any sellers, buyers,
              Creators, NFT Owners or other users. As a marketplace, Company cannot make any
              representation or guarantee that Creators, NFT Owners or other will achieve any
              particular outcome as the result of listing their NFTs or engaging in any other
              Transaction on the Services.
            </p>
            <p>‍</p>
            <p>
              <strong>Initial Transfers:</strong>&nbsp;When each NFT is transferred for the first
              time, the agreement for the transfer is between Company and the Initial Transferee. If
              the Initial Transferee decides to transfer a NFT (“<strong>Secondary Transfer</strong>
              ”), then Company is not a party to any agreement between the applicable buyer, seller,
              or facilitator of the Secondary Transfer. If you or any owner of a NFT sells or
              transfers a NFT to another user, (a) then you, as a NFT Owner, represent and warrant
              that you will notify the subsequent owner of these Terms of Service and require the
              subsequent owner to comply with these Terms of Service, (b) the Applicable License (as
              defined below) will automatically transfer to such subsequent owner, and such other
              owner will be deemed the NFT Owner (for purposes of such NFT and the Applicable
              License to the underlying Art Content) and will be subject to these Terms of Service,
              and (c) you, as the seller or transferor of such NFT, will cease to have any further
              rights to such NFT or underlying NFT Content.
            </p>
            <p>
              By placing an order to purchase a NFT on the Sites or through the Services (including
              by bidding in an auction), you agree that you are submitting a binding offer to
              purchase a NFT, you agree to pay all applicable fees associated with the Transaction,
              and you authorize Company to automatically charge and collect such fees from your
              payment instrument or Wallet. If you are an Initial Transferee, then all amounts due
              are to be paid through the Smart Contract, of which the price of the NFT shall be paid
              to the transferor and applicable fees shall be paid to Company. If you are not the
              Initial Transferee of a NFT, then amounts may be paid to the seller of such NFT.
            </p>
            <p>
              <strong>Secondary Transfer:</strong>&nbsp;Company reserves the right to collect a
              percentage of any Secondary Transfer of a NFT (“<strong>Royalty</strong>”), whether on
              the Services via the NFT Marketplace or otherwise, in perpetuity. The applicable
              Royalty will be displayed at the time of initial transfer, or through other reasonable
              means. If a Royalty for Secondary Transfer applies, you agree to notify actual and
              potential buyers of the applicable NFT of such Royalty and not attempt to circumvent
              such Royalty. No refunds are permitted except with respect to any statutory warranties
              or guaranties that cannot be excluded or limited by law.&nbsp;
            </p>
            <p>
              <strong>Taxes:</strong>&nbsp;You are responsible for any and all transfers, sales,
              use, value-added and other taxes, duties, and assessments now or hereafter claimed or
              imposed by any governmental authority, associated with your use of the Services or
              NFTs (including, without limitation, any taxes that may become payable as the result
              of your ownership, transfer, purchase, or sale of a NFT).
            </p>
            <p>
              <strong>Creator Rights and Obligations:</strong>&nbsp;The Creator owns all legal
              rights, title, and interest in all intellectual property rights in the content
              underlying the NFTs minted on the Services (such underlying content, the “
              <strong>NFT Content</strong>”), including but not limited to copyrights and trademarks
              in the NFT Content, unless the Creator expressly transfers any such right, title or
              interest to a NFT Owner or other third party. As the copyright owner, the Creator has
              the right to reproduce, prepare derivatives of, distribute, and display or perform the
              NFT Content. By launching any NFTs on the Services, the Creator hereby expressly and
              affirmatively grants to the NFT Owner and, to the extent applicable, any subsequent
              NFT Owner in a secondary sale the Applicable License as described below, unless the
              Creator expressly grants different rights to the NFT Owner.
            </p>
            <p>‍</p>
            <p>
              Creator expressly represents and warrants that its NFT Content underlying any NFTs
              transferred on the Services contain only original content otherwise authorized for use
              by the Creator, and do not contain unlicensed or unauthorized copyrighted content,
              including any imagery, design, audio, video, human likeness, or other unoriginal
              content not created by the Creator, not authorized for use by the Creator, not in the
              public domain, or otherwise without a valid claim of fair use. The Creator further
              represents and warrants that it has permission to incorporate the unoriginal content,
              to the extent such permission is legally required.&nbsp;
            </p>
            <p>‍</p>
            <p>
              The Creator hereby acknowledges, understands, and agrees that by launching, listing,
              or selling any NFTs on the Services, the Creator hereby expressly and affirmatively
              grants to Company a non-exclusive, world-wide, transferable, sublicensable, perpetual,
              irrevocable, and royalty-free license to (a) reproduce, display, perform, distribute
              and transmit the NFT Content underlying such NFTs for the purpose of operating and
              developing the Services, and (b) use and incorporate the NFT Content underlying such
              NFTs, or derivative works thereof, on any marketing materials, and to reproduce,
              display, perform, display and transmit such marketing materials on any media whether
              now known or later discovered for the purposes of operating, promoting, sharing,
              marketing, and advertising the Services. The foregoing licenses include, without
              limitation, the express rights to: (i) display or perform the NFT Content underlying
              such NFTs on the Services, a third-party platform, social media posts, blogs,
              editorials, advertising, market reports, virtual galleries, museums, virtual
              environments, editorials, or to the public; (ii) index the NFT Content in electronic
              databases, indexes, and catalogs; and (iii) host, store, distribute, and reproduce one
              or more copies of such NFT Content within a distributed file keeping system, node
              cluster, or other database (e.g., IPFS) or cause, direct, or solicit others to do
              so.&nbsp;&nbsp;
            </p>
            <p>‍</p>
            <p>
              <strong>NFT Owner Rights to Underlying NFT Content:</strong>&nbsp;The NFT Owner
              receives a cryptographic token representing the Creator’s NFT Content as a piece of
              property, but does not own the NFT Content itself or any intellectual property rights
              therein. The NFT Owner may display and share the NFT Content, but the NFT Owner does
              not have any legal ownership, right, or title to any copyrights, trademarks, or other
              intellectual property rights to the NFT Content, except the limited license to the NFT
              Content granted by these Terms of Service.
            </p>
            <p>
              Upon legally collecting and obtaining ownership of any NFTs transferred on the
              Services, unless the Creator expressly grants different rights to the NFT Owner as
              indicated at the time of sale on the Sites for such NFTs, the NFT Owner receives from
              the seller of such NFTs a limited, worldwide, non-assignable and non-transferable
              (except as expressly set forth below), non-sublicensable, royalty-free license to
              display the NFT Content underlying such NFTs solely for the NFT Owner’s non-commercial
              purposes, including the right to display such NFT Content privately or publicly: (i)
              for the purpose of promoting or sharing the NFT Owner’s purchase of, ownership of, or
              interest in such NFTs, (ii) for the purpose of sharing, promoting, discussing, or
              commenting on such NFT Content; (iii) on third party marketplaces, exchanges,
              platforms, or applications in association with an offer to sell, or trade, the NFTs;
              and (iv) within decentralized virtual environments, virtual worlds, virtual galleries,
              virtual museums, or other navigable and perceivable virtual environments.&nbsp; For
              purposes hereof, for each NFT, “<strong>Applicable License</strong>” means the
              foregoing license or such other license that is indicated at the time of sale on the
              Sites for such NFT. Upon any sale or transfer of a NFT, (a) the Applicable License
              will automatically transfer to the subsequent owner of such NFT, and such other owner
              will be deemed the “<strong>NFT Owner</strong>” and licensee (for purposes of such NFT
              and the Applicable License to the underlying Art) and will be subject to these Terms
              of Service and (b) the seller or transferor of such NFT will cease to have any further
              rights to such NFT Content with respect to such NFT.
            </p>
            <p>
              <strong>Certain Restrictions; Release:</strong>&nbsp;As a NFT Owner, you agree that
              you will not, and will not permit any third party to, do or attempt to do any of the
              foregoing without Company’s express prior written consent in each case or except as
              expressly permitted by the Applicable License: (i) modify, distort, mutilate, or
              perform any other modification to the NFT Content which would be prejudicial to
              Company’s or the Artist’s honor or reputation; (ii) use the NFT Content to advertise,
              market, or sell any third party product or service; (iii) use the NFT Content in
              connection with images, videos, or other forms of media that depict hatred,
              intolerance, violence, cruelty, or anything else that could reasonably be found to
              constitute hate speech or otherwise infringe upon the rights of others; (iv)
              incorporate the NFT Content in movies, videos, video games, or any other forms of
              media for a commercial purpose; (v) sell, distribute for commercial gain, or otherwise
              commercialize merchandise that includes, contains, or consists of the NFT Content;
              (vi) attempt to trademark, copyright, or otherwise acquire additional intellectual
              property rights in or to the NFT Content; (vii) attempt to mint, tokenize, or create
              an additional cryptographic token representing your NFT or underlying NFT Content,
              whether on or off of the Service; (viii) falsify, misrepresent, or conceal the
              authorship of the NFT Content; or (ix) otherwise utilize any NFT Content for your or
              any third party’s commercial benefit. You irrevocably release, acquit and forever
              discharge Company of any liability for direct or indirect copyright or trademark
              infringement for their use of any NFTs or underlying NFT Content.
            </p>
            <p>
              <strong>Conditions of Use:</strong>
            </p>
            <p>
              <strong>Your Responsibilities:&nbsp;</strong>You control the Digital Currencies held
              in your Wallet. Title to the Digital Currency stored in your Wallet (if any) shall at
              all times remain with you and shall not transfer to us. As the owner of Digital
              Currency in your Wallet, you are solely responsible for your activity and shall bear
              all risk of loss of such Digital Currency at all times. You are solely in control of
              and responsible for the retention and security of your Digital Currencies, private
              keys, and Recovery Phrase. You must keep your wallet address, Recovery Phrase, and
              private key access information secure. You control the Digital Currencies held in your
              Wallet by controlling your private key. It is very important that you back up your
              private keys, Recovery Phrase, and passwords. Failure to do so may result in the loss
              of control of Digital Currencies associated with your wallet. We cannot generate a new
              password for your wallet if you fail to remember your original password. If you have
              not safely stored a backup of any wallet address and private key pairs maintained in
              your wallet, you accept and acknowledge that any Digital Currencies you have
              associated with such wallet address will become inaccessible. Accordingly, we shall
              have no responsibility or liability whatsoever in the event you are unable to access
              your wallet for any reason including without limitation your failure to keep your
              wallet address, Recovery Phrase and private key information secure.
            </p>
            <p>
              We shall have no liability for any price or value fluctuations of the Digital
              Currencies stored in your Wallet. None of the Digital Currencies in your Wallet are
              the property of the Company, or are loaned to the Company and you shall have no right
              to receive any “interest” from the Company with respect to the Digital Currencies
              stored in your Wallet.
            </p>
            <p>
              The person initiating the Inbound Transfer is solely responsible for executing the
              transaction properly, which may include, among other things, payment of sufficient
              network or miner’s fees in order for the transaction to be successful. Insufficient
              network fees may cause an Inbound Transfer to remain in a pending state and may result
              in delays or losses incurred as a result of an error in the initiation of the
              transaction. The Company will have no obligation to assist in the remediation of such
              transactions. Any such Outbound Transfers are executed on chain by you and not under
              the control of the Company in any way. You should verify all transaction information
              prior to submitting instructions to us. The Company shall bear no liability or
              responsibility in the event you enter an incorrect blockchain destination address.
              Digital Currency Transfers cannot be reversed once they have been broadcast to the
              relevant Digital Currency Network. The Company does not control the Digital Currency
              Network and makes no guarantees that a Digital Currency Transfer will be confirmed by
              the Digital Currency Network. All losses that result from any mistakes in the
              transaction information for Digital Currencies will be your responsibility and the
              Company will bear no liability for any such losses. You understand that Digital
              Currency Transfers cannot be reversed once they have been broadcast to the relevant
              Digital Currency Network. You understand that once a Digital Currency Transfer is
              submitted to a Digital Currency Network, the transaction will be unconfirmed and not
              completed and remain in a pending state until confirmed by the Digital Currency
              Network. You understand that we do not control the Digital Currency Network and we do
              not guarantee that a Digital Currency Transfer will be confirmed by the Digital
              Currency Network.
            </p>
            <p>
              <strong>User Conduct:&nbsp;</strong>If Company offers the ability to upload any User
              Content, this section will apply to your use of the Services. You are solely
              responsible for all code, video, images, information, data, text, software, music,
              sound, photographs, graphics, messages, or other materials (“content”) that you
              upload, post, publish or display (hereinafter, “upload”) or email or otherwise use via
              the Service. The following are examples of the kind of content and/or use that is
              illegal or prohibited by Company. Company reserves the right to investigate and take
              appropriate legal action against anyone who, in Company’s sole discretion, violates
              this provision, including without limitation, removing the offending content from the
              Service, and reporting you to the law enforcement authorities. You agree to not use
              the Service to:
            </p>
            <ol role="list">
              <li>
                email or otherwise upload any content that (i) infringes any intellectual property
                or other proprietary rights of any party; (ii) you do not have a right to upload
                under any law or under contractual or fiduciary relationships; (iii) contains
                software viruses or any other computer code, files or programs designed to
                interrupt, destroy or limit the functionality of any computer software or hardware
                or telecommunications equipment; (iv) poses or creates a privacy or security risk to
                any person; (v) constitutes unsolicited or unauthorized advertising, promotional
                materials, commercial activities and/or sales, “junk mail,” “spam,” “chain letters,”
                “pyramid schemes,” “contests,” “sweepstakes,” or any other form of solicitation;
                (vi) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively
                violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s
                privacy, hateful racially, ethnically or otherwise objectionable; or (vii) in the
                sole judgment of Company, is objectionable or which restricts or inhibits any other
                person from using or enjoying the Service, or which may expose Company or its users
                to any harm or liability of any type;
              </li>
              <li>
                interfere with or disrupt the Service or servers or networks connected to the
                Service, or disobey any requirements, procedures, policies, or regulations of
                networks connected to the Service; or
              </li>
              <li>
                violate any applicable local, state, national or international law, or any
                regulations having the force of law;
              </li>
              <li>
                impersonate any person or entity, or falsely state or otherwise misrepresent your
                affiliation with a person or entity;
              </li>
              <li>solicit personal information from anyone under the age of 18;</li>
              <li>
                harvest or collect email addresses or other contact information of other users from
                the Service by electronic or other means for the purposes of sending unsolicited
                emails or other unsolicited communications;
              </li>
              <li>
                advertise or offer to sell or buy any goods or services for any business purpose
                that is not specifically authorized;
              </li>
              <li>
                further or promote any criminal activity or enterprise or provide instructional
                information about illegal activities;&nbsp;
              </li>
              <li>
                obtain or attempt to access or otherwise obtain any materials or information through
                any means not intentionally made available or provided for through the Service;
              </li>
              <li>
                create user accounts by automated means or under false or fraudulent pretenses;
              </li>
              <li>
                access or use the Services to carry out financial activities subject to registration
                or licensing, including but not limited to creating, listing, or buying securities,
                commodities, options, real estate, or debt instruments;
              </li>
              <li>
                access or use the Services to participate in fundraising for a business, protocol,
                or platform, including but not limited to creating, listing, or buying assets that
                are redeemable for financial instruments, assets that give rights to participate in
                an ICO or any securities offering, or assets that entitle financial rewards,
                including but not limited to, DeFi yield bonuses and burn discounts, provided that
                the foregoing will not restrict the legal use of any proceeds resulting from your
                permitted use of the Services;
              </li>
              <li>
                access or use the Services for the purpose of creating a product or service that is
                competitive with any of our products or services; or
              </li>
              <li>
                distribute the raw data/endpoints to other departments even in the same company,
                unless you have obtained prior written consent from Company.
              </li>
            </ol>
            <p>
              <strong>Fees:&nbsp;</strong>To the extent the Service or any portion thereof is made
              available for any fee, you may be required to select a payment plan and provide
              information regarding your credit card or other payment instruments. You represent and
              warrant to Company that such information is true and that you are authorized to use
              the payment instrument.
            </p>
            <p>
              <strong>Commercial Use:&nbsp;</strong>Unless otherwise expressly authorized herein or
              in the Service, you agree not to display, distribute, license, perform, publish,
              reproduce, duplicate, copy, create derivative works from, modify, sell, resell,
              exploit, transfer or upload for any commercial purposes, any portion of the Service
              (including BE Data), use of the Service, or access to the Service. The Service is for
              your personal use.
            </p>
            <p>
              <strong>Third-Party Distribution Channels:&nbsp;</strong>Company offers Software that
              may be made available through the Apple App Store, the Google Play Store, or other
              distribution channels (“<strong>Distribution Channels</strong>”). If you obtain such
              Software through a Distribution Channel, you may be subject to additional terms of the
              Distribution Channel. These Terms of Service are between you and us only, and not with
              the Distribution Channel. To the extent that you utilize any other third-party
              products and services in connection with your use of the Service, you agree to comply
              with all applicable terms of any agreement for such third-party products and services.
            </p>
            <p>
              <strong>
                <em>Apple-Enabled Software:&nbsp;</em>
              </strong>
              With respect to Mobile Apps that are made available for your use in connection with an
              Apple-branded product (the “<strong>Apple-Enabled Software</strong>”), in addition to
              the other terms and conditions set forth in these Terms of Service, the following
              terms and conditions apply:
            </p>
            <ul role="list">
              <li>
                Company and you acknowledge that these Terms of Service are concluded between
                Company and you only, and not with Apple Inc. (“Apple”), and that as between Company
                and Apple, Company, not Apple, is solely responsible for the Apple-Enabled Software
                and the content thereof.
              </li>
              <li>
                You may not use the Apple-Enabled Software in any manner that is in violation of or
                inconsistent with the Usage Rules set forth for Apple-Enabled Software in, or
                otherwise be in conflict with, the Apple Media Services Terms and Conditions.
              </li>
              <li>
                Your license to use the Apple-Enabled Software is limited to a non-transferable
                license to use the Apple-Enabled Software on an iOS product that you own or control,
                as permitted by the “Usage Rules” set forth in the Apple Media Services Terms and
                Conditions, except that such Apple-Enabled Software may be accessed and used by
                other accounts associated with the purchaser via Apple’s Family Sharing or volume
                purchasing programs.
              </li>
              <li>
                Apple has no obligation whatsoever to provide any maintenance or support services
                with respect to the Apple-Enabled Software.
              </li>
              <li>
                Apple is not responsible for any product warranties, whether expressed or implied by
                law. In the event of any failure of the Apple-Enabled Software to conform to any
                applicable warranty, you may notify Apple, and Apple will refund the purchase price
                for the Apple-Enabled Software, if any, to you; and, to the maximum extent permitted
                by applicable law, Apple will have no other warranty obligation whatsoever with
                respect to the Apple-Enabled Software, or any other claims, losses, liabilities,
                damages, costs, or expenses attributable to any failure to conform to any warranty,
                which will be Company’s sole responsibility, to the extent it cannot be disclaimed
                under applicable law.
              </li>
              <li>
                Company and you acknowledge that Company, not Apple, is responsible for addressing
                any claims of you or any third party relating to the Apple-Enabled Software or your
                possession and/or use of that Apple-Enabled Software, including: (a) product
                liability claims; (b) any claim that the Apple-Enabled Software fails to conform to
                any applicable legal or regulatory requirement; and (c) claims arising under
                consumer protection, privacy, or similar legislation.
              </li>
              <li>
                In the event of any third-party claim that the Apple-Enabled Software or your
                possession and use of that Apple-Enabled Software infringes that third party’s
                intellectual property rights, as between Company and Apple, Company, not Apple, will
                be solely responsible for the investigation, defense, settlement, and discharge of
                any such intellectual property infringement claim.
              </li>
              <li>
                You represent and warrant that (a) you are not located in a country that is subject
                to a U.S. Government embargo, or that has been designated by the U.S. Government as
                a “terrorist supporting” country; and (b) you are not listed on any U.S. Government
                list of prohibited or restricted parties.
              </li>
              <li>
                If you have any questions, complaints, or claims with respect to the Apple-Enabled
                Software, they should be directed to Company as follows:
              </li>
            </ul>
            <p>Email Address: hello@CSPR.live</p>
            <p>Mailing Address: 30 Gould St, Suite R, Sheridan, WY 82801</p>
            <ul role="list">
              <li>
                You must comply with applicable third-party terms of agreement when using the
                Apple-Enabled Software,&nbsp;<em>e.g.</em>, your wireless data service agreement.
              </li>
              <li>
                Company and you acknowledge and agree that Apple, and Apple’s subsidiaries, are
                third-party beneficiaries of these Terms of Service with respect to the
                Apple-Enabled Software, and that, upon your acceptance of the terms and conditions
                of these Terms of Service, Apple will have the right (and will be deemed to have
                accepted the right) to enforce these Terms of Service against you with respect to
                the Apple-Enabled Software as a third-party beneficiary thereof.
              </li>
            </ul>
            <p>
              <strong>
                <em>Google-Sourced Software</em>:&nbsp;
              </strong>
              The following applies to any Mobile App you download from the Google Play Store (“
              <strong>Google-Sourced Software</strong>”): (a) you acknowledge that these Terms of
              Service are between you and Company only, and not with Google, Inc. (“Google”); (b)
              your use of Google-Sourced Software must comply with Google’s then-current Google Play
              Terms of Service; (c) Google is only a provider of Google Play where you obtained the
              Google-Sourced Software; (d) Company, and not Google, is solely responsible for
              Company’s Google-Sourced Software; (e) Google has no obligation or liability to you
              with respect to Google-Sourced Software or these Terms of Service; and (f) you
              acknowledge and agree that Google is a third-party beneficiary to these Terms of
              Service as it relates to Company’s Google-Sourced Software.
            </p>
            <p>
              <strong>Intellectual Property Rights</strong>
            </p>
            <p>
              <strong>Service Content, Software and Trademarks:&nbsp;</strong>Company and its
              licensors exclusively own all right, title and interest in and to the Sites and the
              Services. You acknowledge that the Sites and Services are protected by copyright,
              trademark, and other laws of the United States and foreign countries. You agree not to
              remove, alter, or obscure any copyright, trademark, service mark or other proprietary
              rights notices incorporated in or accompanying the Services.&nbsp;
            </p>
            <p>
              You acknowledge and agree that the Service may contain content or features (“
              <strong>Service Content</strong>”) that are protected by copyright, patent, trademark,
              trade secret or other proprietary rights and laws. Except as expressly authorized by
              Company, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell,
              distribute, or create derivative works based on the Service or the Service Content, in
              whole or in part, except that the foregoing does not apply to your own User Content
              (as defined below) that you legally upload to the Service. In connection with your use
              of the Service you will not engage in or use any data mining, robots, scraping or
              similar data gathering or extraction methods. If you are blocked by Company from
              accessing the Service (including by blocking your IP address), you agree not to
              implement any measures to circumvent such blocking (e.g., by masking your IP address
              or using a proxy IP address). Any use of the Service or the Service Content other than
              as specifically authorized herein is strictly prohibited. The technology and software
              underlying the Service or distributed in connection therewith are the property of
              Company, our affiliates, and our partners (the “<strong>Software</strong>”). You agree
              not to copy, modify, create a derivative work of, reverse engineer, reverse assemble
              or otherwise attempt to discover any source code, sell, assign, sublicense, or
              otherwise transfer any right in the Software. Any rights not expressly granted herein
              are reserved by Company. You agree not to access or use the Services in a way intended
              to avoid incurring fees or exceeding usage limits or quotas, or to resell or
              sublicense the Services, or use the Services to provide software as a service or any
              cloud-based, time sharing, service bureau or other services.
            </p>
            <p>
              The Company name and logos are trademarks and service marks of Company (collectively
              the “<strong>Company Trademarks</strong>”). Other company, product, and service names
              and logos used and displayed via the Service may be trademarks or service marks of
              their respective owners who may or may not endorse or be affiliated with or connected
              to Company. Nothing in this Terms of Service or the Service should be construed as
              granting, by implication, estoppel, or otherwise, any license or right to use any of
              Company Trademarks displayed on the Service, without our prior written permission in
              each instance. All goodwill generated from the use of Company Trademarks will inure to
              our exclusive benefit.
            </p>
            <p>
              <strong>Open Source Software</strong>: The Software may contain or be provided
              together with open source software. Each item of open source software is subject to
              its own license terms, which can be found at: https://github.com/make-software. If
              required by any license for particular open source software, Company makes such open
              source software, and Company’s modifications to that open source software (if any),
              available by written request to hello@CSPR.live. Copyrights to the open source
              software are held by the respective copyright holders indicated therein.
            </p>
            <p>
              <strong>Third Party Material:&nbsp;</strong>Under no circumstances will Company be
              liable in any way for any content or materials of any third parties (including users),
              including, but not limited to, for any errors or omissions in any content, or for any
              loss or damage of any kind incurred as a result of the use of any such content. You
              acknowledge that Company does not pre-screen content, but that Company and its
              designees will have the right (but not the obligation) in their sole discretion to
              refuse or remove any content that is available via the Service. Without limiting the
              foregoing, Company and its designees will have the right to remove any content that
              violates these Terms of Service or is deemed by Company, in its sole discretion, to be
              otherwise objectionable. You agree that you must evaluate, and bear all risks
              associated with, the use of any content, including any reliance on the accuracy,
              completeness, or usefulness of such content.
            </p>
            <p>
              <strong>User Content Transmitted Through the Service:&nbsp;</strong>With respect to
              the content or other materials you upload through the Service or share with other
              users or recipients (collectively, “<strong>User Content</strong>”), you represent and
              warrant that you own all right, title and interest in and to such User Content,
              including, without limitation, all copyrights and rights of publicity contained
              therein. By uploading any User Content you hereby grant and will grant Company and its
              affiliated companies a nonexclusive, worldwide, royalty free, fully paid up,
              transferable, sublicensable, perpetual, irrevocable license to copy, display, upload,
              perform, distribute, store, modify and otherwise use your User Content in connection
              with the operation of the Service or the promotion, advertising or marketing thereof
              any form, medium or technology now known or later developed, provided that the
              foregoing license does not apply to NFT Content (which is addressed above).
            </p>
            <p>
              You acknowledge and agree that any questions, comments, suggestions, ideas, feedback
              or other information about the Service (“<strong>Submissions</strong>”), provided by
              you to Company are non-confidential and Company will be entitled to the unrestricted
              use and dissemination of these Submissions for any purpose, commercial or otherwise,
              without acknowledgment or compensation to you.
            </p>
            <p>
              You acknowledge and agree that Company may preserve content and may also disclose
              content if required to do so by law or in the good faith belief that such preservation
              or disclosure is reasonably necessary to: (a) comply with legal process, applicable
              laws or government requests; (b) enforce these Terms of Service; (c) respond to claims
              that any content violates the rights of third parties; or (d) protect the rights,
              property, or personal safety of Company, its users and the public. You understand that
              the technical processing and transmission of the Service, including your content, may
              involve (a) transmissions over various networks; and (b) changes to conform and adapt
              to technical requirements of connecting networks or devices. You acknowledge that, in
              certain instances, where you have removed your User Content by specifically deleting
              it, some of your User Content (such as posts or comments you make) may not be
              completely removed and copies of your User Content may continue to exist on the
              Services. We are not responsible or liable for the removal or deletion of (or the
              failure to remove or delete) any of your User Content.
            </p>
            <p>
              <strong>Acceptable Use and Enforcement Rights</strong>
            </p>
            <p>
              As a condition to using the Sites and/or Services, you agree not to use the Sites or
              Services in ways that:
            </p>
            <ul role="list">
              <li>
                &nbsp;Reverse engineer, dissemble, or decompile any part of the Services.&nbsp;
              </li>
              <li>
                Send or store malicious code in connection with the Services or otherwise interfere
                with or disrupt performance of the Services,&nbsp;
              </li>
              <li>
                Use manual or automated tools to scan or probe the Services in order to determine
                vulnerabilities, or&nbsp;
              </li>
              <li>
                Attempt to gain access to the Services or its related systems or networks in a
                manner inconsistent with the permitted use of the Service.&nbsp;
              </li>
              <li>
                Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any
                technological measure implemented by us or any of our service providers or any other
                third party to protect the Services or Content;
              </li>
              <li>Disguise your location through IP proxying or other methods;</li>
              <li>
                Interfere with, or attempt to interfere with, the access to the Services of any
                user, host or network, including, without limitation, sending a virus, overloading,
                flooding, spamming, or mail-bombing the Services;
              </li>
              <li>
                Circumvent any content-filtering techniques, security measures or access controls
                that the Company employs on the Sites or the Services in any manner;
              </li>
              <li>
                Interfere with, disrupt, negatively affect or inhibit other users from enjoying the
                Services, or that could damage, disable, overburden, or impair the functioning of
                the Sites or Services;
              </li>
              <li>
                Violate any applicable law or regulation, including, without limitation any
                applicable anti-money laundering and anti-terrorism financing laws and sanctions
                programs, including, without limitation, the Bank Secrecy Act and those enforced by
                the U.S. Department of Treasury's Office of Foreign Assets Controls and any other
                Export Control Laws;
              </li>
              <li>Encourage or enable any other individual to do any of the foregoing.</li>
            </ul>
            <p>By using the Sites or Services, you further represent, warrant and covenant that:</p>
            <ul role="list">
              <li>
                Any Digital Currency, NFTs or other digital assets (collectively, “
                <strong>Digital Assets</strong>”) you transfer via the Services have been legally
                obtained by, and belong to, you;
              </li>
              <li>
                You will not provide any false, inaccurate or misleading information while using the
                Sites or Services, or engage in any activity that operates to defraud Company, other
                users of the Services, or any other person or entity;
              </li>
              <li>
                You will not use the Services to transmit or exchange Digital Assets that are the
                direct or indirect proceeds of any criminal or fraudulent activity, including,
                without limitation, terrorism or tax evasion;
              </li>
              <li>
                Any Digital Assets you use in connection with the Services are either owned by you
                or you are validly authorized to carry out actions using such assets; and
              </li>
              <li>
                You will pay all fees necessary for interacting with the blockchain, or any other
                network with which the Services are compatible, including "gas" costs, as well as
                all fees charged by us for your use of the Services.
              </li>
            </ul>
            <p>
              You shall bear the risk of loss for, and assume all liability arising from, any
              unauthorized or fraudulent usage of Services (or any hardware or software component
              thereof). Company reserves the right, but is not required, to take any and all action
              it deems appropriate, including, without limitation, blocking access to geographic
              areas or suspending access to Services (or any hardware or software component
              thereof), in order to prevent or terminate any fraud, abuse or illegal use of or
              activities in connection with the Services or any other breach of this section,
              provided, however, that any such action by Company shall be consistent with applicable
              laws, rules, and regulations.
            </p>
            <p>
              <strong>Third Party Websites</strong>
            </p>
            <p>
              The Service may provide, or third parties may provide, links or other access to other
              sites and resources on the Internet. Company has no control over such sites and
              resources and Company is not responsible for and does not endorse such sites and
              resources. You further acknowledge and agree that Company will not be responsible or
              liable, directly or indirectly, for any damage or loss caused or alleged to be caused
              by or in connection with use of or reliance on any content, events, goods or services
              available on or through any such site or resource. Any dealings you have with third
              parties found while using the Service are between you and the third party, and you
              agree that Company is not liable for any loss or claim that you may have against any
              such third party.
            </p>
            <p>
              <strong>Indemnity and Release</strong>
            </p>
            <p>
              You agree to defend, indemnify, and hold harmless Company, its affiliates, and its and
              their respective officers, employees, directors, service providers, licensors, and
              agents (collectively, the “<strong>Company Parties</strong>”) from any and all losses,
              damages, expenses, including reasonable attorneys’ fees, rights, claims, actions of
              any kind, and injury (including death) arising out of or relating to your use of the
              Service, any User Content, your connection to the Service, your violation of these
              Terms of Service, or your violation of any rights of another. Notwithstanding the
              foregoing, you will have no obligation to indemnify or hold harmless any Company Party
              from or against any liability, losses, damages, or expenses incurred as a result of
              any action or inaction of such Company Party. Company will provide notice to you of
              any such claim, suit, or proceeding. Company reserves the right to assume the
              exclusive defense and control of any matter which is subject to indemnification under
              this section, and you agree to cooperate with any reasonable requests assisting
              Company’s defense of such matter. You may not settle or compromise any claim against
              the Company Parties without Company’s written consent. If you are a California
              resident, you waive California Civil Code Section 1542, which says: “A general release
              does not extend to claims that the creditor or releasing party does not know or
              suspect to exist in his or her favor at the time of executing the release and that, if
              known by him or her, would have materially affected his or her settlement with the
              debtor or releasing party.” If you are a resident of another jurisdiction, you waive
              any comparable statute or doctrine.
            </p>
            <p>
              <strong>Disclaimers</strong>
            </p>
            <p>
              YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SITES AND THE SERVICES, AND ANY
              CONTENT, IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY,
              PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. THE SITES AND SERVICES ARE PROVIDED ON
              AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER
              EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
              COMPANY SPECIFICALLY DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTIES OF TITLE,
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. COMPANY
              DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE SITES OR SERVICES
              OR ANY OF THE MATERIALS CONTAINED THEREIN WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY,
              OR ERROR-FREE.&nbsp;
            </p>
            <p>
              OUR SERVICES RELY ON EMERGING TECHNOLOGIES. SOME SERVICES ARE SUBJECT TO INCREASED
              RISK THROUGH POTENTIAL MISUSE OF TECHNOLOGY, SUCH AS CRYPTOGRAPHY. BY USING THE
              SERVICES YOU EXPLICITLY ACKNOWLEDGE AND ACCEPT THESE HEIGHTENED RISKS. COMPANY SHALL
              NOT BE LIABLE FOR THE FAILURE OF ANY MESSAGE TO SEND TO OR BE RECEIVED BY THE INTENDED
              RECIPIENT IN THE INTENDED FORM, OR FOR DIMINUTION OF VALUE OF DIGITAL CURRENCIES, AND
              COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE SAME.
            </p>
            <p>
              COMPANY AND ITS LICENSORS DO NOT WARRANT OR REPRESENT THAT ANY DATA, ANALYTICS, OR
              OTHER MATERIALS OR CONTENT YOU DOWNLOAD OR ACCESS FROM THE SERVICE WILL BE FREE OF
              VIRUSES OR OTHER HARMFUL FEATURES. WHILE COMPANY MAY MAKE DATA AND ANALYTICS AVAILABLE
              THROUGH THE SERVICE, THE DATA AND ANALYTICS ARE PROVIDED BY THIRD PARTIES AND COMPANY
              IS NOT RESPONSIBLE OR LIABLE FOR, AND HEREBY DISCLAIMS LIABILITY FOR, YOUR USE OF SUCH
              DATA AND ANALYTICS, AND THE INFORMATION YOU OBTAIN FROM THEM. YOU EXPRESSLY AGREE THAT
              YOUR USE OF ANY DATA AND ANALYTICS MADE AVAILABLE THROUGH THE SERVICE IS AT YOUR SOLE
              RISK. EVEN THOUGH COMPANY STRIVES TO MAKE THE SERVICE AVAILABLE 24/7, COMPANY DOES NOT
              GUARANTEE ITS UNINTERRUPTED AVAILABILITY AND SHALL NOT BE LIABLE FOR ANY DELAYS,
              ERRORS OR INTERRUPTIONS IN THE TRANSMISSION OR DELIVERY OF DATA VIA THE SERVICES.
              HOWEVER, WE WILL DO OUR BEST TO PREVENT THE ABOVE SITUATION FROM OCCURRING. SHOULD
              CERTAIN UNAVOIDABLE ISSUES OCCUR, WE WILL TRY TO REMEDY THE SITUATION AS SOON AS
              POSSIBLE AND NOTIFY SUCH ISSUES TO USERS VIA E-MAIL.
            </p>
            <p>
              EXCEPT AS EXPRESSLY PROVIDED HEREIN, NEITHER PARTY MAKES ANY WARRANTY OR
              REPRESENTATION OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND EACH
              PARTY SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF
              MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, AND
              COMPANY SPECIFICALLY DISCLAIMS ANY WARRANTIES OR REPRESENTATIONS REGARDING THE
              CONTINUOUS, UNINTERRUPTED, DEFECT-FREE, ERROR-FREE, VIRUS-FREE OR SECURE ACCESS TO OR
              OPERATION OF THE SERVICE OR SOFTWARE. COMPANY SPECIFICALLY DISCLAIMS ALL LIABILITY AND
              OBLIGATIONS WITH RESPECT TO ANY THIRD-PARTY PROVIDERS. COMPANY MAKES NO
              REPRESENTATIONS OR WARRANTIES WITH REGARD TO THE POTENTIAL MARKET FOR THE SERVICE.
            </p>
            <p>
              THE SERVICE IS NOT INTENDED TO PROVIDE, AND COMPANY DOES NOT PROVIDE, ANY INVESTMENT
              OR FINANCIAL ADVICE WHATSOEVER. WITH RESPECT TO ANY FINANCIAL OR INVESTMENT DECISIONS
              WITH RESPECT TO THE DIGITAL CURRENCIES AND ANY TRANSACTIONS INVOLVING DIGITAL
              CURRENCIES, IT IS STRONGLY RECOMMENDED THAT YOU CONDUCT YOUR OWN RESEARCH AND DUE
              DILIGENCE INVESTIGATION, TO PROPERLY EVALUATE THE BENEFITS AND RISKS OF ANY INVESTMENT
              OR TRANSACTION INVOLVING DIGITAL CURRENCIES OR HOLDING DIGITAL CURRENCIES. IT IS ALSO
              STRONGLY RECOMMENDED THAT YOU SEEK THE ADVICE AND GUIDANCE OF QUALIFIED ACCOUNTANTS,
              FINANCIAL ADVISORS, TAX ADVISORS, LEGAL COUNSELS, AND INVESTMENT ADVISORS IN
              CONNECTION WITH ANY INVESTMENT OR TRANSACTION INVOLVING DIGITAL CURRENCIES.
            </p>
            <p>
              <strong>Limitation of Liability</strong>
            </p>
            <p>
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE COMPANY PARTIES WILL NOT BE LIABLE FOR ANY
              INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS
              OF PROFITS INCLUDING DAMAGES FOR LOSS OF GOODWILL, USE, OR DATA OR OTHER INTANGIBLE
              LOSSES (EVEN IF THE COMPANY PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE,
              RESULTING FROM: (A) THE USE OR THE INABILITY TO USE THE SERVICE, EVEN IF AN AUTHORIZED
              REPRESENTATIVE OF COMPANY HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN OF THE
              POSSIBILITY OF SUCH DAMAGES; (B) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND
              SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR
              OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
              SERVICE; (C) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (D)
              STATEMENTS OR CONDUCT ARISING OUT OF OR IN ANY WAY RELATED TO SOFTWARE, PRODUCTS,
              SERVICES, AND/OR INFORMATION OFFERED OR PROVIDED BY ANY THIRD PARTY AND ACCESSED
              THROUGH THE SITES OR SERVICE; (E) ANY UNAUTHORIZED USE OF YOUR WALLET ADDRESS AND/OR
              PRIVATE KEY DUE TO YOUR FAILURE TO MAINTAIN THE CONFIDENTIALITY OF YOUR WALLET, ANY
              INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES OR ANY BUGS,
              VIRUSES, TROJAN HORSES, OR THE LIKE THAT ARE FOUND IN THE WALLET SOFTWARE OR THAT MAY
              BE TRANSMITTED TO OR THROUGH OUR SERVICES BY ANY THIRD PARTY (REGARDLESS OF THE SOURCE
              OF ORIGINATION); OR (F) ANY OTHER MATTER RELATING TO THE SERVICE. IN NO EVENT WILL THE
              COMPANY PARTIES’ TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION
              EXCEED THE AMOUNT YOU HAVE PAID COMPANY IN THE LAST SIX (6) MONTHS, OR, IF GREATER,
              ONE HUNDRED DOLLARS ($100).SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION
              OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
              CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY
              NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH
              ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE
              REMEDY IS TO DISCONTINUE THE USE OF THE SERVICE.
            </p>
            <p>
              IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED “DISCLAIMER OF
              WARRANTIES” AND “LIMITATION OF LIABILITY” ARE INTENDED TO BE ONLY AS BROAD AS IS
              PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF ANY PORTION OF THESE SECTIONS
              IS HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF
              SUCH PORTION WILL NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE
              SECTIONS.
            </p>
            <p>
              <strong>Acknowledgment of Certain Risks; Other Disclaimers; Release of Claims</strong>
            </p>
            <p>
              By accessing and using the Services, you represent that you understand the inherent
              risks associated with using cryptographic and blockchain-based systems, and that you
              have a working knowledge of the usage and intricacies of Digital Currencies. You
              further understand that the markets for these Digital Currencies are highly volatile
              due to factors including (but not limited to) adoption, speculation, technology,
              security, and regulation. You acknowledge and accept that the cost and speed of
              transacting with cryptographic and blockchain-based systems such as Casper are
              variable and may increase dramatically at any time. You understand that anyone can
              create a token, including fake versions of existing tokens and tokens that falsely
              claim to represent projects, and acknowledge and accept the risk that you may
              mistakenly trade those or other tokens. You further acknowledge that we are not
              responsible for any of these variables or risks and that we cannot be held liable for
              any resulting losses that you experience while accessing or using the Sites or
              Services.
            </p>
            <p>
              The Services and your Digital Currencies could be impacted by one or more regulatory
              inquiries or regulatory actions, which could impede or limit the ability of Company to
              continue to make its proprietary software, and thus, could impede or limit your
              ability to continue to use the Services.
            </p>
            <p>
              You understand and acknowledge that cryptography is a progressing field with advances
              in code cracking and other technical advancements, such as the development of quantum
              computers, which may present risks to Digital Currencies and the services, and could
              result in the theft or loss of your Digital Currencies. To the extent possible, we
              intend to update Company-developed smart contracts related to the Services to account
              for any advances in cryptography and to incorporate additional security measures
              necessary to address risks presented from technological advancements, but that
              intention does not reflect a binding commitment and does not in any way guarantee or
              otherwise ensure full security of the Services.
            </p>
            <p>
              Although we intend to provide accurate and timely information on the Sites and during
              your use of the Services, that intention does not reflect a binding commitment, and
              the Sites and other information available when using the Services may not be accurate,
              complete, error-free or current. To continue to provide you with as complete and
              accurate information as possible, information may be changed or updated from time to
              time without notice, including, without limitation, information regarding our
              policies. Accordingly, you should verify all information before relying on it in any
              manner, and all decisions based on such information contained on the Sites or made
              available through the Services are your sole and absolute responsibility. No
              representation of any kind or nature is made as to the accuracy, completeness or
              appropriateness for any particular purpose of any pricing or other information
              distributed via the Sites or Services. Pricing information may be higher or lower than
              prices available on platforms providing similar services.
            </p>
            <p>
              Any reference to a type of Digital Currency on the Sites or otherwise during the use
              of the Services does not indicate our approval or disapproval of the technology on
              which the Digital Currency relies, and should not be used as a substitute for your
              understanding of the risks specific to each type of Digital Asset.
            </p>
            <p>
              Use of the Services, in particular for trading Digital Currencies, may carry financial
              risk. Digital Currencies are, by their nature, highly experimental, risky, and
              volatile. Transactions entered into in connection with the Services are irreversible,
              final and there are no refunds. You acknowledge and agree that you will access and use
              the Sites and the Services at your own risk. The risk of loss in trading Digital
              Currencies can be substantial. You should, therefore, carefully consider whether such
              trading is suitable for you in light of your circumstances and financial resources. By
              using the Services, you represent and warrant that you have been, are, and will be
              solely responsible for making your independent appraisal and investigations into the
              risks of a given transaction and the underlying Digital Currencies. You represent that
              you have sufficient knowledge, market sophistication, professional advice, and
              experience to make your evaluation of the merits and risks of any transaction
              conducted in connection with the Services or any Digital Asset. You accept all
              consequences of using the Services, including the risk that you may lose access to
              your Digital Currencies indefinitely. All transaction decisions are made solely by
              you. Notwithstanding anything in these Terms of Service, we accept no responsibility
              whatsoever for, and will in no circumstances be liable to you in connection with, your
              use of the Services for performing Digital Currency transactions.
            </p>
            <p>
              Company is a developer of software. The Company does not operate a Digital Currency
              exchange platform or offer trade execution or clearing services and, therefore, has no
              oversight, involvement, or control concerning your transactions using the Services.
              All transactions between users of Company-developed software are executed peer-to-peer
              directly between the users' Digital Currency Network addresses through smart
              contracts. You are responsible for complying with all laws that may be applicable to
              or govern your use of the Services, including, but not limited to, the Commodity
              Exchange Act and the regulations promulgated thereunder by the U.S. Commodity Futures
              Trading Commission ("CFTC"), the federal securities laws and the regulations
              promulgated thereunder by the U.S. Securities and Exchange Commission ("SEC") and all
              foreign applicable laws.
            </p>
            <p>
              You understand that Company is not registered or licensed by the CFTC, SEC, or any
              financial regulatory authority. No financial regulatory authority has reviewed or
              approved the use of the Company-developed software. The Sites and Company-developed
              software do not constitute advice or a recommendation concerning any commodity,
              security, or other Digital Currency or instrument. Company is not acting as an
              investment adviser or commodity trading adviser to any person or entity.
            </p>
            <p>
              You expressly agree that you assume all risks in connection with your access and use
              of the Sites and Services and your interaction therewith. You further expressly waive
              and release Company Parties from any and all liability, claims, causes of action, or
              damages arising from or in any way relating to your use of the Sites and Services and
              your interaction therewith. If you are a California resident, you waive the benefits
              and protections of California Civil Code § 1542, which provides: "[a] general release
              does not extend to claims that the creditor or releasing party does not know or
              suspect to exist in his or her favor at the time of executing the release and that, if
              known by him or her, would have materially affected his or her settlement with the
              debtor or released party."
            </p>
            <p>
              <strong>Dispute Resolution By Binding Arbitration:</strong>
            </p>
            <p>
              <strong>PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.</strong>
            </p>
            <p>
              <strong>a. Agreement to Arbitrate</strong>
            </p>
            <p>
              This Dispute Resolution by Binding Arbitration section is referred to in these Terms
              of Service as the “Arbitration Agreement.” You agree that any and all disputes or
              claims that have arisen or may arise between you and Company, whether arising out of
              or relating to these Terms of Service (including any alleged breach thereof), the
              Service, any advertising, or any aspect of the relationship or transactions between
              us, will be resolved exclusively through final and binding arbitration, rather than a
              court, in accordance with the terms of this Arbitration Agreement, except that you may
              assert individual claims in small claims court, if your claims qualify. Further, this
              Arbitration Agreement does not preclude you from bringing issues to the attention of
              federal, state, or local agencies, and such agencies can, if the law allows, seek
              relief against us on your behalf. You agree that, by entering into these Terms of
              Service, you and Company are each waiving the right to a trial by jury or to
              participate in a class action. Your rights will be determined by a neutral arbitrator,
              not a judge or jury. The Federal Arbitration Act governs the interpretation and
              enforcement of this Arbitration Agreement.
            </p>
            <p>
              <strong>
                b. Prohibition of Class and Representative Actions and Non-Individualized Relief
              </strong>
            </p>
            <p>
              <strong>
                <em>
                  YOU AND COMPANY AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON
                  AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
                  OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND COMPANY AGREE
                  OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR
                  PARTY’S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
                  REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF
                  (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE
                  INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF
                  NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S), EXCEPT THAT YOU MAY PURSUE A
                  CLAIM FOR AND THE ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE
                  LAW TO THE EXTENT REQUIRED FOR THE ENFORCEABILITY OF THIS PROVISION.
                </em>
              </strong>
            </p>
            <p>
              <strong>c. Pre-Arbitration Dispute Resolution</strong>
            </p>
            <p>
              Company is always interested in resolving disputes amicably and efficiently, and most
              customer concerns can be resolved quickly and to the customer’s satisfaction by
              emailing customer support at hello@CSPR.live. If such efforts prove unsuccessful, a
              party who intends to seek arbitration must first send to the other, by certified mail,
              a written Notice of Dispute (“Notice”). The Notice to Company should be sent to 30
              Gould St, Suite R, Sheridan, WY 82801 (“Notice Address”). The Notice must (i) describe
              the nature and basis of the claim or dispute and (ii) set forth the specific relief
              sought. If Company and you do not resolve the claim within sixty (60) calendar days
              after the Notice is received, you or Company may commence an arbitration proceeding.
              During the arbitration, the amount of any settlement offer made by Company or you will
              not be disclosed to the arbitrator until after the arbitrator determines the amount,
              if any, to which you or Company is entitled.
            </p>
            <p>
              <strong>d. Arbitration Procedures</strong>
            </p>
            <p>
              Arbitration will be conducted by a neutral arbitrator in accordance with the American
              Arbitration Association’s (“AAA”) rules and procedures, including the AAA’s Consumer
              Arbitration Rules (collectively, the “AAA Rules”), as modified by this Arbitration
              Agreement. For information on the AAA, please visit its website, https://www.adr.org.
              Information about the AAA Rules and fees for consumer disputes can be found on the
              AAA’s consumer arbitration page, https://www.adr.org/consumer. If there is any
              inconsistency between any term of the AAA Rules and any term of this Arbitration
              Agreement, the applicable terms of this Arbitration Agreement will control unless the
              arbitrator determines that the application of the inconsistent Arbitration Agreement
              terms would not result in a fundamentally fair arbitration. The arbitrator must also
              follow the provisions of these Terms of Service as a court would. All issues are for
              the arbitrator to decide, including issues relating to the scope, enforceability, and
              arbitrability of this Arbitration Agreement. Although arbitration proceedings are
              usually simpler and more streamlined than trials and other judicial proceedings, the
              arbitrator can award the same damages and relief on an individual basis that a court
              can award to an individual under these Terms of Service and applicable law. Decisions
              by the arbitrator are enforceable in court and may be overturned by a court only for
              very limited reasons.
            </p>
            <p>
              Unless Company and you agree otherwise, any arbitration hearings will take place in a
              reasonably convenient location for both parties with due consideration of their
              ability to travel and other pertinent circumstances. If the parties are unable to
              agree on a location, the determination will be made by AAA. If your claim is for
              $10,000 or less, Company agrees that you may choose whether the arbitration will be
              conducted solely on the basis of documents submitted to the arbitrator, through a
              telephonic hearing, or by an in-person hearing as established by the AAA Rules. If
              your claim exceeds $10,000, the right to a hearing will be determined by the AAA
              Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator
              will issue a reasoned written decision sufficient to explain the essential findings
              and conclusions on which the award is based.
            </p>
            <p>
              <strong>e. Costs of Arbitration</strong>
            </p>
            <p>
              Payment of all filing, administration, and arbitrator fees (collectively, the
              “Arbitration Fees”) will be governed by the AAA Rules, unless otherwise provided in
              this Arbitration Agreement. If the value of the relief sought is $75,000 or less, at
              your request, Company will pay all Arbitration Fees. If the value of relief sought is
              more than $75,000 and you are able to demonstrate to the arbitrator that you are
              economically unable to pay your portion of the Arbitration Fees or if the arbitrator
              otherwise determines for any reason that you should not be required to pay your
              portion of the Arbitration Fees, Company will pay your portion of such fees. In
              addition, if you demonstrate to the arbitrator that the costs of arbitration will be
              prohibitive as compared to the costs of litigation, Company will pay as much of the
              Arbitration Fees as the arbitrator deems necessary to prevent the arbitration from
              being cost-prohibitive. Any payment of attorneys’ fees will be governed by the AAA
              Rules.
            </p>
            <p>
              <strong>f. Confidentiality</strong>
            </p>
            <p>
              All aspects of the arbitration proceeding, and any ruling, decision, or award by the
              arbitrator, will be strictly confidential for the benefit of all parties.
            </p>
            <p>
              <strong>g. Severability</strong>
            </p>
            <p>
              If a court or the arbitrator decides that any term or provision of this Arbitration
              Agreement (other than the subsection (b) above titled “Prohibition of Class and
              Representative Actions and Non-Individualized Relief” above) is invalid or
              unenforceable, the parties agree to replace such term or provision with a term or
              provision that is valid and enforceable and that comes closest to expressing the
              intention of the invalid or unenforceable term or provision, and this Arbitration
              Agreement will be enforceable as so modified. If a court or the arbitrator decides
              that any of the provisions of subsection (b) above titled “Prohibition of Class and
              Representative Actions and Non-Individualized Relief” are invalid or unenforceable,
              then the entirety of this Arbitration Agreement will be null and void, unless such
              provisions are deemed to be invalid or unenforceable solely with respect to claims for
              public injunctive relief. The remainder of these Terms of Service will continue to
              apply.
            </p>
            <p>
              <strong>h. Future Changes to Arbitration Agreement</strong>
            </p>
            <p>
              Notwithstanding any provision in these Terms of Service to the contrary, Company
              agrees that if it makes any future change to this Arbitration Agreement (other than a
              change to the Notice Address) while you are a user of the Service, you may reject any
              such change by sending Company written notice within thirty (30) calendar days of the
              change to the Notice Address provided above. By rejecting any future change, you are
              agreeing that you will arbitrate any dispute between us in accordance with the
              language of this Arbitration Agreement as of the date you first accepted these Terms
              of Service (or accepted any subsequent changes to these Terms of Service).
            </p>
            <p>
              <strong>Termination</strong>
            </p>
            <p>
              You agree that Company, in its sole discretion, may suspend your use of the Service
              and remove and discard any content within the Service, for any reason without notice,
              including for lack of use or if Company believes that you have violated or acted
              inconsistently with the letter or spirit of these Terms of Service. Any suspected
              fraudulent, abusive, or illegal activity that may be grounds for termination of your
              use of the Service, may be referred to appropriate law enforcement authorities.
              Company may also in its sole discretion and at any time discontinue providing the
              Service, or any part thereof, with or without notice. You agree that any termination
              of your access to the Service under any provision of these Terms of Service may be
              effected without prior notice, and acknowledge and agree that Company may immediately
              bar any further access to the Service. Further, you agree that Company will not be
              liable to you or any third party for any termination of your access to the Service.
              You understand that by using the Services, you may be exposed to User Content that is
              offensive, indecent, or objectionable. We take no responsibility and assume no
              liability for any User Content, including any loss or damage to any of your User
              Content.
            </p>
            <p>
              <strong>Compliance with Laws</strong>
            </p>
            <p>
              You acknowledge that the Services are subject to U.S. export control laws, including
              the Export Administration Regulations of the Bureau of Industry and Security (“BIS”),
              U.S. Department of Commerce; and economic sanctions administered by the Office of
              Foreign Assets Control (“OFAC”) of the U.S. Department of the Treasury. You agree to
              comply strictly with all such laws and regulations as they relate to the Services,
              and, to the extent consistent with this Terms of Service, not to download, use,
              reexport, or transfer the Services into or within Crimea, Cuba, Iran, North Korea, or
              Syria, or to the governments of these countries, wherever located. You represent that
              you are not identified on BIS’s Denied Persons List or OFAC’s Specially Designated
              Nationals List, nor will You permit the use of the Services by any person or entity
              identified on such a list. You further represent that you will not use the Services
              for nuclear, chemical, or biological weapons proliferation, or for missile-development
              purposes, nor will You permit the use of the Services by any person or entity for such
              uses. You further represent that you will not provide, export, reexport, or transfer
              the Services other than in compliance with the foregoing restrictions.
            </p>
            <p>
              You represent and warrant that: (i) you (and, if you are an entity, your officers,
              directors, employees, and agents (collectively, the “Representatives”)) are in
              compliance with the Foreign Corrupt Practices Act of 1977, as amended, and any rules
              and regulations thereunder, and similar laws of foreign jurisdictions or other
              anti-money laundering obligations under the law of your jurisdiction or otherwise
              applicable to Representatives; (ii) you are (and, if you are an entity, your
              Representatives are) in compliance with anti-money laundering obligations; (iii) you
              have not (and, if you are an entity, your Representatives have not) been convicted of,
              or have agreed to enter into a pretrial diversion or similar program in connection
              with the prosecution of, a criminal offense involving theft, dishonesty, breach of
              trust, money laundering, the illegal manufacture, sale, distribution of or trafficking
              in controlled substances, or substantially equivalent activity in a domestic,
              military, or foreign court
            </p>
            <p>
              To the extent that any governmental agency or regulatory body in any country takes any
              action which prevents, restricts, or otherwise limits Company’s ability to provide any
              portion of the Services to you, Company shall have no liability in connection with the
              provision of, or failure to provide, such Services to you, and Company may, at its
              option, (a) make a reasonable effort to relocate the Services to another location, or
              (b) terminate the applicable Services immediately and without penalty.
            </p>
            <p>
              <strong>User Disputes</strong>
            </p>
            <p>
              You agree that you are solely responsible for your interactions with any other user in
              connection with the Service, and Company will have no liability or responsibility with
              respect thereto. Company reserves the right, but has no obligation, to become involved
              in any way with disputes between you and any other user of the Service.
            </p>
            <p>
              <strong>General</strong>
            </p>
            <p>
              These Terms of Service (together with the terms incorporated by reference herein)
              constitute the entire agreement between you and Company governing your access and use
              of the Service, and supersede any prior agreements between you and Company with
              respect to the Service. You also may be subject to additional terms and conditions
              that may apply when you use Third-Party Services, third-party content or third-party
              software. These Terms of Service will be governed by the laws of the State of Wyoming
              without regard to its conflict of law provisions. With respect to any disputes or
              claims not subject to arbitration, as set forth above, you and Company submit to the
              personal and exclusive jurisdiction of the state and federal courts located within
              Laramie County, Wyoming. The failure of Company to exercise or enforce any right or
              provision of these Terms of Service will not constitute a waiver of such right or
              provision. If any provision of these Terms of Service is found by a court of competent
              jurisdiction to be invalid, the parties nevertheless agree that the court should
              endeavor to give effect to the parties’ intentions as reflected in the provision, and
              the other provisions of these Terms of Service remain in full force and effect. You
              agree that regardless of any statute or law to the contrary, any claim or cause of
              action arising out of or related to use of the Service or these Terms of Service must
              be filed within one (1) year after such claim or cause of action arose or be forever
              barred. A printed version of these Terms of Service and of any notice given in
              electronic form will be admissible in judicial or administrative proceedings based
              upon or relating to these Terms of Service to the same extent and subject to the same
              conditions as other business documents and records originally generated and maintained
              in printed form. You may not assign these Terms of Service without the prior written
              consent of Company, but Company may assign or transfer these Terms of Service, in
              whole or in part, without restriction. The section titles in these Terms of Service
              are for convenience only and have no legal or contractual effect. As used in these
              Terms of Service, the words “include” and “including,” and variations thereof, will
              not be deemed to be terms of limitation, but rather will be deemed to be followed by
              the words “without limitation.” Notices to you may be made via either email or regular
              mail. The Service may also provide notices to you of changes to these Terms of Service
              or other matters by displaying notices or links to notices generally on the Service.
              Company will not be in default hereunder by reason of any failure or delay in the
              performance of its obligations where such failure or delay is due to civil
              disturbances, riot, epidemic, hostilities, war, terrorist attack, embargo, natural
              disaster, acts of God, flood, fire, sabotage, fluctuations or unavailability of
              electrical power, network access or equipment, or any other circumstances or causes
              beyond Company’s reasonable control.
            </p>
            <p>
              <strong>Your Privacy</strong>
            </p>
            <p>
              At Company, we respect the privacy of our users. For details, please see our Privacy
              Policies. By using the Service, you consent to our collection and use of personal data
              as outlined therein.
            </p>
            <p>
              <strong>Questions? Concerns? Suggestions?</strong>
            </p>
            <p>
              Please contact us at hello@CSPR.live or by sending a notice to 30 Gould St, Suite R,
              Sheridan, WY 82801 to report any violations of these Terms of Service or to pose any
              questions regarding this Terms of Service or the Service.
            </p>
          </div>
        </Content>
      </ContentContainer>
    </UnauthorizedLayout>
  );
};
