import React from 'react';
import styled from 'styled-components';
import { BodyText, Checkbox, FlexRow, InfoIcon, SvgIcon, Tooltip } from '@make-software/cspr-ui';
import { AccessItem, UserRole } from 'app/build-api';

const StyledSvg = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    marginLeft: '4px'
  })
);

interface UserRoleProps {
  currentAccessList: AccessItem[];
  currentUserRole?: UserRole;
  onSelectRole: (one: AccessItem[]) => void;
}

const accessItemTitles = {
  [AccessItem.UserManagement]: 'User & Access Management',
  [AccessItem.ClickKeyManagement]: 'Click Keys Management',
  [AccessItem.CloudKeyManagement]: 'Cloud Keys Management',
  [AccessItem.TierManagement]: 'Subscription Management',
  [AccessItem.Statistics]: 'Statistics'
};

const allAccessItems = Object.values(AccessItem);

export const UserAccessList = ({
  currentAccessList,
  onSelectRole,
  currentUserRole
}: UserRoleProps) => {
  const handleAccess = (entity: AccessItem) => {
    // If the access disabled for CloudKeyManagement need to disable ClickKeyManagement as well
    if (entity === AccessItem.CloudKeyManagement && currentAccessList.includes(entity)) {
      onSelectRole(
        currentAccessList.filter(
          (item) => item !== entity && item !== AccessItem.ClickKeyManagement
        )
      );
      return;
    }

    // If the access enabled for ClickKeyManagement need to enable CloudKeyManagement as well
    if (
      entity === AccessItem.ClickKeyManagement &&
      !currentAccessList.includes(entity) &&
      !currentAccessList.includes(AccessItem.CloudKeyManagement)
    ) {
      onSelectRole([
        ...currentAccessList,
        ...[AccessItem.ClickKeyManagement, AccessItem.CloudKeyManagement]
      ]);
      return;
    }

    if (currentAccessList.includes(entity)) {
      onSelectRole(currentAccessList.filter((item) => item !== entity));
    } else {
      onSelectRole([...currentAccessList, entity]);
    }
  };
  return (
    <FlexRow justify="center" direction={'column'} gap={'16px'}>
      <FlexRow align={'center'}>
        <BodyText size={1}>Access</BodyText>
        <Tooltip
          title={
            "Determine the access and editing rights for the invited user. The owner will be able to change these settings later in the user's settings."
          }
        >
          <StyledSvg src={InfoIcon} color={'gray'} />
        </Tooltip>
      </FlexRow>
      {allAccessItems.map((item) => (
        <FlexRow>
          <Checkbox
            label={<BodyText size={3}>{accessItemTitles[item]}</BodyText>}
            checked={currentAccessList.includes(item)}
            onChange={() => handleAccess(item)}
            disabled={currentUserRole === UserRole.Owner}
          />
        </FlexRow>
      ))}
    </FlexRow>
  );
};
