import styled from 'styled-components';
import { BodyText } from '@make-software/cspr-ui';
import React from 'react';

export const CustomButtonContainer = styled.div<{ active: boolean }>(({ theme, active }) =>
  theme.withMedia({
    border: 'none',
    cursor: 'pointer',
    color: theme.styleguideColors.contentPrimary,
    background: theme.styleguideColors.fillSecondary,
    borderRadius: theme.borderRadius.base,
    fontWeight: theme.typography.fontWeight.regular,
    minHeight: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: ['4px 12px'],
    ':hover': {
      background: theme.styleguideColors.fillSecondaryBlueHover
    },
    ':active': {
      background: theme.styleguideColors.fillSecondaryBlueHover,
      fontWeight: theme.typography.fontWeight.semiBold
    },
    ...(active && {
      background: theme.styleguideColors.fillSecondaryBlueHover,
      fontWeight: theme.typography.fontWeight.semiBold
    })
  })
);

export const ButtonListSelect = ({
  value,
  onClick,
  active
}: {
  value: string;
  onClick: () => void;
  active: boolean;
}) => {
  return (
    <CustomButtonContainer onClick={onClick} active={active}>
      <BodyText size={active ? 1 : 3} scale={'xs'} variation="black">
        {value}
      </BodyText>
    </CustomButtonContainer>
  );
};
