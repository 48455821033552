import React from 'react';
import styled from 'styled-components';
import { BodyText, FlexRow, SvgIcon, Tooltip } from '@make-software/cspr-ui';
import { OrderDirection, SortingProps } from 'app/components/common/pagination/types';
import SortingUpIcon from 'assets/icons/ic-sorting-up.svg';
import SortingDownIcon from 'assets/icons/ic-sorting-down.svg';

export enum HeaderFontSize {
  'small' = 'small',
  'default' = 'default'
}

const headerScaleBySize: Record<HeaderFontSize, 'xs' | 'sm'> = {
  [HeaderFontSize.small]: 'xs',
  [HeaderFontSize.default]: 'sm'
};

export interface BaseTableHeaderProps extends SortingProps {
  align?: 'left' | 'right' | 'center';
  fitContent?: boolean;
  sortableColumnField?: string | undefined;
  tooltipText?: React.ReactElement | string | undefined;
  icon?: React.ReactElement | undefined;
  fixedWidthRem?: number;
  headerFontSize?: HeaderFontSize;
}

const StyledTableHeader = styled.th<BaseTableHeaderProps>(
  ({ theme, align = 'left', fitContent, fixedWidthRem }) => ({
    textAlign: align,
    height: 20,
    padding: 8,
    ':first-of-type': {
      paddingLeft: 20
    },
    ':last-of-type': {
      paddingRight: 20
    },
    ...(fitContent && {
      width: '1%'
    }),
    ...(fixedWidthRem && {
      width: `${fixedWidthRem}rem`
    }),
    textTransform: 'capitalize'
  })
);

const StyledSortedButtons = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '7px',
  '> * + *': {
    marginTop: '2.5px'
  }
}));

const StyledHeaderGroup = styled.div<{ sortable?: boolean }>(({ theme, sortable }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: sortable ? 'pointer' : 'inherit'
}));

const MouseHoverStylesRed = styled.div<{ active?: boolean }>(({ theme, active }) => ({
  lineHeight: '12px',
  path: {
    fill: active ? theme.styleguideColors.contentRed : theme.styleguideColors.contentTertiary
  },
  ':hover': {
    path: {
      fill: theme.styleguideColors.fillPrimaryRedHover
    }
  },
  ':active': {
    path: {
      fill: theme.styleguideColors.fillPrimaryRedClick
    }
  }
}));

export const BaseTableHeader = ({
  children,
  sortableColumnField,
  orderBy,
  orderDirection,
  setOrder,
  tooltipText,
  icon,
  reverseSortingDirection = false,
  headerFontSize = HeaderFontSize.default,
  ...restProps
}: BaseTableHeaderProps) => {
  const descSortingOrder = reverseSortingDirection ? OrderDirection.ASC : OrderDirection.DESC;

  const ascSortingOrder = reverseSortingDirection ? OrderDirection.DESC : OrderDirection.ASC;

  const handleColumnSort = (orderDirectionArg?: OrderDirection) => (ev: any) => {
    if (sortableColumnField) {
      ev.stopPropagation();
      let direction = orderDirectionArg;
      if (direction == null) {
        if (orderBy !== sortableColumnField) {
          direction = OrderDirection.DESC;
        } else {
          direction =
            orderDirection !== OrderDirection.ASC ? OrderDirection.ASC : OrderDirection.DESC;
        }
      }

      setOrder && setOrder(sortableColumnField, direction);
    }
  };

  return (
    <StyledTableHeader {...restProps}>
      <StyledHeaderGroup sortable={!!sortableColumnField}>
        <Tooltip title={tooltipText} limitWidth>
          <FlexRow gap={3} align={'center'}>
            <BodyText
              size={1}
              scale={headerScaleBySize[headerFontSize]}
              onClick={handleColumnSort()}
            >
              {children}
            </BodyText>
            {icon}
          </FlexRow>
        </Tooltip>
        {!!sortableColumnField && (
          <StyledSortedButtons>
            <MouseHoverStylesRed
              active={sortableColumnField === orderBy && orderDirection === ascSortingOrder}
              onClick={handleColumnSort(ascSortingOrder)}
            >
              <SvgIcon height={7} src={SortingUpIcon} />
            </MouseHoverStylesRed>
            <MouseHoverStylesRed
              active={sortableColumnField === orderBy && orderDirection === descSortingOrder}
              onClick={handleColumnSort(descSortingOrder)}
            >
              <SvgIcon height={7} src={SortingDownIcon} />
            </MouseHoverStylesRed>
          </StyledSortedButtons>
        )}
      </StyledHeaderGroup>
    </StyledTableHeader>
  );
};

export default BaseTableHeader;
