export const ClickProviders = [
  {
    label: 'Casper Wallet',
    value: 'casper-wallet'
  },
  {
    label: 'Ledger',
    value: 'ledger'
  },
  {
    label: 'CasperDash',
    value: 'casperdash'
  },
  {
    label: 'Metamask',
    value: 'metamask-snap'
  },
  {
    label: 'WalletConnect',
    value: 'walletconnect'
  },
  {
    label: 'ClickJWT',
    value: 'csprclick-customjwt'
  },
  {
    label: 'Torus',
    value: 'torus-wallet'
  },
  {
    label: 'Signer',
    value: 'casper-signer'
  }
];
