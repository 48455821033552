import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

interface SkeletonComponentProps {
  baseColor?: string;
  animationColor?: string;
  width?: string;
  height?: string;
}

const SkeletonWrapper = styled.div<{
  baseColor?: string;
  width?: string;
  height?: string;
  animationColor?: string;
}>`
    @keyframes react-loading-skeleton {
        100% {
            transform: translateX(100%);
        }
    }

    .react-loading-skeleton {

        background-color: ${(props) => (props.baseColor ? props.baseColor : '#f2f3f5')};

        width: ${(props) => (props.width ? props.width : '100%')};
        border-radius: 0.25rem;
        display: inline-flex;
        line-height: 1;

        position: relative;
        user-select: none;
        overflow: hidden;
        z-index: 1;
    }

    .react-loading-skeleton::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-repeat: no-repeat;
        background-image: linear-gradient(90deg,
        ${(props) => (props.baseColor ? props.baseColor : '#f2f3f5')},
        ${(props) => (props.animationColor ? props.animationColor : '#FFFFFF')},
        ${(props) => (props.baseColor ? props.baseColor : '#f2f3f5')}
        transform: translateX(-100%);

        animation-name: react-loading-skeleton;
        animation-direction: normal;
        animation-duration: 1.5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-delay: 1ms;
    }

    @media (prefers-reduced-motion) {
        .react-loading-skeleton {
            --pseudo-element-display: none;
        }
    }
`;

export const SkeletonLoader = ({
  baseColor,
  animationColor,
  height,
  width
}: SkeletonComponentProps) => {
  return (
    <SkeletonWrapper baseColor={baseColor} animationColor={animationColor} width={width}>
      <Skeleton height={height ? height : '24px'} width={width ? width : '100%'} />
    </SkeletonWrapper>
  );
};
