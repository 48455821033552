export const validateEmail = (email: string) => {
  return !!(
    email &&
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  );
};

export const validateDomain = (domain: string) =>
  !domain.toLowerCase().match(/^[a-z0-9\d*]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}$/)
    ? 'Incorrect format. Please use example.com or www.example.com'
    : '';

export const validateNumbersOnly = (data: string) =>
  !!(data && String(data).toLowerCase().match(/\d+/));

const DEFAULT_STR_MIN_LENGTH = 3;
const DEFAULT_STR_MAX_LENGTH = 63;

export const checkMinLength = (str: string, minLength: number = DEFAULT_STR_MIN_LENGTH) =>
  str.length < minLength ? `Invalid length. Minimum length should be at least ${minLength} characters` : '';

export const checkMaxLength = (str: string, maxLength: number = DEFAULT_STR_MAX_LENGTH) =>
  str.length > maxLength ? `Invalid length. Max length should be ${maxLength} characters` : '';

export const checkDefaultLengthRange = (str: string) => checkMinLength(str) || checkMaxLength(str);


export const invalidEmailMessage = 'This isn’t a correct email format';
export const invalidPasswordMessage = 'Password doesn’t meet our requirements';
export const invalidNameMessage = 'Name can’t contain digits or special characters';
