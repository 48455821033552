import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { PageTile } from '@make-software/cspr-ui';
import { ClickFilterState, GetClickKeyResponseType } from 'app/build-api';
import { buildApi, DefaultResponse, GetResponseType } from 'app/build-api/types';
import { getSearchParamsFromClickFilters } from 'app/components/utils/url-utils';
import { getDateRangeFromSearchParams } from 'app/components/utils/date-time';
import { ActiveTabs, TabData } from '../common/active-tabs/active-tabs';
import { FiltersBlockContainer } from './filters-block';
import { SessionCountContainer } from './session-count-graph/session-count-container';
import { UniqueAccountContainer } from './unique-accounts-graph/unique-accounts-container';

const FiltersContent = styled(PageTile)(({ theme }) =>
  theme.withMedia({
    padding: '32px 0px'
  })
);

interface ClickStatisticsContainerProps {
  activeTab: TabData;
  handleChangeTab: (tab: TabData) => void;
}

const getInitialFilters = (searchParams: URLSearchParams): ClickFilterState => {
  return {
    dateRange: getDateRangeFromSearchParams(searchParams),
    apps: searchParams.get('apps')?.split(',') || [],
    providers: searchParams.get('providers')?.split(',') || []
  };
};

export const ClickStatisticsContainer = (props: ClickStatisticsContainerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<ClickFilterState>(getInitialFilters(searchParams));
  const [appsList, setAppsList] =
    useState<GetResponseType<GetClickKeyResponseType>>(DefaultResponse);

  useEffect(() => {
    (async () => {
      setAppsList(await buildApi.getClickKeys(0,0,true));
    })();
  }, []);

  const handleUpdateFilters = (updatedFilters: Partial<ClickFilterState>) => {
    const newState = {
      ...filters,
      ...updatedFilters
    };
    setSearchParams((prevParams) => {
      return getSearchParamsFromClickFilters(prevParams, newState);
    });
    setFilters(newState);
  };

  return (
    <>
      <FiltersContent>
        <ActiveTabs activeTab={props.activeTab} onChange={props.handleChangeTab} />
        <FiltersBlockContainer
          filters={filters}
          keyList={appsList}
          onUpdateFilters={handleUpdateFilters}
        />
      </FiltersContent>
      <SessionCountContainer filters={filters} appsList={appsList} />
      <UniqueAccountContainer filters={filters} appsList={appsList} />
    </>
  );
};
