import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetSubscriptions } from 'app/build-api/hooks/useGetSubscriptions';
import { ErrorResult } from 'app/build-api/types';
import {
  AUTH_TOKEN,
  CLICK_FREE_TIER_ID,
  CLICK_PRO_TIER_ID,
  CLOUD_FREE_TIER_ID,
  CLOUD_PRO_TIER_ID,
  CUSTOM_TIER,
  generalError,
  statusCode
} from 'app/constants';
import { SIGN_IN_PATH } from 'app/route/paths';
import {
  CurrentClickTierType,
  CurrentCloudTierType,
  ProductType,
  SubscriptionItem,
  WithSubscriptionProps
} from 'app/build-api';
import { SvgIcon } from '@make-software/cspr-ui';
import NotFoundIcon from 'assets/logos/no-found.svg';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import { UnexpectedErrorWrapper } from 'app/components/home/components/create-modal-styles';
import { getFormattedDate } from 'app/components/utils/date-time';

export const WithSubscriptions =
  (
    WrappedComponent: ({ tiersDetails, subscriptions }: WithSubscriptionProps) => React.JSX.Element
  ) =>
  (props: any) => {
    const navigate = useNavigate();
    const { data: subscriptionsResponse, httpCode, error } = useGetSubscriptions();
    const [unexpectedError, setUnexpectedError] = useState<ErrorResult | null>(null);

    if (error && !unexpectedError) {
      switch (httpCode) {
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        default:
          setUnexpectedError(generalError);
          break;
      }
    }

    if (unexpectedError) {
      return (
        <UnexpectedErrorWrapper justify={'center'} align={'center'}>
          <ErrorWindow
            title={unexpectedError.code || ''}
            information={unexpectedError.message}
            icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
          />
        </UnexpectedErrorWrapper>
      );
    }

    const getCurrentTierType = (productType: ProductType) => {
      const currentTier = subscriptionsResponse?.data?.filter((item) => {
        return item.product_type == productType;
      })[0];

      if (currentTier?.product_type == ProductType.Click) {
        if (currentTier.tier_id === CLICK_FREE_TIER_ID) {
          return CurrentClickTierType.free;
        } else if (currentTier.tier_id === CLICK_PRO_TIER_ID) {
          return CurrentClickTierType.pro;
        }
      }

      if (currentTier?.product_type == ProductType.Cloud) {
        if (currentTier.tier_id === CLOUD_FREE_TIER_ID) {
          return CurrentCloudTierType.free;
        } else if (currentTier.tier_id === CLOUD_PRO_TIER_ID) {
          return CurrentCloudTierType.pro;
        }
      }

      return CUSTOM_TIER;
    };

    const getKeyTierInfo = (productType: ProductType): SubscriptionItem | undefined =>
      subscriptionsResponse?.data?.filter((item) => item.product_type === productType)[0];

    const clickTier = getKeyTierInfo(ProductType.Click);
    const cloudTier = getKeyTierInfo(ProductType.Cloud);

    const tiersDetails = {
      clickTier: {
        info: clickTier,
        currentType: getCurrentTierType(ProductType.Click),
        expirationDate: !clickTier?.is_canceled ? getFormattedDate(clickTier?.expire_at) : '',
        cancelledDate: clickTier?.is_canceled ? getFormattedDate(clickTier?.cancel_at) : ''
      },
      cloudTier: {
        info: cloudTier,
        currentType: getCurrentTierType(ProductType.Cloud),
        expirationDate: !cloudTier?.is_canceled ? getFormattedDate(cloudTier?.expire_at) : '',
        cancelledDate: cloudTier?.is_canceled ? getFormattedDate(cloudTier?.cancel_at) : ''
      }
    };

    return (
      <WrappedComponent
        {...props}
        tiersDetails={tiersDetails}
        subscriptions={subscriptionsResponse}
      />
    );
  };
