import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SvgIcon } from '@make-software/cspr-ui';
import NotFoundIcon from 'assets/logos/not_found.svg';
import { BLANK_LAYOUT } from 'app/constants';
import CSPRBuildLogoOriginal from 'assets/logos/cspr-build-logo-original.svg';
import UnauthorizedLayout from 'app/layouts/unauthorized-layout';
import { InformationWindow } from 'app/components/common/information-window/information-window';
import { HOME_PATH } from 'app/route/paths';

const StyledSvg = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    marginTop: '20px'
  })
);

const StyledNotFound = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    marginBottom: '25px'
  })
);

export const NotFound = () => {
  const navigate = useNavigate();

  const handleHomePageRedirect = () => {
    navigate(HOME_PATH);
  };
  return (
    <UnauthorizedLayout title="Not found" type={BLANK_LAYOUT}>
      <StyledSvg src={CSPRBuildLogoOriginal} width={249} height={44} />

      <InformationWindow
        bodyImg={<StyledNotFound src={NotFoundIcon} width={195} height={119} />}
        title="Oops! Page Not Found"
        information="The page you're looking for seems to have disappeared. Our team is on the lookout."
        onConfirm={handleHomePageRedirect}
        confirmLabel="Return to Homepage"
      />
    </UnauthorizedLayout>
  );
};
