import { GetResponseType, Pagination } from 'app/build-api/types';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import useApi from 'app/build-api/hooks/useApi';
import { CloudFiltersState } from './filter';
import { getSearchParamsFromCloudFilters } from 'app/components/utils/url-utils';
import { decodeJWT } from 'app/components/utils/utils';

export interface CloudLogItem {
  '@timestamp': string;
  auth: string;
  api: number;
  endpoint: string;
  status: number;
  size: number;
  duration: number;
}

export interface GetCloudLogResponse {
  item_count: number;
  page_count: number;
  data: CloudLogItem[] | null;
}

export const getCloudLogs = async (
  pagination: Pagination,
  filters: CloudFiltersState
): Promise<GetResponseType<GetCloudLogResponse>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));

  const searchParams = new URLSearchParams();
  const queryParams = getSearchParamsFromCloudFilters(searchParams, filters);

  searchParams.append('page', pagination.currentPage.toString());
  searchParams.append('size', pagination.pageSize.toString());

  return await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/cloud-logs?${queryParams.toString()}`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
};
