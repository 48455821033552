import { GetResponseType } from '../types';
import useApi from '../hooks/useApi';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import { decodeJWT } from 'app/components/utils/utils';
import { ProductType } from '../subscription/subscription';

export interface CreateCheckoutSessionRequestType {
  tier_id: number;
  tier_type: ProductType;
}

export interface CreateCheckoutSessionResponseType {
  url: string;
}

export const createCheckoutSession = async (
  requestData: CreateCheckoutSessionRequestType
): Promise<GetResponseType<CreateCheckoutSessionResponseType>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));

  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/payments/checkout`,
    'POST',
    requestData,
    {},
    WITH_AUTH
  );

  return {
    data,
    httpCode,
    error,
    loading
  };
};
