import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  BodyText,
  Button,
  FlexColumn,
  FlexRow,
  HeaderText,
  Input,
  InputValidationType,
  useMatchMedia
} from '@make-software/cspr-ui';
import { ROW_GAP_LG, ROW_GAP_MD } from 'app/constants';
import { invalidEmailMessage, validateEmail } from 'app/validators';
import { ForgotPasswordWindowProps } from './types';
import { Link } from 'react-router-dom';
import { SIGN_IN_PATH, SIGN_UP_PATH } from 'app/route/paths';

const ModalContainer = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    width: ['400px', '440px', '576px'],
    background: theme.styleguideColors.backgroundPrimary,
    borderColor: theme.styleguideColors.backgroundPrimary
  })
);

const ModalContentWrapper = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    gap: '40px',
    padding: ['20px', '48px', '48px']
  })
);

const StyledInformationText = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    marginTop: '8px',
    color: theme.styleguideColors.contentSecondary
  })
);

const ButtonsContainer = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    flexDirection: ['column', 'row', 'row']
  })
);

const StyledRecaptcha = styled(ReCAPTCHA)<{ recaptchaError?: string }>(
  ({ theme, recaptchaError }) =>
    theme.withMedia({
      'div div > div': {
        border: recaptchaError && `1px solid ${theme.styleguideColors.borderRed}`,
        height: recaptchaError && '76px'
      }
    })
);

const RecaptchaErrorText = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    fontSize: '11px',
    position: 'relative',
    bottom: '42px',
    height: '0',
    color: theme.styleguideColors.borderRed
  })
);
export const ForgotPasswordWindow = ({
  confirmLabel,
  confirmColor,
  onConfirm,
  information,
  disableConfirm,
  title,
  reCaptchaError,
  emailNotFoundError
}: ForgotPasswordWindowProps) => {
  const [email, setEmail] = useState<string>('');
  const [reCaptcha, setReCaptcha] = useState<string>('');
  const [reCaptchaErrorText, setReCaptchaErrorText] = useState<string>('');

  const [formErrors, setFormErrors] = useState<Record<'email', string | null>>({
    email: ''
  });

  useEffect(() => {
    if (emailNotFoundError) {
      setFormErrors({
        ...formErrors,
        email: emailNotFoundError
      });
    }
  }, [emailNotFoundError]);

  const screenType = useMatchMedia(['mobile', 'tablet', 'desktop'], []);
  const handleOnSubmit = () => {
    if (!email.length) {
      setFormErrors({
        ...formErrors,
        email: 'Please enter email address'
      });

      return;
    }

    if (!reCaptcha) {
      setReCaptchaErrorText('Please fill reCaptcha');
    }

    const validForm = !formErrors.email && reCaptcha;
    validForm && onConfirm(email, reCaptcha);
  };

  const handleOnBlur = () => {
    setFormErrors({
      ...formErrors,
      email: !validateEmail(email) ? invalidEmailMessage : ''
    });
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleReCaptcha = (value: any) => {
    setReCaptcha(value);
    setReCaptchaErrorText('');
  };

  return (
    <ModalContainer>
      <ModalContentWrapper>
        <FlexRow direction="column">
          <HeaderText size={3} scale="md">
            {title}
          </HeaderText>
          <StyledInformationText size={3} scale="sm">
            {information}
          </StyledInformationText>
        </FlexRow>

        <FlexRow gap={ROW_GAP_MD} direction="column">
          <Input
            value={email}
            label={<BodyText size={1}>Email</BodyText>}
            validationType={InputValidationType.email}
            onChange={handleEmail}
            error={!!formErrors.email}
            validationText={formErrors.email}
            onBlur={handleOnBlur}
          />
        </FlexRow>

        <FlexRow gap={screenType === 'mobile' ? ROW_GAP_MD : ROW_GAP_LG} direction={'column'}>
          <StyledRecaptcha
            recaptchaError={reCaptchaError || reCaptchaErrorText}
            sitekey={`${process.env.REACT_APP_SITE_KEY}`}
            onChange={handleReCaptcha}
          />
          {(reCaptchaError || reCaptchaErrorText) && (
            <RecaptchaErrorText size={3}>{reCaptchaError || reCaptchaErrorText}</RecaptchaErrorText>
          )}
        </FlexRow>

        <ButtonsContainer justify={'space-between'}>
          {confirmLabel && (
            <Button
              color={confirmColor === 'red' ? 'primaryRed' : 'primaryBlue'}
              onClick={handleOnSubmit}
              disabled={disableConfirm}
            >
              {confirmLabel}
            </Button>
          )}
        </ButtonsContainer>

        <FlexRow direction="column" gap={16}>
          <BodyText size={3}>
            Don’t have an account yet?{' '}
            <Link to={SIGN_UP_PATH}>
              <BodyText size={1}>Sign up for free →</BodyText>
            </Link>
          </BodyText>
          <BodyText size={3}>
            Already have an account?{' '}
            <Link to={SIGN_IN_PATH}>
              <BodyText size={1}>Log in →</BodyText>
            </Link>
          </BodyText>
        </FlexRow>
      </ModalContentWrapper>
    </ModalContainer>
  );
};
