import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { SignUpForm } from './components/sign-up-form';
import UnauthorizedLayout from 'app/layouts/unauthorized-layout';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { SEND_CONFIRM_EMAIL_PATH } from 'app/route/paths';
import { GENERAL_ERROR_DESCRIPTION, GENERAL_ERROR_MESSAGE, statusCode } from 'app/constants';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import { SvgIcon } from '@make-software/cspr-ui';
import NotFoundIcon from 'assets/logos/no-found.svg';
import { SignUpRequestData } from 'app/build-api';

export const SignUp = () => {
  const navigate = useNavigate();
  const [expectedError, setExpectedError] = useState<string>('');
  const [unexpectedError, setUnexpectedError] = useState<ErrorResult | null>(null);
  const [recaptchaError, setRecaptchaError] = useState<string>('');

  const handleSubmit = async (data: SignUpRequestData, reCaptchaToken: string) => {
    const response = await buildApi.signUp(data, reCaptchaToken);

    if (response.error) {
      const err = {
        code: GENERAL_ERROR_MESSAGE,
        message: GENERAL_ERROR_DESCRIPTION
      };
      switch (response.httpCode) {
        case statusCode.invalid_input_error:
        case statusCode.validation_error:
          setUnexpectedError(response.error);
          Sentry.captureException(response.error);
          break;
        case statusCode.authentication_error:
          setRecaptchaError(response.error?.message);
          break;
        case statusCode.conflict_error:
          setExpectedError(response.error?.message);
          break;
        case statusCode.unexpected_error:
          setUnexpectedError(err);
          break;
        default:
          setUnexpectedError(err);
          break;
      }
    }

    if (response.data?.token) {
      navigate(`${SEND_CONFIRM_EMAIL_PATH}?email=${data.email}&token=${response.data.token}`);
    }
  };

  if (unexpectedError) {
    return (
      <UnauthorizedLayout title="Registration">
        <ErrorWindow
          title={unexpectedError.code}
          information={unexpectedError.message}
          icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
        />
      </UnauthorizedLayout>
    );
  }
  return (
    <UnauthorizedLayout title="Registration">
      <SignUpForm
        onSubmit={handleSubmit}
        recaptchaError={recaptchaError}
        expectedError={expectedError}
      />
    </UnauthorizedLayout>
  );
};
