import React, { useEffect, useState } from 'react';
import {
  ClickFilterState,
  ClickGroupBy,
  ClickUniqueAccountsCountGraphFilter,
  GetClickKeyResponseType,
  Period,
  UniqueHistogramResponse
} from 'app/build-api';
import { buildApi, DefaultResponse, GetResponseType } from 'app/build-api/types';
import { EntityCountChart } from '../../common/charts/entity-count-chart';
import { FailedToFetch } from 'app/components/table/table-error';
import {
  ChartLegendsType,
  getChartValuesDataWithLabels,
  getUniqueCount,
  prepareAppListDataToChartLegends,
  prepareProvidersListDataToChartLegends
} from '../../common/charts/chart-formatters';
import { ClickProviders } from '../constants/constants';
import { useSearchParams } from 'react-router-dom';
import { ChartGraphFilter, UniqueAccountsChartHeader } from '../../common/charts/chart-header';

const getClickUniqueGraphFiltersFromSearchParams = (
  searchParams: URLSearchParams
): ClickUniqueAccountsCountGraphFilter => {
  return {
    clickUniqueAccountsCountGroupBy:
      (searchParams.get('clickUniqueAccountsCountGroupBy') as ClickGroupBy) || ClickGroupBy.App,
    clickUniqueAccountsCountPeriod:
      (searchParams.get('clickUniqueAccountsCountPeriod') as Period) || Period.Day
  };
};

const getSearchParamsFromUniqueGraphFilters = (
  searchParams: URLSearchParams,
  filters: ClickUniqueAccountsCountGraphFilter
): URLSearchParams => {
  searchParams.set('clickUniqueAccountsCountGroupBy', filters.clickUniqueAccountsCountGroupBy);
  searchParams.set('clickUniqueAccountsCountPeriod', filters.clickUniqueAccountsCountPeriod);
  return searchParams;
};

export interface UniqueCountContainerProps {
  filters: ClickFilterState;
  appsList: GetResponseType<GetClickKeyResponseType>;
}

export const UniqueAccountContainer = ({ filters, appsList }: UniqueCountContainerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [graphFilters, setGraphFilters] = useState<ClickUniqueAccountsCountGraphFilter>(
    getClickUniqueGraphFiltersFromSearchParams(searchParams)
  );
  const [histogramData, setHistogramData] =
    useState<GetResponseType<UniqueHistogramResponse>>(DefaultResponse);
  const [chartLegendsData, setChartLegendsData] = useState<ChartLegendsType[]>([]);

  useEffect(() => {
    (async () => {
      const res = await buildApi.getUniqueAccountsHistogram(filters, graphFilters);
      setHistogramData(res);
    })();
  }, [filters, graphFilters]);

  const handleGraphFiltersChange = (updatedFilters: Partial<ChartGraphFilter>) => {
    const newState = {
      ...graphFilters,
      ...(updatedFilters.period && {
        clickUniqueAccountsCountPeriod: updatedFilters.period
      }),
      ...(updatedFilters.groupBy && {
        clickUniqueAccountsCountGroupBy: updatedFilters.groupBy
      })
    };
    setSearchParams((prevParams) => {
      const currentParams = new URLSearchParams(prevParams);
      return getSearchParamsFromUniqueGraphFilters(
        currentParams,
        newState as ClickUniqueAccountsCountGraphFilter
      );
    });
    setGraphFilters(newState as ClickUniqueAccountsCountGraphFilter);
  };

  const formattedAppListData = prepareAppListDataToChartLegends(appsList?.data?.data || []);
  const formattedProvidersListData = prepareProvidersListDataToChartLegends(ClickProviders || []);

  useEffect(() => {
    switch (graphFilters.clickUniqueAccountsCountGroupBy) {
      case ClickGroupBy.App:
        setChartLegendsData(formattedAppListData);
        break;
      case ClickGroupBy.Provider:
        setChartLegendsData(formattedProvidersListData);
        break;
      default:
        setChartLegendsData(formattedAppListData);
        break;
    }
  }, [
    graphFilters.clickUniqueAccountsCountGroupBy,
    graphFilters.clickUniqueAccountsCountPeriod,
    appsList
  ]);

  const yAxisDataById = histogramData.data?.histogram ? getUniqueCount(histogramData.data) : [];

  const uniqueAccountsChartData = getChartValuesDataWithLabels(chartLegendsData, yAxisDataById);

  const loading = appsList.loading || histogramData.loading;
  const error = appsList.error || histogramData.error;

  return (
    <EntityCountChart
      chartHeader={
        <UniqueAccountsChartHeader
          totalRequests={histogramData?.data?.total || 0}
          graphFilters={graphFilters}
          onFiltersChange={handleGraphFiltersChange}
        />
      }
      title={'Unique accounts Count'}
      yAxisLabelText={'Unique accounts Count'}
      data={uniqueAccountsChartData}
      loading={loading}
      error={error ? <FailedToFetch error={error} /> : null}
    />
  );
};
