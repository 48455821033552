import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useGetInvitedUsers } from 'app/build-api/hooks/useGetInvitedUsers';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { AUTH_TOKEN, generalError, jsonErrorCodes, statusCode } from 'app/constants';
import SuccessEntityModal from 'app/components/common/success-entity-modal/success-entity-modal';
import { FlexRow, SvgIcon } from '@make-software/cspr-ui';
import CreatedSuccessIcon from 'assets/logos/success-entity.svg';
import {
  InviteUserRequestType,
  InviteUserResponseType,
  UpdateUserRequestType
} from 'app/build-api';
import { SIGN_IN_PATH } from 'app/route/paths';
import { UserList } from './show/user-list';
import InviteUserWindow from './create/invite-user-window';
import { PreloadTableWindow } from 'app/components/common/preload-table-window/preload-table-window';
import FolderIcon from 'assets/logos/folder.svg';
import EditUserWindow from './edit/edit-user-window';

export interface ExpectedErrors {
  duplicatedEmailError: string;
  userLimitExceededError: string;
}

const Container = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginTop: '48px'
  })
);

export const UserContainer = () => {
  const { data: users, error: fetchingUsersError, refetch, httpCode } = useGetInvitedUsers(1, 5);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [userInvited, setUserInvited] = useState<string | null>(null);
  const [userToEdit, setUserToEdit] = useState<InviteUserResponseType>();
  const [expectedError, setExpectedError] = useState<ExpectedErrors>({} as ExpectedErrors);
  const [unexpectedError, setUnexpectedError] = useState<ErrorResult | null>(null);
  const [userData, setUserData] = useState<InviteUserResponseType[] | null>();
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const noUsers = !userData?.length && !loading && !fetchingUsersError && !unexpectedError;

  useEffect(() => {
    setUserData(users?.data);
    if (users?.data) {
      setLoading(false);
    }
  }, [users]);

  if (fetchingUsersError && !unexpectedError) {
    switch (httpCode) {
      case statusCode.authentication_error:
        localStorage.removeItem(AUTH_TOKEN);
        navigate(SIGN_IN_PATH);
        break;
      default:
        setLoading(false);
        setUnexpectedError(generalError);
        break;
    }
  }

  const handleOpenInviteUserWindow = () => {
    setOpenModal(true);
    setUnexpectedError(null);
    setExpectedError({} as ExpectedErrors);
  };

  const handleCloseInviteUserWindow = () => {
    setOpenModal(false);
    setExpectedError({} as ExpectedErrors);
  };

  const handleOpenEditUserWindow = (user: InviteUserResponseType) => {
    setOpenEditModal(true);
    setUserToEdit(user);
  };

  const handleCloseEditUserWindow = () => {
    setOpenEditModal(false);
    setExpectedError({} as ExpectedErrors);
  };

  const handleCloseSuccessModal = () => {
    setUserInvited(null);
  };

  const updateUserList = (userId: string) => {
    setUserInvited(userId);
    refetch && refetch();
  };

  const handleInviteUser = async (data: InviteUserRequestType) => {
    const response = await buildApi.inviteUser(data);

    if (response.data?.id) {
      handleCloseInviteUserWindow();
      setUserInvited(response.data.id);

      setUserData((prevState: any) => [...prevState, response.data]);

      if (noUsers) {
        updateUserList(response.data.id);
      }
    }
    if (response.error) {
      switch (response.httpCode) {
        case statusCode.invalid_input_error:
        case statusCode.validation_error:
          setUnexpectedError(response.error);
          Sentry.captureException(response.error);
          break;
        case statusCode.access_denied_error:
          if (response.error.code === jsonErrorCodes.usersLimitExceeded) {
            setExpectedError({ ...expectedError, userLimitExceededError: response.error.message });
          } else {
            setUnexpectedError(response.error);
            Sentry.captureException(response.error);
          }
          break;
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        case statusCode.conflict_error:
          setExpectedError({ ...expectedError, duplicatedEmailError: response.error.message });
          break;
        case statusCode.unexpected_error:
          setUnexpectedError(generalError);
          break;
        default:
          setUnexpectedError(generalError);
          break;
      }
    }
  };

  const handleUpdateUser = async (id: string, user: UpdateUserRequestType) => {
    const response = await buildApi.updateUser(id, user);

    if (response.data?.id) {
      handleCloseEditUserWindow();
      setUserData((prevState: any) => [
        ...prevState.map((u: InviteUserResponseType) => {
          if (u.id === response.data?.id) {
            return {
              ...u,
              is_active: response.data.is_active,
              full_name: response.data.full_name,
              access_list: response.data.access_list,
              status_description: response.data.status_description
            };
          }
          return u;
        })
      ]);
    }

    if (response.error) {
      switch (response.httpCode) {
        case statusCode.invalid_input_error:
        case statusCode.validation_error:
        case statusCode.access_denied_error:
        case statusCode.not_found_error:
          setUnexpectedError(response.error);
          Sentry.captureException(response.error);
          break;
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        case statusCode.unexpected_error:
          setUnexpectedError(generalError);
          break;
        default:
          setUnexpectedError(generalError);
          break;
      }
    }
  };

  return (
    <>
      {userInvited && (
        <SuccessEntityModal
          isOpen={Boolean(userInvited)}
          icon={<SvgIcon src={CreatedSuccessIcon} height={120} width={212} />}
          title="You’ve invited the new user"
          information="It was added to the list of your users."
          onDismiss={handleCloseSuccessModal}
        />
      )}

      {noUsers ? (
        <PreloadTableWindow
          icon={<SvgIcon src={FolderIcon} height={120} width={224} />}
          title="You haven’t invited any users yet"
          windowTitle="Users"
          subtitle="All users will appear here once you create them"
          onSubmitTitle="Invite user"
          onSubmit={handleOpenInviteUserWindow}
        />
      ) : (
        <Container justify={'center'} align={'center'}>
          <UserList
            loading={loading}
            users={userData || null}
            pageCount={users?.page_count || 1}
            error={unexpectedError}
            refetch={refetch}
            onOpenInviteUserWindow={handleOpenInviteUserWindow}
            onOpenEditUserWindow={handleOpenEditUserWindow}
          />
        </Container>
      )}

      <InviteUserWindow
        isOpen={openModal}
        confirmLabel="Invite"
        onConfirm={handleInviteUser}
        title="Invite new user"
        dismissLabel="Cancel"
        expectedError={expectedError}
        unexpectedError={unexpectedError}
        onDismiss={handleCloseInviteUserWindow}
      />

      {userToEdit && (
        <EditUserWindow
          userToEdit={userToEdit}
          isOpen={openEditModal}
          title={'User Settings'}
          confirmLabel={'Save'}
          onUpdate={handleUpdateUser}
          onDismiss={handleCloseEditUserWindow}
          dismissLabel={'Cancel'}
          expectedError={expectedError}
          unexpectedError={unexpectedError}
        />
      )}
    </>
  );
};
