import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FlexBox,
  FlexColumn,
  DropdownEventValue,
  MultiSelectDropdown
} from '@make-software/cspr-ui';
import * as Sentry from '@sentry/react';
import { GetResponseType } from 'app/build-api/types';
import { AUTH_TOKEN, statusCode } from 'app/constants';
import { SIGN_IN_PATH } from 'app/route/paths';
import { Calendar, CalendarRef } from 'app/components/common/calendar/calendar';
import { SkeletonLoader } from 'app/components/common/skeleton-loader/skeleton-loader';
import {
  ClickFilterState,
  ClickInputFilters,
  DateRange,
  DateRangeKey,
  GetClickKeyResponseType
} from 'app/build-api';
import {
  formatAppDataToMultiSelect,
  formatProvidersDataToMultiSelect
} from './utils/miltiselect-formatters';
import { ClickProviders } from './constants/constants';
import {
  getDefaultFilters,
  getDefaultSelectedFilters,
  getPreselectedFilters,
  SelectedClickFiltersState
} from './utils/inital-filter-state';
import { SelectedCloudFilterType } from '../cloud/utils/initial-filter-state-getter';

export const StyledContainer = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    padding: ['0 16px', '0 150px', '0 200px', '0 280px'],
    '& > * + *': {
      marginTop: [28, 32, 40, 40]
    }
  })
);

const LoadingContainer = styled(StyledContainer)(() => ({
  marginTop: 20
}));

const StyledButtonContainer = styled(FlexBox)(({ theme }) =>
  theme.withMedia({
    flexDirection: ['column', 'row', 'row', 'row'],
    marginTop: [28, 28, 40, 40],
    '& > *': {
      width: ['100%', 'auto', 'auto', 'auto']
    },
    '& > * + *': {
      marginLeft: [0, 16, 16, 16],
      marginTop: [16, 0, 0, 0]
    }
  })
);

const StyledButton = styled(Button)(() => ({
  height: '36px'
}));

export interface FiltersBlockProps {
  filters: ClickFilterState;
  keyList: GetResponseType<GetClickKeyResponseType>;
  onUpdateFilters: (updatedFilters: Partial<ClickFilterState>) => void;
}

export const FiltersBlock: React.FC<FiltersBlockProps> = ({
  filters,
  keyList,
  onUpdateFilters
}) => {
  const calendarRef = useRef<CalendarRef>();
  const apps = formatAppDataToMultiSelect(keyList.data?.data || []);
  const providers = formatProvidersDataToMultiSelect(ClickProviders);

  const preselectedFilters = getPreselectedFilters({ filters, apps, providers });
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedClickFiltersState>(preselectedFilters);

  const getSelectedItemIds = (selectedItems: SelectedCloudFilterType[]) =>
    selectedItems.map((item) => item.id.toString());

  const handleSelectItems = (event: DropdownEventValue, key: ClickInputFilters) => {
    const selectedItems = event.target.value;

    if (selectedItems) {
      setSelectedFilters({
        ...selectedFilters,
        [key]: selectedItems
      });
    }
  };

  const handleRemoveAllItems = (key: ClickInputFilters) => {
    setSelectedFilters({
      ...selectedFilters,
      [key]: []
    });
  };

  const handleSelectDateRange = (newDateRange: DateRange) => {
    onUpdateFilters({ ...filters, dateRange: newDateRange });
  };

  const handleApplyFilters = () => {
    onUpdateFilters({
      ...filters,
      apps: getSelectedItemIds(selectedFilters[ClickInputFilters.apps]),
      providers: getSelectedItemIds(selectedFilters[ClickInputFilters.providers])
    });
  };

  const handleResetFilters = () => {
    if (calendarRef.current) {
      calendarRef.current.resetState();
    }
    setSelectedFilters(getDefaultSelectedFilters());
    onUpdateFilters(getDefaultFilters());
  };

  return (
    <StyledContainer>
      <Calendar
        ref={calendarRef}
        dateRange={filters[DateRangeKey]}
        onDateRangeChange={handleSelectDateRange}
      />

      <MultiSelectDropdown
        label={'Apps'}
        value={selectedFilters[ClickInputFilters.apps]}
        placeholder={'Select Apps'}
        disabled={false}
        items={apps}
        onSelect={(event) => handleSelectItems(event, ClickInputFilters.apps)}
        onClearAllItems={(event) => handleRemoveAllItems(ClickInputFilters.apps)}
      />

      <MultiSelectDropdown
        label={'Providers'}
        placeholder={'Select Providers'}
        disabled={false}
        value={selectedFilters[ClickInputFilters.providers]}
        items={providers}
        onSelect={(event) => handleSelectItems(event, ClickInputFilters.providers)}
        onClearAllItems={(event) => handleRemoveAllItems(ClickInputFilters.providers)}
      />
      <StyledButtonContainer>
        <StyledButton width={'176'} color={'primaryBlue'} onClick={handleApplyFilters}>
          Apply Filters
        </StyledButton>
        <StyledButton width={'176'} color={'secondaryBlue'} onClick={handleResetFilters}>
          Reset Filters
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export const FiltersBlockContainer: React.FC<FiltersBlockProps> = (props) => {
  const navigate = useNavigate();

  if (props.keyList.loading) {
    return (
      <LoadingContainer>
        <SkeletonLoader height={'40px'} />
        <SkeletonLoader height={'40px'} />
        <SkeletonLoader height={'40px'} />
      </LoadingContainer>
    );
  }

  if (props.keyList.error) {
    switch (props.keyList.httpCode) {
      case statusCode.invalid_input_error:
      case statusCode.access_denied_error:
      case statusCode.validation_error:
        Sentry.captureException(props.keyList.error);
        break;
      case statusCode.authentication_error:
        localStorage.removeItem(AUTH_TOKEN);
        navigate(SIGN_IN_PATH);
        break;
    }
  }
  return <FiltersBlock {...props} />;
};
