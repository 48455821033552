import { CreatedEntityResponseType, GetResponseType } from '../types';
import useApi from '../hooks/useApi';
import { NO_AUTH } from 'app/constants';

export const forgotPassword = async (
  email: string,
  reCaptchaToken: string
): Promise<GetResponseType<CreatedEntityResponseType>> => {
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/auth/forgot-password`,
    'POST',
    { email: email },
    {},
    NO_AUTH,
    undefined,
    reCaptchaToken
  );

  return {
    data,
    httpCode,
    error,
    loading
  };
};
