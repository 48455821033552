import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import UnauthorizedLayout from 'app/layouts/unauthorized-layout';
import EmailConfirmationIcon from 'assets/logos/updating-account.svg';
import NotFoundIcon from 'assets/logos/no-found.svg';
import { BodyText, SvgIcon } from '@make-software/cspr-ui';
import { InformationWindow } from 'app/components/common/information-window/information-window';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { HOME_PATH, SIGN_IN_PATH } from 'app/route/paths';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import { useOnMountUnsafe } from 'hooks/useOnMountUnsafe';
import {
  AUTH_TOKEN,
  GENERAL_ERROR_DESCRIPTION,
  GENERAL_ERROR_MESSAGE,
  statusCode,
  TOKEN_EXPIRED_ERROR
} from 'app/constants';

const TOKEN = 'token';

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ErrorResult | null>(null);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);

  const token = searchParams.get(TOKEN) || '';

  const verifyingEmail = async () => {
    const response = await buildApi.verifyEmail(token);
    setLoading(response.loading);

    if (response.data?.token) {
      setIsEmailVerified(true);
    }

    if (response.error) {
      const err = {
        code: GENERAL_ERROR_MESSAGE,
        message: GENERAL_ERROR_DESCRIPTION
      };

      switch (response.httpCode) {
        case statusCode.authentication_error:
          if (response.error?.code === TOKEN_EXPIRED_ERROR) {
            setError({
              ...response.error,
              description: (
                <>
                  Your token has expired. <br />
                  To get the new one, please go to the{' '}
                  <Link to={SIGN_IN_PATH}>
                    <BodyText size={1}>Sign in</BodyText>
                  </Link>{' '}
                  page
                </>
              )
            });
            localStorage.removeItem(AUTH_TOKEN);
          } else {
            setError(response.error);
            Sentry.captureException(response.error);
          }
          break;
        case statusCode.access_denied_error:
        case statusCode.validation_error:
          setError(response.error);
          Sentry.captureException(response.error);
          break;
        case statusCode.unexpected_error:
          setError(err);
          break;
        default:
          setError(err);
          break;
      }
    }
  };

  useOnMountUnsafe(verifyingEmail);

  const handleRedirection = () => {
    navigate(HOME_PATH);
  };

  if (error) {
    return (
      <UnauthorizedLayout title="Email confirmation">
        <ErrorWindow
          title={error.code}
          information={error.description ? error.description : error.message}
          icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
        />
      </UnauthorizedLayout>
    );
  }

  return (
    <UnauthorizedLayout title="Email confirmation">
      {isEmailVerified && (
        <InformationWindow
          title={'Thanks for confirming your email!'}
          confirmLabel="Start using CSPR.build"
          confirmColor="red"
          onConfirm={handleRedirection}
          information="You can explore and enjoy CSPR.build now."
          bodyImg={<SvgIcon src={EmailConfirmationIcon} width={219} height={125} />}
          disableConfirm={loading}
        />
      )}
    </UnauthorizedLayout>
  );
};
