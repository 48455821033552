import React from 'react';
import { Badge, BodyText, FlexRow, SvgIcon } from '@make-software/cspr-ui';
import styled from 'styled-components';
import ImportantIcon from 'assets/icons/ic-info-important.svg';
import { BADGE_COLOR, FREE_TIER, PRO_TIER } from 'app/constants';
import { SUBSCRIPTIONS_PATH } from 'app/route/paths';
import { checkGrantedAccess } from 'app/components/utils/utils';
import { AccessItem, ProductType } from 'app/build-api';
import { StyledLink } from 'app/components/home/components/create-modal-styles';

const StyledWarningWrapper = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    backgroundColor: '#FDF5DACC',
    padding: '16px'
  })
);

const StyledSvg = styled(SvgIcon)(({ theme }) =>
  theme.withMedia({
    marginRight: '8px'
  })
);

const BadgeWrapper = styled.div(({ theme }) =>
  theme.withMedia({
    marginLeft: '8px',
    display: 'inline-block',
    bottom: '3px',
    position: 'relative'
  })
);

const StyledBadgeTitle = styled.div(({ theme }) =>
  theme.withMedia({
    textTransform: 'uppercase'
  })
);

interface TierTypeMarkProps {
  currentTire: string;
  productType: ProductType;
}

export const TierTypeMark = ({ currentTire, productType }: TierTypeMarkProps) => {
  return (
    <>
      {currentTire === FREE_TIER ? (
        <StyledWarningWrapper align={'center'}>
          <StyledSvg src={ImportantIcon} color={'yellow'} />
          {checkGrantedAccess(AccessItem.TierManagement) ? (
            <BodyText size={2} scale={'xs'}>
              "Free" tier comes with certain restrictions,{' '}
              <StyledLink to={SUBSCRIPTIONS_PATH} state={{ activeTab: productType }}>
                Upgrade your plan{' '}
              </StyledLink>
              to get more features.
            </BodyText>
          ) : (
            <BodyText size={2} scale={'xs'}>
              The "Free" tier comes with certain restrictions, please ask an organization owner to
              upgrade the plan.
            </BodyText>
          )}
        </StyledWarningWrapper>
      ) : currentTire === PRO_TIER ? (
        <BadgeWrapper>
          <Badge
            label={<StyledBadgeTitle>{currentTire}</StyledBadgeTitle>}
            variation={BADGE_COLOR}
          />
        </BadgeWrapper>
      ) : (
        <></>
      )}
    </>
  );
};
