import React from 'react';
import {
  ClickKeyRequestType,
  ClickKeyResponseType,
  CloudKeyRequestType,
  CloudKeyResponseType,
  createCheckoutSession,
  CreateCheckoutSessionRequestType,
  CreateCheckoutSessionResponseType,
  createClickKey,
  createCloudKey,
  createCustomerPortal,
  CreateCustomerPortalResponseType,
  forgotPassword,
  GetClickKeyResponseType,
  getClickKeys,
  GetCloudKeyResponseType,
  getCloudKeys,
  getInvitedUsers,
  GetInvitedUsersResponseType,
  GetSubscriptionResponseType,
  getSubscriptions,
  inviteUser,
  InviteUserRequestType,
  InviteUserResponseType,
  requestCustomTierMessage,
  RequestCustomTierMessageRequestType,
  resetPassword,
  sendConfirmEmail,
  signIn,
  SignInRequestData,
  signUp,
  SignUpRequestData,
  updateClickKey,
  UpdateClickKeyRequestType,
  updateCloudKey,
  UpdateCloudKeyRequestType,
  updateUser,
  UpdateUserRequestType,
  verifyEmail,
  GetCloudLogResponse,
  getCloudLogs,
  CloudApiItem,
  getCloudApiList,
  CloudFiltersState,
  CloudRequestCountFilter,
  CountHistogramResponse,
  ClickFilterState,
  UniqueHistogramResponse,
  ClickUniqueAccountsCountGraphFilter,
  ClickSessionCountGraphFilter
} from 'app/build-api';
import {
  CloudEndpointItem,
  getCloudAutocompleteEndpointsList,
  getCloudPopularEndpointsList
} from './statistics/cloud-logs/endpoints-list';
import { getCloudLogsRequestCountHistogram } from './statistics/cloud-logs/request-count-histogram';
import { getClickSessionCountHistogram } from './statistics/click-logs/session-count-histogram';
import { getUniqueAccountsHistogram } from './statistics/click-logs/unique-accounts-histogram';

export interface ErrorResult {
  code: string;
  message: string;
  description?: string | React.ReactElement;
}

export interface GetResponseType<Entity extends any> {
  loading: boolean;
  error: ErrorResult | null;
  httpCode?: number;
  data: Entity | null;
}

export interface TokenResponseType {
  token: string;
}

export interface CreatedEntityResponseType {
  code: number;
}

export interface Pagination {
  currentPage: number;
  pageSize: number;
}

export const DefaultResponse = {
  data: null,
  error: null,
  httpCode: undefined,
  loading: true
};

type BuildApi = {
  signUp: (
    data: SignUpRequestData,
    reCaptchaToken: string
  ) => Promise<GetResponseType<TokenResponseType>>;
  signIn: (data: SignInRequestData) => Promise<GetResponseType<TokenResponseType>>;
  sendConfirmEmail: (token: string) => Promise<GetResponseType<any>>;
  verifyEmail: (token: string) => Promise<GetResponseType<TokenResponseType>>;
  forgotPassword: (
    email: string,
    reCaptchaToken: string
  ) => Promise<GetResponseType<CreatedEntityResponseType>>;
  resetPassword: (password: string, token: string) => Promise<GetResponseType<TokenResponseType>>;
  inviteUser: (payload: InviteUserRequestType) => Promise<GetResponseType<InviteUserResponseType>>;
  getInvitedUsers: (
    page?: number,
    pageSize?: number
  ) => Promise<GetResponseType<GetInvitedUsersResponseType>>;
  updateUser: (
    userId: string,
    payload: UpdateUserRequestType
  ) => Promise<GetResponseType<InviteUserResponseType>>;
  createCloudKey: (payload: CloudKeyRequestType) => Promise<GetResponseType<CloudKeyResponseType>>;
  getCloudKeys: (
    page?: number,
    pageSize?: number,
    isMaskedHash?: boolean
  ) => Promise<GetResponseType<GetCloudKeyResponseType>>;
  updateCloudKey: (
    id: string,
    payload: UpdateCloudKeyRequestType
  ) => Promise<GetResponseType<CloudKeyResponseType>>;
  createClickKey: (payload: ClickKeyRequestType) => Promise<GetResponseType<ClickKeyResponseType>>;
  getClickKeys: (
    page?: number,
    pageSize?: number,
    isMaskedHash?: boolean
  ) => Promise<GetResponseType<GetClickKeyResponseType>>;
  updateClickKey: (
    id: string,
    payload: UpdateClickKeyRequestType
  ) => Promise<GetResponseType<ClickKeyResponseType>>;
  getSubscriptions: (
    page?: number,
    pageSize?: number
  ) => Promise<GetResponseType<GetSubscriptionResponseType>>;
  createCheckoutSession: (
    data: CreateCheckoutSessionRequestType
  ) => Promise<GetResponseType<CreateCheckoutSessionResponseType>>;
  requestCustomTierMessage: (
    data: RequestCustomTierMessageRequestType
  ) => Promise<GetResponseType<any>>;
  createCustomerPortal: () => Promise<GetResponseType<CreateCustomerPortalResponseType>>;
  getCloudLogs: (
    pagination: Pagination,
    filters: CloudFiltersState
  ) => Promise<GetResponseType<GetCloudLogResponse>>;
  getCloudApiList: () => Promise<GetResponseType<CloudApiItem[]>>;
  getCloudPopularEndpointsList: (
    from: Date,
    to: Date
  ) => Promise<GetResponseType<CloudEndpointItem[]>>;
  getCloudAutocompleteEndpointsList: (
    from: Date,
    to: Date,
    search: string
  ) => Promise<GetResponseType<CloudEndpointItem[]>>;
  getCloudLogsRequestCountHistogram: (
    filters: CloudFiltersState,
    graphFilter: CloudRequestCountFilter
  ) => Promise<GetResponseType<CountHistogramResponse>>;
  getClickSessionCountHistogram: (
    filters: ClickFilterState,
    graphFilter: ClickSessionCountGraphFilter
  ) => Promise<GetResponseType<CountHistogramResponse>>;
  getUniqueAccountsHistogram: (
    filters: ClickFilterState,
    graphFilter: ClickUniqueAccountsCountGraphFilter
  ) => Promise<GetResponseType<UniqueHistogramResponse>>;
};

export const buildApi: BuildApi = {
  signUp,
  signIn,
  sendConfirmEmail,
  verifyEmail,
  forgotPassword,
  resetPassword,
  inviteUser,
  getInvitedUsers,
  updateUser,
  createCloudKey,
  getCloudKeys,
  updateCloudKey,
  updateClickKey,
  createClickKey,
  getClickKeys,
  getSubscriptions,
  createCheckoutSession,
  requestCustomTierMessage,
  createCustomerPortal,
  getCloudLogs,
  getCloudApiList,
  getCloudPopularEndpointsList,
  getCloudAutocompleteEndpointsList,
  getCloudLogsRequestCountHistogram,
  getClickSessionCountHistogram,
  getUniqueAccountsHistogram
};
