import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled, { useTheme } from 'styled-components';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  BodyText,
  Button,
  FlexRow,
  InfoColourIcon,
  SearchableDropdown,
  SvgIcon,
  useClickAway,
  WarningMessage
} from '@make-software/cspr-ui';
import {
  ButtonsContainer,
  centerModalStyles,
  ErrorText,
  ErrorTextContainer,
  ModalContainer,
  StyledCaption,
  StyledCaptionText,
  StyledConfirmButton,
  StyledDismissButton,
  StyledInput
} from 'app/components/home/components/create-modal-styles';
import { checkMaxLength, validateDomain } from 'app/validators';
import { ErrorWindow } from 'app/components/common/error-window/error-window';
import NotFoundIcon from 'assets/logos/no-found.svg';
import { AccessItem, ClickKeyRequestType, ClickKeyStatus, ProductType } from 'app/build-api';
import { CASPER, CASPER_TEST, MAINNET, TESTNET } from 'app/constants';
import { ExpectedErrors } from 'app/components/home/components/click-keys/click-key-container';
import { ErrorResult } from 'app/build-api/types';
import { appActions, communicator } from 'app/communicator';
import { SUBSCRIPTIONS_PATH } from 'app/route/paths';
import { checkGrantedAccess } from 'app/components/utils/utils';

export interface DropdownItem {
  label: string;
  value: string;
}

export interface CreateClickKeyWindowProps {
  isOpen: boolean;
  title: string;
  confirmLabel: string;
  onConfirm: (data: ClickKeyRequestType) => void;
  dismissLabel?: string;
  cloudKeyList: DropdownItem[];
  onDismiss: () => void;
  currentTire: string;
  expectedError: ExpectedErrors;
  unexpectedError?: ErrorResult | null;
  isTierLimitExceeded?: boolean;
  portalClass?: string;
}

const NETWORKS = [
  { value: MAINNET, label: CASPER },
  { value: TESTNET, label: CASPER_TEST }
];

const StyledAddDomain = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    cursor: 'pointer'
  })
);

const StyledButton = styled(Button)(({ theme }) =>
  theme.withMedia({
    height: '30px'
  })
);

const DomainsTitle = styled(BodyText)(({ theme }) =>
  theme.withMedia({
    position: 'relative',
    top: '15px'
  })
);

export const CreateClickKeyWindow = ({
  isOpen,
  title,
  confirmLabel,
  onConfirm,
  dismissLabel,
  onDismiss,
  cloudKeyList,
  unexpectedError,
  expectedError,
  currentTire,
  isTierLimitExceeded,
  portalClass = 'portal'
}: CreateClickKeyWindowProps) => {
  const theme = useTheme();
  const modalStyle = {
    overlay: {
      backgroundColor: theme.styleguideColors.backgroundOverlay,
      zIndex: 15
    },
    content: {
      ...centerModalStyles,
      ...{
        border: 'none',
        backgroundColor: theme.styleguideColors.backgroundPrimary,
        borderTop: `4px solid ${theme.styleguideColors.contentRed}`,
        borderColor: theme.styleguideColors.contentRed,
        boxShadow: '0px 16px 48px rgba(26, 25, 25, 0.2)'
      }
    }
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [keyName, setKeyName] = useState<string>('');
  const [network, setNetwork] = useState<DropdownItem>(NETWORKS[0]);
  const [domains, setDomains] = useState<{ value: string }[]>([{ value: '' }]);
  const [formErrors, setFormErrors] = useState<Record<'keyName' | 'domain', string | null>>({
    keyName: '',
    domain: ''
  });

  const navigate = useNavigate();

  const [linkedCloudKey, setLinkedCloudKey] = useState<DropdownItem>(
    cloudKeyList[0] || { value: '', label: '' }
  );
  const noCloudKeysToLink = !cloudKeyList || !cloudKeyList.length;
  const tierAccessProvided = checkGrantedAccess(AccessItem.TierManagement);
  const capitalizedCurrentTier =
    currentTire && currentTire.charAt(0).toUpperCase() + currentTire.slice(1);

  const clearForm = () => {
    setKeyName('');
    setNetwork(NETWORKS[0]);
    setDomains([{ value: '' }]);
    setFormErrors({
      ...formErrors,
      keyName: '',
      domain: ''
    });
  };

  useEffect(() => {
    setShowModal(isOpen);
    clearForm();
  }, [isOpen]);

  const { ref } = useClickAway({
    callback: () => {
      clearForm();
      onDismiss();
    }
  });

  const handleKeyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setKeyName(val);
  };

  const handleKeyNameOnBlur = () => {
    setFormErrors({
      ...formErrors,
      keyName: !keyName.length ? 'This field is required' : checkMaxLength(keyName, 50)
    });
  };

  const handleAddDomain = () => {
    if (domains.length >= 5) return;
    setDomains([...domains, { value: '' }]);
  };

  const handleDomainOnBlur = (index: number) => {
    const data: any = [...domains];
    const value = data[index].value;
    setFormErrors({
      ...formErrors,
      domain: !value.length ? 'This field is required' : validateDomain(value)
    });
  };

  const handleChangeNetwork = (network: DropdownItem) => {
    setNetwork(network);
  };

  const handleLinkCloudKey = (linkedKey: DropdownItem) => {
    setLinkedCloudKey(linkedKey);
  };

  const handleConfirm = () => {
    if (!keyName.length) {
      setFormErrors({
        ...formErrors,
        keyName: 'This field is required'
      });
      return;
    }

    if (!domains[0].value) {
      setFormErrors({
        ...formErrors,
        domain: 'This field is required'
      });
      return;
    }

    const invalidDomain = domains
      .map((d) => d.value)
      .filter((d) => d)
      .filter((d) => validateDomain(d));

    !formErrors.keyName &&
      !formErrors.domain &&
      !invalidDomain.length &&
      linkedCloudKey.value &&
      onConfirm &&
      onConfirm({
        network: network.value,
        name: keyName,
        domains: domains.map((d) => d.value).filter((d) => d),
        status: ClickKeyStatus.ACTIVE,
        cloud_key_id: linkedCloudKey.value
      });
  };

  const handleOpenCreateCloudKeyWindow = () => {
    communicator.call(appActions.openCreateCloudKeyWindow);
    handleOnDismiss();
  };

  const handleOnDismiss = () => {
    clearForm();
    onDismiss();
  };

  const handleUpgradeTier = () => {
    handleOnDismiss();
    navigate({
      pathname: SUBSCRIPTIONS_PATH,
      search: createSearchParams({
        activeTab: ProductType.Click
      }).toString()
    });
  };

  const handleChangeDomain = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    const data: any = [...domains];
    data[index].value = value;
    setDomains(data);
  };
  const handleDelete = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const data = [...domains];
    data.splice(index, 1);
    setDomains(data);
  };

  return (
    <>
      {showModal && (
        <ReactModal
          isOpen={showModal}
          style={modalStyle}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          portalClassName={portalClass}
        >
          <ModalContainer ref={ref} gap={20}>
            {unexpectedError ? (
              <ErrorWindow
                title={unexpectedError.code || ''}
                information={unexpectedError.message}
                withModal
                icon={<SvgIcon src={NotFoundIcon} width={219} height={125} />}
              />
            ) : (
              <>
                <StyledCaption>
                  <StyledCaptionText size={1} scale="lg">
                    {title}
                  </StyledCaptionText>
                </StyledCaption>

                {isTierLimitExceeded || noCloudKeysToLink ? (
                  <WarningMessage
                    title={
                      noCloudKeysToLink
                        ? 'Create CSPR.cloud key first'
                        : `The "${capitalizedCurrentTier}" tier comes with certain restrictions`
                    }
                    message={
                      noCloudKeysToLink
                        ? 'CSPR.click key should be linked to CSPR.cloud key'
                        : tierAccessProvided
                          ? 'Upgrade your plan to access this feature.'
                          : 'Please ask an organization owner to upgrade the plan.'
                    }
                    iconSrc={InfoColourIcon}
                    margin={'0'}
                  />
                ) : (
                  <>
                    <FlexRow justify="center">
                      <StyledInput
                        value={keyName}
                        label={<BodyText size={1}>Name</BodyText>}
                        placeholder="Key name"
                        onChange={handleKeyName}
                        onBlur={handleKeyNameOnBlur}
                        error={!!formErrors.keyName}
                        validationText={formErrors.keyName}
                      />
                    </FlexRow>
                    <DomainsTitle size={1}>Domains (up to 5)</DomainsTitle>
                    {domains.map((d, index) => (
                      <>
                        <FlexRow justify={'center'} align={'center'} gap={5}>
                          <StyledInput
                            name={'domain'}
                            value={d.value}
                            placeholder="example.com"
                            onChange={(event) => handleChangeDomain(event, index)}
                            onBlur={() => index === 0 && handleDomainOnBlur(index)}
                            error={
                              index === 0
                                ? !!formErrors.domain
                                : Boolean(
                                    domains[index].value && validateDomain(domains[index].value)
                                  )
                            }
                            validationText={
                              index === 0
                                ? formErrors.domain
                                : domains[index].value && validateDomain(domains[index].value)
                            }
                          />
                          {index !== 0 && (
                            <StyledButton
                              width={'100'}
                              color={'secondaryBlue'}
                              onClick={(event) => handleDelete(index, event)}
                            >
                              Remove
                            </StyledButton>
                          )}
                        </FlexRow>
                      </>
                    ))}
                    <BodyText
                      size={3}
                      scale={'sm'}
                      variation={domains.length === 5 ? 'gray' : 'blue'}
                    >
                      <StyledAddDomain onClick={handleAddDomain}>
                        + Add another domain
                      </StyledAddDomain>
                    </BodyText>
                    <BodyText size={1}>Network</BodyText>
                    <SearchableDropdown
                      value={network}
                      items={NETWORKS}
                      onSelect={handleChangeNetwork}
                      height={'auto'}
                      fontSize={'14px'}
                      maxHeight={'134px'}
                      placeholder={'Please select network...'}
                      isError={Boolean(expectedError?.tierNetworkLimitError)}
                    />
                    {Object.keys(expectedError).length !== 0 && (
                      <ErrorTextContainer justify={'center'}>
                        <ErrorText size={3}>
                          {expectedError.tierNetworkLimitError
                            ? expectedError.tierNetworkLimitError
                            : expectedError.duplicateDomainError}
                        </ErrorText>
                      </ErrorTextContainer>
                    )}
                    {!noCloudKeysToLink && (
                      <>
                        <BodyText size={1}>Connected CSPR.cloud key</BodyText>
                        <SearchableDropdown
                          value={linkedCloudKey}
                          items={cloudKeyList}
                          onSelect={handleLinkCloudKey}
                          height={'auto'}
                          fontSize={'14px'}
                          maxHeight={'134px'}
                          placeholder={'Please link CSPR.cloud key...'}
                          isError={!linkedCloudKey.value}
                        />
                      </>
                    )}
                  </>
                )}
                <ButtonsContainer justify={'space-between'}>
                  {dismissLabel && (
                    <StyledDismissButton
                      color={'utility'}
                      onClick={handleOnDismiss}
                      fullWidth={isTierLimitExceeded && !tierAccessProvided}
                    >
                      {dismissLabel}
                    </StyledDismissButton>
                  )}

                  {isTierLimitExceeded && tierAccessProvided ? (
                    <StyledConfirmButton onClick={handleUpgradeTier}>Upgrade</StyledConfirmButton>
                  ) : !isTierLimitExceeded ? (
                    <StyledConfirmButton
                      onClick={noCloudKeysToLink ? handleOpenCreateCloudKeyWindow : handleConfirm}
                    >
                      {noCloudKeysToLink ? 'Create Cloud Key' : confirmLabel}
                    </StyledConfirmButton>
                  ) : (
                    ''
                  )}
                </ButtonsContainer>
              </>
            )}
          </ModalContainer>
        </ReactModal>
      )}
    </>
  );
};

export default CreateClickKeyWindow;
