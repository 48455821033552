const actionHandlers: Record<string, (() => any)[]> = {};

export const enum appActions {
  openCreateCloudKeyWindow = 'openCreateCloudKeyWindow',
  refetchCloudKeys = 'refetchCloudKeys'
}

export const communicator = {
  actions: actionHandlers,
  register(actionName: appActions, callback: () => any) {
    if (!this.actions[actionName as keyof object]) {
      this.actions[actionName] = [];
    }
    this.actions[actionName].push(callback);
  },
  call(actionName: appActions) {
    if (!this.actions[actionName]) {
      throw new Error(`No action with name ${actionName} found`);
    }

    this.actions[actionName].forEach((ev: any) => ev());
  }
};
