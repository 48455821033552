import React from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexRow, SvgIcon } from '@make-software/cspr-ui';
import CSPRBuiltLogo from 'assets/logos/cspr-build-logo-white.svg';
import CheckmarkIcon from 'assets/icons/ic-checkmark.svg';

interface SideBarProps {
  hide?: boolean;
}

const StyledSideBar = styled.div<{ hide?: boolean }>(({ theme, hide }) =>
  theme.withMedia({
    backgroundColor: theme.styleguideColors.backgroundTertiary,
    width: ['200px', '280px', '560px'],
    minHeight: '100vh',
    display: hide ? 'none' : ['none', 'block', 'block']
  })
);

const StyledLogoWrapper = styled.div(() => ({
  padding: '40px 0 0 20px'
}));

const StyledStaticInfoWrapper = styled.div(({ theme }) =>
  theme.withMedia({
    padding: ['120px 0 0 20px', '120px 0 0 30px', '120px 0 0 80px'],
    width: ['200px', '260px', '400px']
  })
);

const StyledHeader = styled.div(({ theme }) => ({
  fontSize: '36px',
  fontWeight: '600',
  lineHeight: '56px',
  color: theme.styleguideColors.contentOnFill
}));

const StyledSubTitle = styled.div(({ theme }) => ({
  margin: '60px 0 40px 0',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '24px',
  color: theme.styleguideColors.contentOnFill
}));

const StyledCheckmarkWrapper = styled.div(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '28px',
  color: theme.styleguideColors.contentOnFill
}));

const CheckmarkRow = styled(FlexRow)(() => ({
  marginBottom: '28px'
}));

export const Sidebar = ({ hide }: SideBarProps) => (
  <StyledSideBar hide={hide}>
    <StyledLogoWrapper>
      <SvgIcon src={CSPRBuiltLogo} height={44} width={249} />
    </StyledLogoWrapper>
    <StyledStaticInfoWrapper>
      <StyledHeader>Manage your CSPR.click and CSPR.cloud keys with CSPR.build</StyledHeader>
      <StyledSubTitle>Built to scale from an idea to enterprise applications:</StyledSubTitle>
      <StyledCheckmarkWrapper>
        <CheckmarkRow gap={16} align={'center'}>
          <FlexColumn>
            <SvgIcon src={CheckmarkIcon} size={24} />
          </FlexColumn>
          <FlexColumn>Battle-tested with a large community</FlexColumn>
        </CheckmarkRow>
        <CheckmarkRow gap={16} align={'center'}>
          <FlexColumn>
            <SvgIcon src={CheckmarkIcon} size={24} />
          </FlexColumn>
          <FlexColumn>Integration is easy and intuitive</FlexColumn>
        </CheckmarkRow>
        <CheckmarkRow gap={16} align={'center'}>
          <FlexColumn>
            <SvgIcon src={CheckmarkIcon} size={24} />
          </FlexColumn>
          <FlexColumn>Highest API availability</FlexColumn>
        </CheckmarkRow>
        <CheckmarkRow gap={16}>
          <FlexColumn>
            <SvgIcon src={CheckmarkIcon} size={24} />
          </FlexColumn>
          <FlexColumn>User-friend and intuitive tools</FlexColumn>
        </CheckmarkRow>
      </StyledCheckmarkWrapper>
    </StyledStaticInfoWrapper>
  </StyledSideBar>
);
