import { GetResponseType, TokenResponseType } from '../types';
import useApi from '../hooks/useApi';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';

export const resetPassword = async (
  password: string,
  token: string
): Promise<GetResponseType<TokenResponseType>> => {
  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/auth/reset-password`,
    'POST',
    { password: password },
    {},
    WITH_AUTH,
    token
  );

  if (data && data.token) {
    localStorage.setItem(AUTH_TOKEN, data.token);
  }

  return {
    data,
    httpCode,
    error,
    loading
  };
};
