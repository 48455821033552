import { GetResponseType } from '../types';
import useApi from '../hooks/useApi';
import { AUTH_TOKEN, WITH_AUTH } from 'app/constants';
import { decodeJWT } from 'app/components/utils/utils';

export interface TierDetails {
  info: SubscriptionItem;
  currentType: CurrentClickTierType | CurrentCloudTierType;
  expirationDate: string;
  cancelledDate: string;
}

export interface WithSubscriptionTierDetails {
  clickTier: TierDetails;
  cloudTier: TierDetails;
}

export interface WithSubscriptionProps {
  tiersDetails: WithSubscriptionTierDetails;
  subscriptions?: GetSubscriptionResponseType;
}

export enum CurrentClickTierType {
  free = 'free',
  pro = 'pro',
  custom = 'custom'
}

export enum CurrentCloudTierType {
  free = 'free',
  pro = 'pro',
  custom = 'custom'
}

export enum ProductType {
  Cloud = 'Cloud',
  Click = 'Click'
}

interface Tier {
  id: number;
  type: ProductType;
  default: boolean;
  key_limit: number;
  click_key_mainnet_limit: number;
  click_key_testnet_limit: number;
  stripe_id: string;
  name: string;
}

export interface SubscriptionItem {
  id: number;
  organization_id: string;
  customer_id: string;
  tier_id: number;
  product_type: ProductType;
  provider: string;
  tier: Tier;
  expire_at: string;
  is_canceled: boolean;
  cancel_at: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export interface GetSubscriptionResponseType {
  item_count: number;
  page_count: number;
  data: SubscriptionItem[] | null;
}

export const getSubscriptions = async (
  page?: number,
  pageSize?: number
): Promise<GetResponseType<GetSubscriptionResponseType>> => {
  const decodedJwt = JSON.parse(decodeJWT(localStorage.getItem(AUTH_TOKEN) || ''));
  const getPageOption = () => {
    if (!page && !pageSize) return '';
    if (page && pageSize) return `?page=${page}&page_size=${pageSize}`;
    if (!page && pageSize) return `?page_size=${pageSize}`;
    if (page && !pageSize) return `?page=${page}`;
  };

  const { data, error, loading, httpCode } = await useApi(
    `${process.env.REACT_APP_CSPRBUILD_API_ENDPOINT}/organizations/${decodedJwt.organizationID}/payments/subscriptions${getPageOption()}`,
    'GET',
    null,
    {},
    WITH_AUTH
  );
  return {
    data,
    httpCode,
    error,
    loading
  };
};
