export const getApiQueryParamsFromGraphFilters = ({
  searchParams,
  groupBy,
  period
}: {
  searchParams: URLSearchParams;
  groupBy: string;
  period: string;
}): URLSearchParams => {
  searchParams.set('group_by', groupBy);
  searchParams.set('period', period);
  return searchParams;
};
