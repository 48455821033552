// @ts-ignore
import { DateRange, DayPicker } from 'react-day-picker/utc';
import 'react-day-picker/style.css';
import { BodyText, FlexColumn, Input, PageTile } from '@make-software/cspr-ui';
import { DateRange as CalendarDateRange } from 'app/build-api';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { CustomSettings } from './custom-settings';
import { formatDate, getCurrentAndThirtyDaysAgoDate } from '../../utils/date-time';
import { UTCDate } from '@date-fns/utc';

const CalendarContentContainer = styled(PageTile)(({ theme }) =>
  theme.withMedia({
    padding: '8px 16px',
    margin: '0px 0'
  })
);

const CalendarContent = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    flexDirection: ['column', 'column', 'row', 'row'],
    width: '100%',
    justifyContent: ['start', 'start', 'space-between', 'space-between'],
    '& > * + *': {
      marginTop: [8, 8, 0],
      marginLeft: [0, 0, 8]
    }
  })
);

const DayPickerWrapper = styled.div(({ theme }) =>
  theme.withMedia({
    '.rdp-root': {
      '--rdp-accent-color': theme.styleguideColors.contentLightBlue,
      '--rdp-accent-background-color': `${theme.styleguideColors.contentLightBlue}26`,
      '.rdp-button_previous, .rdp-button_next': {
        backgroundColor: theme.styleguideColors.fillSecondary,
        borderRadius: '50%',
        marginLeft: 10,
        svg: {
          height: '16px',
          fill: 'black'
        }
      }
    }
  })
);

const toInputValueFormat = (range: CalendarDateRange) =>
  `${formatDate(range.from)} - ${formatDate(range.to)}`;

const getDefaultDateRangeState = (): CalendarDateRange => {
  const { now, thirtyDaysAgo } = getCurrentAndThirtyDaysAgoDate();
  return {
    from: thirtyDaysAgo,
    to: now
  };
};

export interface CalendarRef {
  resetState: () => void;
}

export interface CalendarProps {
  dateRange: CalendarDateRange;
  onDateRangeChange: (newDateRange: CalendarDateRange) => void;
}

export const Calendar = forwardRef(({ dateRange, onDateRangeChange }: CalendarProps, ref) => {
  const [localDateRange, setLocalDateRange] = useState(dateRange);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [inputValue, setInputValue] = useState(toInputValueFormat(dateRange));

  const now = new UTCDate();
  const dateLimit = new UTCDate(now.getTime() - 90 * 24 * 60 * 60 * 1000);

  useImperativeHandle(ref, () => ({
    resetState() {
      setLocalDateRange(getDefaultDateRangeState());
      setInputValue(toInputValueFormat(getDefaultDateRangeState()));
    }
  }));

  const handleDateChange = (newDateRange: CalendarDateRange) => {
    setLocalDateRange(newDateRange);
    onDateRangeChange(newDateRange); // Notify the parent component
    setInputValue(toInputValueFormat(newDateRange));
  };

  const handleRangeSelect = (range: DateRange | undefined) => {
    if (!range || !range?.from || !range?.to) {
      return;
    }
    handleDateChange(range as CalendarDateRange);
  };

  return (
    <>
      <Input
        value={inputValue}
        label={<BodyText size={1}>Date UTC</BodyText>}
        disabled={false}
        placeholder={'Select Date Range'}
        onClick={() => setIsCalendarOpen(!isCalendarOpen)}
        onChange={() => {}} // Prevent the input from being editable
      />
      {isCalendarOpen && (
        <CalendarContentContainer>
          <CalendarContent>
            <DayPickerWrapper>
              <DayPicker
                mode={'range'}
                selected={localDateRange}
                onSelect={handleRangeSelect}
                required={true}
                disabled={[{ after: now }, { before: dateLimit }]}
                excludeDisabled={true}
              />
            </DayPickerWrapper>
            <CustomSettings
              dateRange={localDateRange}
              onDateRangeChange={handleDateChange}
              onCloseAction={() => setIsCalendarOpen(false)}
            />
          </CalendarContent>
        </CalendarContentContainer>
      )}
    </>
  );
});
