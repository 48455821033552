import { BodyText, Button, FlexBox, FlexColumn, Input } from '@make-software/cspr-ui';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CustomSettingsSelect } from './custom-settings-select';
import { DateRange as CalendarDateRange } from 'app/build-api';
import { formatDate, validateDateRange } from 'app/components/utils/date-time';
import { UTCDate } from '@date-fns/utc';

const CalendarSettings = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    justifyContent: 'space-between',
    padding: '16px 16px'
  })
);

const StyledButtonContainer = styled(FlexBox)(({ theme }) =>
  theme.withMedia({
    flexDirection: ['column', 'row', 'row', 'row'],
    marginTop: [28, 28, 40, 40],
    '& > *': {
      width: ['100%', 'auto', 'auto', 'auto']
    },
    '& > * + *': {
      marginLeft: [0, 16, 16, 16],
      marginTop: [16, 0, 0, 0]
    },
    justifyContent: 'space-between'
  })
);

const StyledButton = styled(Button)(() => ({
  height: '36px',
  padding: '4px 32px'
}));

const parseDate = (dateStr: string): Date | null => {
  const [day, month, year] = dateStr.split('/').map(Number);
  if (!month || !day || !year || year < 1000) {
    return null; // Invalid date format
  }
  const date = new UTCDate(year, month - 1, day); // month - 1 because months are 0-indexed in JavaScript
  return isNaN(date.getTime()) ? null : date;
};

export interface CustomSettingsProps {
  dateRange: CalendarDateRange;
  onDateRangeChange: (newDateRange: CalendarDateRange) => void;
  onCloseAction: () => void;
}

export const CustomSettings = (props: CustomSettingsProps) => {
  const [dateStartInput, setDateStartInput] = useState(formatDate(props.dateRange.from));
  const [dateStartError, setDateStartError] = useState('');
  const [dateEndInput, setDateEndInput] = useState(formatDate(props.dateRange.to));
  const [dateEndError, setDateEndError] = useState('');

  useEffect(() => {
    setDateStartInput(formatDate(props.dateRange.from));
    setDateEndInput(formatDate(props.dateRange.to));
  }, [props.dateRange]);

  const onCancel = () => {
    props.onCloseAction();
  };

  const onApply = () => {
    const startDate = parseDate(dateStartInput);
    if (!startDate) {
      setDateStartError('Invalid date format');
      return;
    }
    const endDate = parseDate(dateEndInput);
    if (!endDate) {
      setDateEndError('Invalid date format');
      return;
    }
    const err = validateDateRange(startDate, endDate);
    if (err) {
      setDateEndError(err);
      return;
    }

    props.onDateRangeChange({ from: startDate, to: endDate });
    props.onCloseAction();
  };

  const startDayInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateStartError('');
    setDateStartInput(event.target.value);
  };

  const endDayInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateEndError('');
    setDateEndInput(event.target.value);
  };

  return (
    <CalendarSettings gap={4}>
      <CustomSettingsSelect
        dateRange={props.dateRange}
        onDateRangeChange={props.onDateRangeChange}
      />
      <Input
        label={<BodyText size={1}>Date Start</BodyText>}
        value={dateStartInput}
        onChange={startDayInputOnChange}
        error={!!dateStartError}
        validationText={dateStartError}
      />
      <Input
        label={<BodyText size={1}>Date End</BodyText>}
        value={dateEndInput}
        onChange={endDayInputOnChange}
        error={!!dateEndError}
        validationText={dateEndError}
      />
      <StyledButtonContainer>
        <StyledButton color={'primaryBlue'} onClick={onApply}>
          Apply
        </StyledButton>
        <StyledButton color={'secondaryBlue'} onClick={onCancel}>
          Cancel
        </StyledButton>
      </StyledButtonContainer>
    </CalendarSettings>
  );
};
