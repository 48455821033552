import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { AUTH_TOKEN, generalError, statusCode } from 'app/constants';
import KeyIcon from 'assets/logos/keys.svg';
import SuccessEntityModal from 'app/components/common/success-entity-modal/success-entity-modal';
import { FlexRow, SvgIcon } from '@make-software/cspr-ui';
import CreatedSuccessIcon from 'assets/logos/success-entity.svg';
import {
  CloudKeyRequestType,
  CloudKeyResponseType,
  UpdateCloudKeyRequestType,
  WithSubscriptionProps
} from 'app/build-api';
import { useGetCloudKeys } from 'app/build-api/hooks/useGetCloudKeys';
import { SIGN_IN_PATH } from 'app/route/paths';
import { CloudKeyList } from './show/cloud-key-list';
import CreateCloudKeyWindow from './create/create-cloud-key-window';
import { PreloadTableWindow } from 'app/components/common/preload-table-window/preload-table-window';
import EditCloudKeyWindow from './edit/edit-cloud-key-window';
import { appActions, communicator } from 'app/communicator';

export interface ExpectedErrors {
  plainError: string;
}

const Container = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    marginTop: '48px'
  })
);

export const CloudKeyContainer = ({ tiersDetails }: WithSubscriptionProps) => {
  const { data: cloudKeys, error: fetchingKeysError, refetch, httpCode } = useGetCloudKeys(1, 5);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [createdKey, setCreatedKey] = useState<string | null>(null);
  const [keyToEdit, setKeyToEdit] = useState<CloudKeyResponseType>();
  const [unexpectedError, setUnexpectedError] = useState<ErrorResult | null>(null);
  const [keys, setKeys] = useState<CloudKeyResponseType[] | null>();
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const { cloudTier } = tiersDetails;
  const noCloudKeys = !keys?.length && !loading && !fetchingKeysError && !unexpectedError;
  const isTierLimitExceeded =
    cloudTier.info?.tier.key_limit !== null
      ? Boolean(cloudKeys && cloudKeys.item_count >= cloudTier.info?.tier.key_limit)
      : false;

  useEffect(() => {
    communicator.register(appActions.openCreateCloudKeyWindow, () => setOpenModal(true));
  }, [communicator]);

  useEffect(() => {
    setKeys(cloudKeys?.data);
    if (cloudKeys?.data) {
      setLoading(false);
    }
  }, [cloudKeys]);

  if (fetchingKeysError && !unexpectedError) {
    switch (httpCode) {
      case statusCode.authentication_error:
        localStorage.removeItem(AUTH_TOKEN);
        navigate(SIGN_IN_PATH);
        break;
      default:
        setLoading(false);
        setUnexpectedError(generalError);
        break;
    }
  }

  const handleOpenCreateKeyWindow = () => {
    setOpenModal(true);
    setUnexpectedError(null);
  };

  const handleCloseCreateKeyWindow = () => {
    setOpenModal(false);
  };

  const handleOpenEditKeyWindow = (key: CloudKeyResponseType) => {
    setOpenEditModal(true);
    setKeyToEdit(key);
  };

  const handleCloseEditKeyWindow = () => {
    setOpenEditModal(false);
  };

  const handleCloseSuccessModal = () => {
    setCreatedKey(null);
  };

  const updateKeyList = (key: string) => {
    setCreatedKey(key);
    refetch && refetch();
  };

  const handleCreateKey = async (data: CloudKeyRequestType) => {
    const response = await buildApi.createCloudKey(data);

    if (response.data?.id) {
      handleCloseCreateKeyWindow();
      communicator.call(appActions.refetchCloudKeys);
      setCreatedKey(response.data.hash);

      await refetch();

      setKeys((prevState: any) => [...prevState, response.data]);

      if (noCloudKeys) {
        updateKeyList(response.data.hash);
      }
    }

    if (response.error) {
      switch (response.httpCode) {
        case statusCode.invalid_input_error:
        case statusCode.access_denied_error:
        case statusCode.validation_error:
          setUnexpectedError(response.error);
          Sentry.captureException(response.error);
          break;
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        case statusCode.payment_required_error:
          Sentry.captureException(response.error);
          setUnexpectedError(generalError);
          break;
        case statusCode.unexpected_error:
          setUnexpectedError(generalError);
          break;
        default:
          setUnexpectedError(generalError);
          break;
      }
    }
  };

  const handleUpdateKey = async (id: string, key: UpdateCloudKeyRequestType) => {
    const response = await buildApi.updateCloudKey(id, key);

    if (response.data?.id) {
      communicator.call(appActions.refetchCloudKeys);
      handleCloseEditKeyWindow();
      setKeys((prevState: any) => [
        ...prevState.map((k: CloudKeyResponseType) => {
          if (k.id === response.data?.id) {
            return { ...k, status: response.data.status, name: response.data.name };
          }
          return k;
        })
      ]);
    }

    if (response.error) {
      switch (response.httpCode) {
        case statusCode.invalid_input_error:
        case statusCode.not_found_error:
        case statusCode.access_denied_error:
        case statusCode.validation_error:
          setUnexpectedError(response.error);
          Sentry.captureException(response.error);
          break;
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        case statusCode.unexpected_error:
          setUnexpectedError(generalError);
          break;
        default:
          setUnexpectedError(generalError);
          break;
      }
    }
  };

  return (
    <>
      {createdKey && (
        <SuccessEntityModal
          isOpen={Boolean(createdKey)}
          icon={<SvgIcon src={CreatedSuccessIcon} height={120} width={212} />}
          title="New CSPR.cloud key is ready!"
          information="It was added to the list of your keys."
          onDismiss={handleCloseSuccessModal}
          previewData={createdKey}
        />
      )}

      {noCloudKeys ? (
        <PreloadTableWindow
          icon={<SvgIcon src={KeyIcon} height={120} width={224} />}
          title="You have no CSPR.cloud keys yet"
          windowTitle="CSPR.cloud keys"
          subtitle="All your CSPR.cloud keys will be here once you create them."
          onSubmitTitle="Create key"
          onSubmit={handleOpenCreateKeyWindow}
        />
      ) : (
        <Container justify={'center'} align={'center'}>
          <CloudKeyList
            loading={loading}
            currentTire={cloudTier.currentType}
            cloudKeys={keys || null}
            pageCount={cloudKeys?.page_count || 1}
            error={unexpectedError}
            refetch={refetch}
            onOpenCreateKeyWindow={handleOpenCreateKeyWindow}
            onOpenEditKeyWindow={handleOpenEditKeyWindow}
          />
        </Container>
      )}

      <CreateCloudKeyWindow
        isOpen={openModal}
        confirmLabel="Create"
        onConfirm={handleCreateKey}
        title="Create CSPR.cloud key"
        dismissLabel="Cancel"
        currentTire={cloudTier.currentType}
        unexpectedError={unexpectedError}
        onDismiss={handleCloseCreateKeyWindow}
        isTierLimitExceeded={isTierLimitExceeded}
      />

      {keyToEdit && (
        <EditCloudKeyWindow
          isOpen={openEditModal}
          keyToEdit={keyToEdit}
          confirmLabel="Save"
          onUpdate={handleUpdateKey}
          title="Edit CSPR.cloud key"
          dismissLabel="Cancel"
          unexpectedError={unexpectedError}
          onDismiss={handleCloseEditKeyWindow}
        />
      )}
    </>
  );
};
