import React from 'react';
import styled from 'styled-components';
import { CaptionText, FlexBox } from '@make-software/cspr-ui';

interface PaginationInfoTextProps {
  children?: React.ReactNode;
}

const StyledContainer = styled(FlexBox)(({ theme }) =>
  theme.withMedia({
    textAlign: 'center',
    borderRadius: theme.borderRadius.base,
    backgroundColor: theme.styleguideColors.fillSecondary,
    color: theme.styleguideColors.contentPrimary,
    height: 24,
    padding: ['4px 8px', '4px 16px'],
    width: '100%'
  })
);

export const PaginationInfoText = ({ children, ...props }: PaginationInfoTextProps) => {
  return (
    <StyledContainer {...props} align={'center'} justify={'center'}>
      <CaptionText size={2} variation={'black'} noWrap>
        {children}
      </CaptionText>
    </StyledContainer>
  );
};
