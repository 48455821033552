import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FlexColumn, FlexRow, SvgIcon, useMatchMedia } from '@make-software/cspr-ui';
import CSPRBuildLogo from 'assets/logos/cspr-build-logo-white.svg';
import { HOME_PATH, SIGN_IN_PATH } from 'app/route/paths';
import { DesktopNavigationMenu, MobileNavigationMenu } from './navigation-menu';
import MenuIcon from 'assets/icons/ic-menu.svg';
import CloseSvgIcon from 'assets/icons/ic-close.svg';
import { AUTH_TOKEN } from 'app/constants';
import { decodeJWT } from 'app/components/utils/utils';

const StyledHeader = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    height: '72px',
    width: '100%',
    backgroundColor: theme.styleguideColors.backgroundTertiary
  })
);

const HeaderContent = styled(FlexColumn)(({ theme }) =>
  theme.withMedia({
    width: theme.maxWidth,
    padding: ['20px', '20px', '20px']
  })
);

const HamburgerIcon = styled(SvgIcon)(({ theme }) => ({
  color: theme.styleguideColors.contentOnFill
}));

const CloseIcon = styled(SvgIcon)(({ theme }) => ({
  path: {
    stroke: theme.styleguideColors.contentOnFill
  }
}));

const Logo = () => (
  <FlexRow>
    <Link to={HOME_PATH}>
      <SvgIcon src={CSPRBuildLogo} width={181} height={32} />
    </Link>
  </FlexRow>
);

const HamburgerButton = ({
  menuVisible,
  ...props
}: {
  menuVisible: boolean;
  onClick: () => void;
}) => {
  return menuVisible ? (
    <CloseIcon src={CloseSvgIcon} size={16} {...props} />
  ) : (
    <HamburgerIcon src={MenuIcon} size={16} {...props} />
  );
};

export const PageHeader = () => {
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);
  const [email, setEmail] = useState<string>('');
  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      const decodedJwt = decodeJWT(token);
      const parsedJwt = decodedJwt ? JSON.parse(decodeJWT(token)).email : '';
      setEmail(parsedJwt);
    }
  }, []);

  const handleDocsRedirect = () => {
    window.open('https://docs.cspr.build/', '_blank');
  };

  const handleLogout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    navigate(SIGN_IN_PATH);
  };

  const onMobile = (
    <>
      <FlexRow justify={'space-between'} align={'center'}>
        <Logo />
        <HamburgerButton
          menuVisible={menuVisible}
          onClick={() => {
            setMenuVisible((visible) => !visible);
          }}
        />
      </FlexRow>
      {menuVisible && (
        <MobileNavigationMenu
          email={email}
          setMenuVisible={setMenuVisible}
          handleDocsRedirect={handleDocsRedirect}
          handleLogout={handleLogout}
        />
      )}
    </>
  );

  const onAbove = (
    <FlexRow justify={'space-between'} align={'center'}>
      <Logo />
      <DesktopNavigationMenu
        email={email}
        handleDocsRedirect={handleDocsRedirect}
        handleLogout={handleLogout}
      />
    </FlexRow>
  );

  const responsiveHeader = useMatchMedia(
    [onMobile, onMobile, onAbove, onAbove],
    [menuVisible, email]
  );
  return (
    <StyledHeader justify={'center'}>
      <HeaderContent>{responsiveHeader}</HeaderContent>
    </StyledHeader>
  );
};
