import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FlexRow } from '@make-software/cspr-ui';
import {
  CurrentClickTierType,
  ProductType,
  WithSubscriptionProps
} from 'app/build-api/subscription/subscription';
import { TierItem } from '../tier-item/tier-item';
import CustomRequestWindow from '../custom-request-window/custom-request-window';
import { buildApi, ErrorResult } from 'app/build-api/types';
import { AUTH_TOKEN, CLICK_PRO_TIER_ID, generalError, statusCode } from 'app/constants';
import { SIGN_IN_PATH } from 'app/route/paths';

interface ClickSubscriptionProps extends WithSubscriptionProps {
  onError: (error: ErrorResult | null) => void;
}

const Container = styled(FlexRow)(({ theme }) =>
  theme.withMedia({
    flexDirection: ['column', 'column', 'row', 'row']
  })
);

const clickTiersOptions = {
  free: {
    title: 'Free',
    price: 0,
    properties: [
      'Up to 2 App ID (1 testnet, 1 mainnet)',
      'Up to 1,000 MAW (Monthly Active Wallets)',
      'Single On-Ramp (or geo)',
      'No social login auth integrations'
    ]
  },
  pro: {
    title: 'Pro',
    price: 99,
    properties: [
      'Up to 6 App ID (3 testnet, 3 mainnet)',
      'Up to 15,000 MAW (Monthly Active Wallets)',
      'Multi (or global) On-Ramp',
      'Default social logins up to 2,000 users',
      '40$ per 1,000 MAW over 15,000 limit'
    ]
  },
  custom: {
    title: 'Custom',
    price: 0,
    properties: [
      'More MAW (Monthly Active Wallets)',
      'More  App IDs',
      'Multi (or global) On-Ramp',
      'Custom auth integrations'
    ]
  }
};

export const ClickSubscription = ({ tiersDetails, onError }: ClickSubscriptionProps) => {
  const navigate = useNavigate();
  const { clickTier } = tiersDetails;
  const [openRequestCustomPlanModal, setOpenRequestCustomPlanModal] = useState(false);

  const handleOpenRequestCustomPlanModal = () => {
    setOpenRequestCustomPlanModal(true);
  };

  const handleCloseRequestCustomPlanModal = () => {
    setOpenRequestCustomPlanModal(false);
  };

  const handleUpgradePlan = async () => {
    const payload = {
      tier_id: CLICK_PRO_TIER_ID,
      tier_type: ProductType.Click
    };

    const response = await buildApi.createCheckoutSession(payload);

    if (response.data?.url) {
      window.location.replace(response.data?.url);
    }

    if (response.error) {
      switch (response.httpCode) {
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        default:
          onError(generalError);
          break;
      }
    }
  };

  const handleCustomerPortal = async () => {
    const response = await buildApi.createCustomerPortal();

    if (response.data?.url) {
      window.location.replace(response.data?.url);
    }

    if (response.error) {
      switch (response.httpCode) {
        case statusCode.authentication_error:
          localStorage.removeItem(AUTH_TOKEN);
          navigate(SIGN_IN_PATH);
          break;
        default:
          onError(generalError);
          break;
      }
    }
  };

  return (
    <>
      <Container justify="center" gap={16}>
        <TierItem
          tierTitle={clickTiersOptions.free.title}
          tierPrice={clickTiersOptions.free.price}
          tierItems={clickTiersOptions.free.properties}
          buttonLabel={clickTier.currentType !== CurrentClickTierType.free ? 'Select' : ''}
          onSubmit={
            clickTier.currentType !== CurrentClickTierType.free ? handleCustomerPortal : undefined
          }
          isCurrentTier={clickTier.currentType === CurrentClickTierType.free}
        />
        <TierItem
          tierTitle={clickTiersOptions.pro.title}
          tierPrice={clickTiersOptions.pro.price}
          tierItems={clickTiersOptions.pro.properties}
          buttonLabel={
            clickTier.cancelledDate && clickTier.currentType === CurrentClickTierType.pro
              ? 'Renew'
              : clickTier.currentType === CurrentClickTierType.pro
                ? 'Manage'
                : 'Upgrade'
          }
          onSubmit={
            clickTier.currentType === CurrentClickTierType.pro
              ? handleCustomerPortal
              : handleUpgradePlan
          }
          cancelledDate={clickTier.cancelledDate}
          expirationDate={clickTier.expirationDate}
          isCurrentTier={clickTier.currentType === CurrentClickTierType.pro}
        />
        <TierItem
          tierTitle={clickTiersOptions.custom.title}
          tierItems={clickTiersOptions.custom.properties}
          buttonLabel={clickTier.currentType === CurrentClickTierType.custom ? 'Manage' : 'Request'}
          onSubmit={handleOpenRequestCustomPlanModal}
          isCurrentTier={clickTier.currentType === CurrentClickTierType.custom}
        />
      </Container>

      <CustomRequestWindow
        isOpen={openRequestCustomPlanModal}
        onDismiss={handleCloseRequestCustomPlanModal}
      />
    </>
  );
};
