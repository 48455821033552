import { MONTH_NAMES } from 'app/constants';
import { UTCDate } from '@date-fns/utc';

export const getFormattedDate = (date: string | undefined) => {
  if (!date) return '';
  const dateObject = new UTCDate(date);
  return `${dateObject.getUTCDate()} ${MONTH_NAMES[dateObject.getUTCMonth()]}, ${dateObject.getUTCFullYear()}`;
};

export const formatDateToISODate = (date: Date): string => {
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
};

export const formatTimestamp = (isoString: string) => {
  const date = new UTCDate(isoString);

  const pad = (number: number) => (number < 10 ? '0' : '') + number;

  const hours = pad(date.getUTCHours());
  const minutes = pad(date.getUTCMinutes());
  const seconds = pad(date.getUTCSeconds());

  return `${formatDate(date)} ${hours}:${minutes}:${seconds}`;
};

export const formatDate = (date: Date) => {
  const pad = (number: number) => (number < 10 ? '0' : '') + number;
  const day = pad(date.getUTCDate());
  const month = pad(date.getUTCMonth() + 1); // Months are zero-indexed, so we add 1
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
};
export const getCurrentAndThirtyDaysAgoDate = () => {
  const now = new UTCDate();
  const thirtyDaysAgo = new UTCDate();
  thirtyDaysAgo.setUTCDate(now.getUTCDate() - 30);

  return {
    now,
    thirtyDaysAgo
  };
};

export const getDateRangeFromSearchParams = (
  searchParams: URLSearchParams
): { from: Date; to: Date } => {
  const fromDate = new UTCDate(searchParams.get('from') as string);
  const toDate = new UTCDate(searchParams.get('to') as string);
  if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime()) || validateDateRange(fromDate, toDate)) {
    const { now, thirtyDaysAgo } = getCurrentAndThirtyDaysAgoDate();
    return { from: thirtyDaysAgo, to: now };
  }
  return { from: fromDate, to: toDate };
};

export const validateDateRange = (startDate: Date, endDate: Date): string | null => {
  if (startDate > endDate) {
    return 'End date should be after start date';
  }
  if (endDate > new UTCDate()) {
    return 'End date should be before today';
  }
  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
  const differenceInMilliseconds = new UTCDate().getTime() - startDate.getTime();
  const differenceInDays = Math.floor(differenceInMilliseconds / MILLISECONDS_PER_DAY);

  if (differenceInDays > 90) {
    return 'Date range should not exceed 90 days from today';
  }
  return null;
};
