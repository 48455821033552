import React from 'react';
import styled from 'styled-components';
import { checkGrantedAccess } from 'app/components/utils/utils';
import { AccessItem, WithSubscriptionProps } from 'app/build-api';
import { CloudKeyContainer } from './components/cloud-keys/cloud-key-container';
import { ClickKeyContainer } from './components/click-keys/click-key-container';
import { UserContainer } from './components/users/user-container';
import { NoAccessWindow } from './components/no-access-window/no-access-window';
import { WithSubscriptions } from 'app/components/common/hoc/withSubscriptions';

const HomeContainer = styled.div(({ theme }) =>
  theme.withMedia({
    padding: '0 0 60px 0'
  })
);

const BaseHome = ({ tiersDetails }: WithSubscriptionProps) => {
  const noGrantedAccess =
    !checkGrantedAccess(AccessItem.CloudKeyManagement) &&
    !checkGrantedAccess(AccessItem.ClickKeyManagement) &&
    !checkGrantedAccess(AccessItem.UserManagement);

  return (
    <HomeContainer>
      {checkGrantedAccess(AccessItem.CloudKeyManagement) && (
        <CloudKeyContainer tiersDetails={tiersDetails} />
      )}
      {checkGrantedAccess(AccessItem.ClickKeyManagement) && (
        <ClickKeyContainer tiersDetails={tiersDetails} />
      )}
      {checkGrantedAccess(AccessItem.UserManagement) && <UserContainer />}

      {noGrantedAccess && <NoAccessWindow />}
    </HomeContainer>
  );
};

const Home = WithSubscriptions(BaseHome);

export default Home;
