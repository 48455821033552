import { BodyText, FlexRow, PageTile, SubtitleText } from '@make-software/cspr-ui';
import styled from 'styled-components';

const NoAccessWrapper = styled(PageTile)(({ theme }) =>
  theme.withMedia({
    padding: '100px 30px 0 30px',
    width: '80%',
    margin: ['100px auto', '200px auto 300px auto', '200px auto 300px auto'],
    height: '200px'
  })
);

export const NoAccessWindow = () => {
  return (
    <NoAccessWrapper>
      <FlexRow align={'center'} direction={'column'} gap={20}>
        <SubtitleText size={1}>
          Seems like you don't have any access to CSPR.build tools.
        </SubtitleText>
        <BodyText size={1}>Please ask your company owner to provide an access.</BodyText>
      </FlexRow>
    </NoAccessWrapper>
  );
};
